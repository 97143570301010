import * as constants from "./constants";

export const getCurrentSentenceStartTime = (sentencesStartTimeArray, curentTime) => {
  let currentSentenceStartTime = 0;
  for (let i in sentencesStartTimeArray) {
    if (i > 0 && curentTime < sentencesStartTimeArray[i]) {
      currentSentenceStartTime = sentencesStartTimeArray[i - 1];
      break;
    }
  }
  return currentSentenceStartTime;
};

export const getSentencesStartTimeArray = (sentences) => {
  let sentencesStartTimeArray = [];
  sentences.forEach((sentence) => {
    sentencesStartTimeArray.push(sentence.StartTime.second);
  });
  return sentencesStartTimeArray;
};

export const getSentenceData = (sentences, participants, isVideoFile, utteranceFlagsSelected) => {
  let rows = [];
  if (sentences) {
    sentences.map((sentence, key) => {
      let meddicKPI = [];
      let utteranceFlags = utteranceFlagsSelected?.filter((item) => item.MessageID === sentence.MessageID);
      let participant = constants.updatedSpeaker(sentence.ParticipantRole);
      if (sentence.ParticipantRole === "HUMAN_AGENT" || sentence.ParticipantRole === "HOST") {
        meddicKPI = sentence.MEDDIC_KPI?.map((k) => {
          return k.value;
        });
      }
      if (isVideoFile) {
        participant = constants.getParticipant(participants, sentence.ParticipantID, sentence.ParticipantRole);
      }
      rows.push(
        createData(
          key,
          constants.updatedSpeaker(sentence.ParticipantRole),
          participant,
          constants.cleanupTranscription(sentence.Content),
          sentence.KeyWords,
          sentence.SentimentAnalysis?.score ? sentence.SentimentAnalysis?.score : 0,
          sentence.KPI?.map((k) => {
            return k.value;
          }),
          meddicKPI,
          sentence.StartTime.second,
          sentence.EndTime.second,
          sentence.MediaIndex,
          sentence.MessageID,
          utteranceFlags,
          sentence.GryphCards
        )
      );
      return sentence;
    });
  }
  return rows;
};

export const createData = (
  second,
  speaker,
  participant,
  sentence,
  keywords,
  score,
  kpi,
  mkpi,
  start,
  end,
  mediaIndex,
  msgID,
  utteranceFlags,
  gryphCards
) => {
  return {
    second,
    speaker,
    participant,
    sentence,
    keywords,
    score,
    kpi,
    mkpi,
    start,
    end,
    mediaIndex,
    msgID,
    utteranceFlags,
    gryphCards,
  };
};

export const secondsToMinutes = (seconds) => {
  let minutes = Math.floor(seconds / 60);
  let secs = Math.floor(seconds % 60);
  let timeStr = `${minutes}m ${secs}s`;
  return timeStr;
};
