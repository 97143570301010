import "./Bullet.css";
import React from "react";

export default function Bullet(props) {
  return props.type === "mkpis" ? (
    <svg id="svgelem" className={props.type}>
      <path d="M 10 1 L 12.1 7.9 L 19 10 L 12.1 12.1 L 10 19 L 7.9 12.1 L 1 10 L 7.9 7.9 Z" fill={props.color} />
    </svg>
  ) : (
    <div className={props.type} style={{ backgroundColor: props.color }}></div>
  );
}
