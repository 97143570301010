import React, { useEffect } from "react";
import * as constants from "../../assets/constants";
// import Typography from "@mui/material/Typography";
import { Typography } from "@material-ui/core";
import styles from "./FauxVideoView.module.css";
import StyledContainer from "../../assets/components/StyledContainer";
import Chip from "@mui/material/Chip";

const FauxVideoView = (props) => {
  useEffect(() => {}, [props.scrollToIndex, props.sentences, props.callMetadataInfo]);

  return (
    <React.Fragment>
      <div className={styles.fauxVideoContainer}>
        <div className={styles.fauxVideoSpeakerInfo}>
          {/* Here we firstly check if the isCallRecordingOff flag is set to YES or NO. 
            If it is set to YES then it indicates that the audio recording for this call is not available as per client configurations set. 
            However, if it is set to NO, we need to check if that file is actually present in GCP bucket or not as per retention policy. 
            if audio file is present on cloud storage, when we hit its url it should return status code as 200, 
            if it doesn't then that file is not present on the cloud storage */}
          {props.isCallRecordingOff === "YES" || !props.audioURL ? (
            <Typography style={{ textTransform: "none" }} variant="h4" gutterBottom component="div">
              {constants.ERROR_NO_RECORDING_AVAILALE}
            </Typography>
          ) : (
            <>
              <Typography variant="h5" gutterBottom component="div">
                Speaker:
              </Typography>
              <Typography variant="h4" gutterBottom component="div">
                {constants
                  .getAgentUpdatedSpeaker(
                    props.sentences[props.scrollToIndex]?.speaker,
                    props.callMetadataInfo?.data?.FirstName,
                    props.callMetadataInfo?.data?.LastName
                  )
                  .toLowerCase()}
              </Typography>
            </>
          )}
        </div>
        <StyledContainer disableGutters={false} className={styles.fauxVideoKPIDiv}>
          {props.sentences[props.scrollToIndex]?.kpi?.length > 0
            ? props.sentences[props.scrollToIndex]?.kpi?.map((kpi, index) =>
                kpi !== "None" ? (
                  <Chip
                    key={index}
                    sx={{
                      background: "#EBFBEE",
                      borderRadius: "10px",
                      color: "#2B8A3E",
                      fontWeight: 600,
                    }}
                    size="large"
                    label={kpi}
                  />
                ) : undefined
              )
            : undefined}
          {props.sentences[props.scrollToIndex]?.mkpi?.length > 0 && props.isMeddic
            ? props.sentences[props.scrollToIndex]?.mkpi?.map((mkpi, index) =>
                mkpi !== "None" ? (
                  <Chip
                    key={index}
                    sx={{
                      background: "#E7F5FF",
                      borderRadius: "10px",
                      color: "#1971C2",
                      fontWeight: 600,
                    }}
                    size="large"
                    label={mkpi}
                  />
                ) : undefined
              )
            : undefined}
        </StyledContainer>
      </div>
    </React.Fragment>
  );
};

export default FauxVideoView;
