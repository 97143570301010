import { React, useState } from "react";
import { Box, Typography, AccordionDetails, Paper } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

const PreviewContent = (props) => {
  const { onPreviewCloseHandler, cardContent } = props;
  const [content] = useState([JSON.parse(cardContent.Content)]);
  const [isScript] = useState(false);
  const [panel, setPanel] = useState(-1);

  const handleAccordian = (index) => {
    setPanel(index === panel ? index : "");
  };

  const findSectionTitle = (sections) => {
    const isItemFound = sections.every((item) => item.sectiontitle !== "");
    return isItemFound;
  };

  const renderSection = (sections) => {
    return (
      <>
        {sections?.map((section, index) => {
          return (
            <Accordion key={`pc_rs_acc_${index}`} square={false} onChange={() => handleAccordian(index)}>
              <AccordionSummaryStyled aria-controls="panel1a-content" id="panel1a-header">
                <AccordionHeaderTextStyled>{section.sectiontitle}</AccordionHeaderTextStyled>
              </AccordionSummaryStyled>
              <AccordionDetails>
                <InnerSubContentWrapper className={isScript ? "script-file" : ""}>
                  <InnerContentSeperator />
                  <InnerSubContentStyled>
                    {section.bullets?.map((bullet) => {
                      return (
                        <>
                          <li>
                            <BulletContentStyled>{bullet?.bulletheader}</BulletContentStyled>:{" "}
                            <SummaryContent>
                              {bullet.bullettype === "text" ? (
                                <SummaryInnerContent>{bullet?.bulletcontent}</SummaryInnerContent>
                              ) : (
                                <>
                                  {" "}
                                  <SummaryInnerContent>{bullet?.bulletlinktext}</SummaryInnerContent>,<SummaryInnerContent> </SummaryInnerContent>
                                  <SummaryInnerContentURL>{bullet?.bulletlinkurl}</SummaryInnerContentURL>
                                </>
                              )}
                            </SummaryContent>
                          </li>
                        </>
                      );
                    })}
                  </InnerSubContentStyled>
                </InnerSubContentWrapper>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </>
    );
  };

  const renderScriptContent = (segment) => {
    const { sections = [] } = segment;
    return (
      <>
        {sections.map((item) => {
          return (
            <>
              <ScriptTitleStyled>{item?.segmentTitle}</ScriptTitleStyled>

              <InnerSubContentWrapper>
                <ScriptContentSeperator />
                <InnerSubContentStyled>
                  {findSectionTitle(item.sections.section) > 0 ? (
                    renderSection(item.sections.section)
                  ) : (
                    <NoContentDisplayStyled>No card content to display. </NoContentDisplayStyled>
                  )}
                </InnerSubContentStyled>
              </InnerSubContentWrapper>
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      {content.length > 0 ? (
        <Box>
          {content.map((card, cardIndex) => (
            <ModalWrapperStyled key={`pc_ret_mws_${cardIndex}`}>
              <ModalContentStyled>
                <ModalHeaderStyled>
                  <ModalHeaderIconStyled />
                  <ModalHeaderTitleStyled>{card.cardtitle}</ModalHeaderTitleStyled>
                  <CloseIcon color="primary" onClick={onPreviewCloseHandler} />
                </ModalHeaderStyled>

                <CardContentStyled>
                  {isScript ? (
                    renderScriptContent(card)
                  ) : findSectionTitle(card.sections.section) ? (
                    card?.sections?.section?.map((section, sectionIndex) => (
                      <AccordionStyled key={`pc_ret_ccs_acc_${sectionIndex}`} square={false} onChange={() => handleAccordian(sectionIndex)}>
                        <AccordionSummaryStyled aria-controls="panel1a-content" id="panel1a-header">
                          <AccordionHeaderTextStyled>{section.sectiontitle}</AccordionHeaderTextStyled>
                        </AccordionSummaryStyled>
                        <AccordionDetails>
                          <InnerSubContentWrapper className={isScript ? "script-file" : ""}>
                            <InnerContentSeperator />
                            <InnerSubContentStyled>
                              {section.bullets?.bullet?.map((bullet, bulletIndex) => (
                                <li key={`pc_ret_inscs_li_${bulletIndex}`}>
                                  <BulletContentStyled>{bullet?.bulletheader}</BulletContentStyled>:{" "}
                                  <SummaryContent>
                                    {bullet.bullettype === "text" ? (
                                      <SummaryInnerContent>{bullet?.bulletcontent}</SummaryInnerContent>
                                    ) : (
                                      <>
                                        {" "}
                                        <SummaryInnerContent>{bullet?.bulletlinktext}</SummaryInnerContent>,
                                        <SummaryInnerContent> </SummaryInnerContent>
                                        <SummaryInnerContentURL>{bullet?.bulletlinkurl}</SummaryInnerContentURL>
                                      </>
                                    )}
                                  </SummaryContent>
                                </li>
                              ))}
                            </InnerSubContentStyled>
                          </InnerSubContentWrapper>
                        </AccordionDetails>
                      </AccordionStyled>
                    ))
                  ) : (
                    <NoContentDisplayStyled>No card content to display. </NoContentDisplayStyled>
                  )}
                </CardContentStyled>
              </ModalContentStyled>
            </ModalWrapperStyled>
          ))}
        </Box>
      ) : (
        <ModalWrapperStyled key={"pc_ret_mws_no_data"}>
          <ModalContentStyled>
            <ModalHeaderStyled>
              <ModalHeaderIconStyled />
              <ModalHeaderTitleStyled>{"Content Not Found"}</ModalHeaderTitleStyled>
              <CloseIcon color="primary" onClick={onPreviewCloseHandler} />
            </ModalHeaderStyled>

            <CardContentStyled>
              <NoContentDisplayStyled>No card content to display. </NoContentDisplayStyled>
            </CardContentStyled>
          </ModalContentStyled>
        </ModalWrapperStyled>
      )}
    </>
  );
};

export default PreviewContent;

const BulletContentStyled = styled("span")(() => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "19px",
  color: "#495057",
}));

const SummaryContent = styled("span")(() => ({
  textAlign: "left",
  paddingLeft: "20px",
}));

const InnerContentSeperator = styled("div")(() => ({
  borderLeft: "4px solid #1971C2",

  marginRight: "14px",
}));

const ScriptContentSeperator = styled("div")(() => ({
  border: "1px solid #1976d2",
  width: "0.3rem",

  marginRight: "14px",
}));

const NoContentDisplayStyled = styled("div")(() => ({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const InnerSubContentWrapper = styled(Box)(() => ({
  display: "flex",
  marginLeft: "2.5rem",
}));

const InnerSubContentStyled = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  "& li": {
    textAlign: "left",

    span: {
      paddingLeft: "0",
      marginRight: "0",
    },
  },
}));

const ModalHeaderStyled = styled(Box)(() => ({
  display: "flex",

  height: "28px",
}));

const ModalHeaderIconStyled = styled(ListIcon)(() => ({
  fontSize: "31px",

  path: {
    fill: "#495057",
  },
}));

const ModalHeaderTitleStyled = styled(Typography)(() => ({
  lineHeight: "29.05px",
  fontSize: "24px",
  fontWeight: "600",
  fontFamily: "Inter",
  color: "#495057",
  width: "90%",
  paddingLeft: "2%",
}));

const ScriptTitleStyled = styled(Typography)(() => ({
  lineHeight: "29.05px",
  fontSize: "16px",
  fontWeight: "600",
  fontFamily: "Inter",
  color: "#495057",
  width: "88%",
  paddingLeft: "2%",
}));

const ModalWrapperStyled = styled(Paper)(() => ({
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fffff",
  boxShadow: "24",
  width: "44%",
  borderRadius: "10px",
}));

const ModalContentStyled = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxHeight: "392px",
  height: "392px",
  overflowY: "auto",

  justifyContent: "center",
  textAlign: "center",
  paddingLeft: "1rem",

  "& p": {
    textAlign: "left",
  },

  "& .script-file": {
    marginLeft: "1.5rem",
  },
}));

const SummaryInnerContent = styled("span")(() => ({
  color: "#495057",
}));

const SummaryInnerContentURL = styled("span")(({ theme }) => ({
  color: `${theme.palette.primary.main}`,
  textDecoration: "underline",
}));

const AccordionHeaderTextStyled = styled(Typography)(() => ({
  marginLeft: "0.5rem",
  fontSize: "16px",
  fontFamily: "Inter",
  fontWeight: "600",
}));

const CardContentStyled = styled("div")(() => ({
  overflowY: "scroll",
  height: "292px",
  paddingTop: "1rem",
}));

const AccordionSummaryStyled = styled((props) => <AccordionSummary {...props} />)(({ theme }) => ({
  paddingLeft: "5px",
  paddingRight: "5px",

  "& .AccordionSummary-expandIconWrapper.Mui-expanded": {
    color: "#ffffff",
  },

  "& .AccordionSummary-content": {
    marginleft: theme.spacing(1),
  },
}));

const AccordionStyled = styled((props) => <Accordion disableGutters elevation={0} {...props} />)(() => ({
  boxShadow: "none",
  borderRadius: "0",
  marginBottom: "2px",

  "&:before": {
    display: "none",
  },

  "& .expandedAccordion": {
    height: "22rem",
  },

  "& .expandedAccordionSummary": {
    backgroundColor: "#1971C2",
    color: "#ffffff",

    "& p": {
      color: "#ffffff",
    },
  },
}));
