import React, { useEffect } from "react";
import "./ESDateRange.css";
import { DatePicker } from "antd";
import dayjs from "dayjs";

export default function ESDateRange(props) {
  const { RangePicker } = DatePicker;

  useEffect(() => {
    (async () => {
      try {
        const query = {
          query: {
            bool: {
              must: [
                {
                  bool: {
                    must: [
                      {
                        range: {
                          StartTimestamp: {
                            gte: props.selectedFromDate.format("YYYY-MM-DD"),
                            lte: props.selectedToDate.format("YYYY-MM-DD"),
                            format: "strict_date",
                          },
                        },
                      },
                      {
                        match_all: {},
                      },
                    ],
                  },
                },
              ],
            },
          },
        };

        props.setQuery(query);
      } catch (err) {}
    })();
  }, [props.refreshDate]);

  const updateDateRangeQuery = () => {
    if (props.selectedFromDate || props.selectedToDate) {
      const query = {
        query: {
          bool: {
            must: [
              {
                bool: {
                  must: [
                    {
                      range: {
                        StartTimestamp: {
                          gte: props.selectedFromDate.format("YYYY-MM-DD"),
                          lte: props.selectedToDate.format("YYYY-MM-DD"),
                          format: "strict_date",
                        },
                      },
                    },
                    {
                      match_all: {},
                    },
                  ],
                },
              },
            ],
          },
        },
        value: `${props.selectedFromDate.format("YYYY-MM-DD")} - ${props.selectedToDate.format("YYYY-MM-DD")}`,
      };
      props.setQuery(query);
    }
  };

  const handleDateChange = async (date) => {
    try {
      if (date) {
        let from = date[0];
        if (!from) from = dayjs().subtract(1, "month").set("hour", 0).set("minute", 0).set("second", 0);
        props.setSelectedFromDate(from);
        let to = date[1];
        if (!to) to = dayjs().set("hour", 23).set("minute", 59).set("second", 59);
        props.setSelectedToDate(to);
      }
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <RangePicker
        placeholder={["Start Date", "End Date"]}
        className="gn_rangepicker"
        value={[props.selectedFromDate, props.selectedToDate]}
        defaultPickerValue={[props.defaultFromDate, props.defaultToDate]}
        disabledDate={(current) => {
          return current > dayjs();
        }}
        placement="bottomLeft"
        bordered={false}
        size="large"
        format={"MM/DD/YYYY"}
        separator="-"
        onChange={(date) => handleDateChange(date)}
        allowEmpty={[true, true]}
        onOpenChange={(open) => {
          if (open === false) updateDateRangeQuery();
        }}
      />
    </React.Fragment>
  );
}
