import { withStyles } from "@material-ui/core/styles";
import HelpIcon from "@mui/icons-material/Help";

export const NegativeIcon = withStyles(() => ({
  root: {
    color: "#FD9A00",
    width: "inherit !important",
    height: "inherit !important",
    verticalAlign: "inherit",
  },
}))(HelpIcon);
