import { withStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const PositiveIcon = withStyles(() => ({
  root: {
    color: "#4BC957",
    width: "inherit !important",
    height: "inherit !important",
    verticalAlign: "inherit",
  },
}))(CheckCircleIcon);
