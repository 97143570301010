import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-playlist";
import "videojs-playlist-ui";
import "videojs-overlay";
import "./VideoView.css";
import "videojs-overlay-buttons";
import "videojs-overlay-buttons/dist/videojs-overlay-buttons.css";
import { LinearProgress } from "@mui/material";
import Immutable from "immutable";
import PropTypes from "prop-types";
import styles from "../faux-video-view/FauxVideoView.module.css";
import * as constants from "../../assets/constants";
import { Typography } from "@material-ui/core";
import ThumbnailUrl from "../../assets/imgs/VideoThumbnail.jpeg";

export default class VideoPlayer extends React.Component {
  static contextTypes = {
    list: PropTypes.instanceOf(Immutable.List),
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: "1",
      fullscreen: false,
      transcripts: {
        text: [],
        transcriptUrl: [],
      },
      errMsg: "",
      indexPlaylist: 0,
      position: 0,
      scrollToIndex: 0,
      previousAudioURL: "",
    };
  }

  async componentDidUpdate() {
    // check if the previous audio URL and current stateInfo audio URL are different or not, if different then only fetch status of the file.
    if (this.state.previousAudioURL !== this.props.stateInfo.audioURL) {
      this.setState({ previousAudioURL: this.props.stateInfo.audioURL });
    }
  }

  async componentDidMount() {
    let comp = this;
    this.setState({ errMsg: undefined });
    try {
      this.setState({ previousAudioURL: this.props.stateInfo.audioURL });
      this.player = videojs(this.videoNode, this.props, function onPlayerReady() {});
      this.player.on("pause", () => {
        this.props.pauseMedia();
      });

      this.player.on("play", () => {
        this.props.playMedia();
      });

      this.player.on("beforeplaylistitem", () => {
        let indexOfCurrentItem = this.player.playlist.currentItem();
        indexOfCurrentItem = indexOfCurrentItem + 1;

        if (this.props.isPlaylistClick) {
          if (this.props.stateInfo?.playlistIndex < this.player.playlist().length) {
            this.props.seekFromGraph(0);
            this.props.setMediaUrl(this.player.playlist()[this.props.stateInfo?.playlistIndex].sources[0].src);
          }
        } else {
          this.props.setPlaylistIndex(indexOfCurrentItem);
          if (indexOfCurrentItem < this.player.playlist().length) {
            this.props.seekFromGraph(0);
            this.props.setMediaUrl(this.player.playlist()[indexOfCurrentItem].sources[0].src);
          }
        }
        this.player.playlist.autoadvance(0);
        this.props.setIsplaylistClick(false);
      });

      this.player.on("playlistitem", () => {
        this.player.playlist.autoadvance(0);
      });

      this.player.on("error", () => {
        this.player.error(null);
        this.player.reset();
        this.player.controlBar.hide();
      });

      let videoURL = [];
      this.props.stateInfo?.data?.MediaURL?.forEach((element) => {
        let obj = {
          sources: [
            {
              src: element.BucketURL ? element.BucketURL : "",
              type: element.BucketURL ? "video/mp4" : "",
            },
          ],
          poster: element.ThumbnailURL ? element.ThumbnailURL : "",
          thumbnail: element.ThumbnailURL ? element.ThumbnailURL : ThumbnailUrl,
        };
        videoURL.push(obj);
      });

      // set start and end times for snippet calls or URLs with queryParams &st, &et
      if (parseInt(this.props.snippetStartTime) > 0 && videoURL[0]?.sources[0]?.src) {
        let mVideoURL = this.props.isEndTimePresentInQueryString
          ? videoURL[0]?.sources[0]?.src + "#t=" + this.props.snippetStartTime + "," + this.props.snippetEndTime
          : videoURL[0]?.sources[0]?.src + "#t=" + this.props.snippetStartTime;
        videoURL[0].sources[0].src = mVideoURL;
      }

      //creates a playlist
      comp.player.playlist(videoURL);
      let indexOfCurrentItem = this.player.playlist.currentItem();

      if (indexOfCurrentItem === 0 && this.props.stateInfo?.playlistIndex >= 0) {
        comp.player.playlist.currentItem(this.props.stateInfo?.playlistIndex);
      }
      this.setNode();

      this.player.playlist.autoadvance(0);
      // }
    } catch (err) {
      this.setState({ errMsg: "File ID not found" });
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }
  handleChange = (event, value) => {
    this.setState({
      value: value,
    });
  };

  setScrollToIndex = (index) => {
    this.setState({ scrollToIndex: index });
  };
  setPosition = (position) => {
    this.setState({ position: position });
    this.changeVideoPlayTime(position);
  };

  getAcroyn(str) {
    if (str && str !== "") {
      let acronym = str.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "");
      return acronym.toUpperCase();
    } else return;
  }

  changeVideoPlayTime(startTime) {
    this.player.currentTime(startTime);
  }

  getStartTime = () => {
    return new Date(this.state.meetingDetails?.StartTime._seconds * 1000).toString();
  };
  formatDuration(index) {
    if (this.state.meetingDetails?.MediaURL[index].StartTime !== null) {
      let startValue = this.state.meetingDetails?.MediaURL[index].StartTime._seconds;
      let endValue = this.state.meetingDetails?.MediaURL[index].EndTime._seconds;

      var startDate = new Date(startValue * 1000).getTime();
      var endDate = new Date(endValue * 1000).getTime();

      var ms = endDate - startDate;

      let seconds = Math.floor((ms / 1000) % 60);
      let minutes = Math.floor((ms / 1000 / 60) % 60);
      let hours = Math.floor((ms / 1000 / 3600) % 24);

      minutes = String(minutes).padStart(2, "0");
      hours = String(hours).padStart(2, "0");
      seconds = String(seconds).padStart(2, "0");
      return hours + ":" + minutes + ":" + seconds;
    } else {
      return "no time available";
    }
  }

  convertSecondsToMin(totalSeconds) {
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = Math.floor(totalSeconds % 60);

    minutes = String(minutes).padStart(2, "0");
    hours = String(hours).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");
    return hours + ":" + minutes + ":" + seconds;
  }

  setNode = () => {
    this.props.setRef(this);
  };

  playlistClick(index) {
    try {
      let sentencesArr = this.state.meetingDetails?.sentences.filter((sen) => sen.MediaIndex === index);
      let transcriptResponse = {
        text: sentencesArr,
        transcriptUrl: this.state.meetingDetails?.TranscriptsURL[index],
      };
      this.setState({ transcripts: transcriptResponse, indexPlaylist: index });
      this.player.playlist.currentItem(index);
    } catch (err) {}
  }

  render() {
    return (
      <>
        <React.Fragment>
          <React.Fragment>
            {this.props.stateInfo ? (
              <React.Fragment>
                <div id="videoPlayer" className="main-preview-player">
                  <div data-vjs-player hidden={!this.props.stateInfo.audioURL}>
                    <video ref={(node) => (this.videoNode = node)} className="video-js size vid"></video>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <LinearProgress />
            )}
          </React.Fragment>
        </React.Fragment>

        <div className={styles.fauxVideoContainer} hidden={this.props.stateInfo.audioURL}>
          <div className={styles.fauxVideoSpeakerInfo}>
            <Typography style={{ textTransform: "none" }} variant="h4" gutterBottom component="div">
              {constants.ERROR_NO_RECORDING_AVAILALE}
            </Typography>
          </div>
        </div>
      </>
    );
  }
}
