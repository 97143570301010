let config = {
  MEDDIC_SINGLESELECT: process.env.REACT_APP_MEDDIC_SINGLESELECT,
  PCA_CLIENT_URL: process.env.REACT_APP_PCA_CLIENT_URL,
  PCA_SERVER_URL: process.env.REACT_APP_PCA_SERVER_URL,
  PCA_SEARCH_URL: process.env.REACT_APP_PCA_CLIENT_URL + process.env.REACT_APP_PCA_SEARCH_URL,
  CALENDAR_HOME_URL: process.env.REACT_APP_CALENDAR_HOME_URL,
  CALENDAR_MEETING_URL: process.env.REACT_APP_CALENDAR_HOME_URL + process.env.REACT_APP_CALENDAR_MEETING_URL,
  CALENDAR_REDIRECT_LOGIN_URL: process.env.REACT_APP_CALENDAR_HOME_URL + process.env.REACT_APP_CALENDAR_REDIRECT_LOGIN_URL,
  CALENDAR_INTEGRATION_URL: process.env.REACT_APP_CALENDAR_HOME_URL + process.env.REACT_APP_CALENDAR_INTEGRATION_URL,
  CALENDAR_SETTINGS_URL: process.env.REACT_APP_CALENDAR_HOME_URL + process.env.REACT_APP_CALENDAR_SETTINGS_URL,
  GRYPH_HOME_URL: process.env.REACT_APP_GRYPH_HOME_URL,
  GRYPH_URL: process.env.REACT_APP_GRYPH_HOME_URL + process.env.REACT_APP_GRYPH_URL,
  GRYPH_ADMIN_URL: process.env.REACT_APP_GRYPH_HOME_URL + process.env.REACT_APP_GRYPH_ADMIN_URL,
  INSIGHTS_URL: process.env.REACT_APP_INSIGHTS_URL,
  API_GET_USER_SESSION: process.env.REACT_APP_API_GET_USER_SESSION,
  API_EXTEND_SESSION: process.env.REACT_APP_API_EXTEND_SESSION,
  API_END_SESSION: process.env.REACT_APP_API_END_SESSION,
  API_INSIGHTS_SESSION: process.env.REACT_APP_API_INSIGHTS_SESSION,
  MINIMUM_DATA_POINTS_TO_PROMOTE: process.env.REACT_APP_MINIMUM_DATA_POINTS_TO_PROMOTE,
};

export default config;
