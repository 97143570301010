import React, { useEffect } from "react";
import * as constants from "../../assets/constants";
import callMetadataStyles from "./CallMetadata.css";
import "./CallMetadata.css";
import { Typography } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import HtmlTooltip from "../../assets/components/HtmlTooltip";
import Fade from "@material-ui/core/Fade";
import Button from "@mui/material/Button";
import classNames from "classnames";

const CallMetadata = (props) => {
  const styles = callMetadataStyles();
  const initialMetadataInfo = {
    UserType: "",
    ClientKey: 0,
    FirstName: "Gryphon",
    LastName: "User",
    EmailAddress: "",
    Status: "",
    Role: "",
    Office: "",
    CallAccountName: "",
    CallContactName: "",
    CallOpportunity: "",
    FollowUpKey: "",
    Action: "",
    ActionText: "None",
    DialedNumber: "0",
    DialingNumber: "0",
    Number: "0",
  };

  const [metadataInfo, setMetadataInfo] = React.useState(initialMetadataInfo);
  const [avatarInitial, setAvatarInitial] = React.useState(initialMetadataInfo.FirstName.charAt(0));
  const [noOfParticipants] = React.useState(props.isVideoFile ? props?.dataForSnip?.data?.Participants?.length : 2);

  useEffect(() => {
    (async () => {
      try {
        // get call metadata summary
        await getCallMetadataInfo();
      } catch (err) {
        props.setErrorObject({
          isError: true,
          msgError: "ERR: useEffect load failed inside CallMetadata",
        });
      }
    })();
  }, [props.callMetadataInfo]);

  const getCallMetadataInfo = async () => {
    // getCallMetadata
    try {
      let callMetadataRes = props.callMetadataInfo;
      if (props.isVideoFile) {
        callMetadataRes.data.FirstName = props.dataForSnip?.data?.HostName;
        callMetadataRes.data.LastName = "";
      }
      if (callMetadataRes.data != null) {
        setMetadataInfo(callMetadataRes.data);
        setAvatarInitial(constants.getAcroyn(callMetadataRes?.data?.FirstName + " " + callMetadataRes?.data?.LastName));
      }
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <div className={styles.callMetadataRoot}>
        {props.dockState ? (
          <div>
            <div>
              <Typography style={{ textTransform: "capitalize" }} className={styles.callTitle}>
                {props.isVideoFile
                  ? props.dataForSnip?.data?.Topic
                    ? props.dataForSnip?.data?.Topic
                    : "Call"
                  : metadataInfo.CallContext
                  ? metadataInfo.CallContext
                  : "Call"}{" "}
                {metadataInfo.CallAccountName !== "" ? "/ " + metadataInfo.CallAccountName : undefined}
              </Typography>
              <Typography className={classNames(styles.callSubTitle, styles.participantText)}>
                {constants.formatTimeStampToEST(props.dataForSnip.data.Timestamp)}
              </Typography>
              {constants.formatPhoneNumber(metadataInfo.Number) !== "0" ? (
                <Typography className={classNames(styles.callSubTitle, styles.participantText)}>
                  Number: {constants.formatPhoneNumber(metadataInfo.Number)}
                </Typography>
              ) : undefined}
              <Typography className={classNames(styles.callSubTitle, styles.participantText)}>
                Disposition: {metadataInfo.ActionText ? metadataInfo.ActionText : "None"}
              </Typography>
            </div>
            <div className={styles.callInfo}>
              <div className={styles.meetingInfo}>
                <Avatar sx={{ width: 48, height: 48 }} className={styles.hostColor}>
                  {avatarInitial}
                </Avatar>
                <div className={styles.participantDiv}>
                  <Typography className={styles.participantText}>
                    {metadataInfo.FirstName.toLowerCase()} {metadataInfo.LastName.toLowerCase()}
                  </Typography>
                  <Typography style={{ color: "#495057" }} className={styles.participantText}>
                    Meeting Host
                  </Typography>
                  <Typography className={styles.participantText}>{noOfParticipants} Participants</Typography>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.callInfoDocked}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography className={styles.participantText}>
                    {metadataInfo.FirstName.toLowerCase()} {metadataInfo.LastName.toLowerCase()}
                  </Typography>
                  <Typography className={styles.participantText}>Meeting Host</Typography>
                  <Typography className={styles.participantText}>{noOfParticipants} Participants</Typography>
                </React.Fragment>
              }
              placement="right"
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 200 }}
            >
              <Button variant="text" sx={{ borderRadius: "10px", outline: "0 !important" }} onClick={props.handleDrawerOpen}>
                <Avatar sx={{ width: 48, height: 48 }} className={styles.hostColor}>
                  {avatarInitial}
                </Avatar>
              </Button>
            </HtmlTooltip>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CallMetadata;
