import React from "react";
import * as constants from "../../assets/constants";
import playlistPanelStyles from "./PlaylistPanel.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Grid, Divider } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Typography } from "@material-ui/core";
import AudioCallAnalysis from "../audio-call-analysis/AudioCallAnalysis";
import SnippetPanel from "../snippet-panel/SnippetPanel";
import ThumbnailUrl from "../../assets/imgs/VideoThumbnail.jpeg";
import Alert from "@mui/material/Alert";

const PlaylistPanel = (props) => {
  const styles = playlistPanelStyles();

  function formatDuration(index) {
    if (props.stateInfo?.data?.MediaURL[index].BucketURL) {
      if (props.stateInfo?.data?.MediaURL[index].StartTime !== null) {
        let startValue = props.stateInfo?.data?.MediaURL[index].StartTime._seconds;
        let endValue = props.stateInfo?.data?.MediaURL[index].EndTime._seconds;

        var startDate = new Date(startValue * 1000).getTime();
        var endDate = new Date(endValue * 1000).getTime();

        var ms = (endDate - startDate) / 1000;

        return constants.toStringHHMMSS(ms, ms > 3600);
      } else {
        return "Duration unavailable";
      }
    } else return "Recording unavailable";
  }

  function formatSnippetDuration() {
    if (props.stateInfo?.data?.SnippetInfo) {
      let startValue = props.stateInfo?.data?.SnippetInfo?.sourceStartTime;
      let endValue = props.stateInfo?.data?.SnippetInfo?.sourceEndTime;
      var startDate = new Date(startValue * 1000).getTime();
      var endDate = new Date(endValue * 1000).getTime();
      var ms = (endDate - startDate) / 1000;
      return constants.toStringHHMMSS(ms, ms > 3600);
    } else {
      return "Timestamp unavailable";
    }
  }

  function playlistClick(index) {
    try {
      props.setPlaylistIndex(index);
      let sentencesArr = props.stateInfo?.data?.sentences.filter((sen) => sen.MediaIndex === index);
      props.setSentences(sentencesArr);
      setSnippetTimes(index, sentencesArr);
      props.toggleOffSnip();
      props.resetSpeed();
    } catch (err) {}
  }

  function setSnippetTimes(index, sentencesArr) {
    try {
      if (index >= 1) {
        props.setParentState({
          dragStartTime: sentencesArr[0]?.StartTime?.second,
          dragEndTime: sentencesArr[sentencesArr.length - 1]?.StartTime?.second,
        });
      } else {
        props.setParentState({
          dragStartTime: 0,
          dragEndTime: sentencesArr[sentencesArr.length - 1]?.EndTime?.second,
        });
      }
    } catch (err) {}
  }

  return (
    <React.Fragment>
      <div className={styles.playlistPanelRoot}>
        {!props.stateInfo?.data?.SnippetInfo?.isAudio ? (
          <div className={styles.mediaPlaylist}>
            <Typography
              style={{
                margin: "8px",
                marginLeft: "16px",
                float: "left",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "22px",
                color: "#495057",
              }}
            >
              {props.isSnippetCall ? "Video Snippet Recording Files" : "Video Recording Files"}
            </Typography>
            {props.stateInfo?.data?.MediaURL?.length > 0 ? (
              props.stateInfo?.data?.MediaURL?.map((item, index) => {
                return (
                  <Card
                    key={`cplay_${index}`}
                    elevation={0}
                    sx={{
                      display: "flex",
                      margin: "15px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      width: "90%",
                      cursor: "pointer",
                    }}
                    onClick={() => playlistClick(index)}
                  >
                    <Grid spacing={0} container>
                      <Grid item xs={3}>
                        <CardMedia
                          component="img"
                          height="100"
                          image={item.ThumbnailURL ? item.ThumbnailURL : ThumbnailUrl}
                          alt={"thumbnail"}
                          sx={
                            props.videoRef.player
                              ? props.playlistIndex === index && props.isVideoFile && !props.isAudioPlayingInPlaylist
                                ? {
                                    border: "2.5px solid #88B3DA",
                                    borderRadius: "10px",
                                    padding: "5px",
                                  }
                                : {
                                    borderRadius: "10px",
                                  }
                              : null
                          }
                        />
                      </Grid>

                      <Grid item xs={9}>
                        <Box>
                          <CardContent sx={{ padding: "10px" }}>
                            <Typography variant="subtitle2" component="div" align="left">
                              {props.isSnippetCall
                                ? props.stateInfo?.data?.SnippetInfo?.name
                                  ? props.stateInfo?.data?.SnippetInfo?.name
                                  : "Snippet file"
                                : `${props.stateInfo?.data?.Topic} : Part ${index + 1}`}
                            </Typography>
                            <Typography variant="caption" color="text.secondary" component="div" align="left">
                              {props.isSnippetCall
                                ? props.stateInfo?.data?.SnippetInfo?.createdOn
                                  ? constants.formatTimeStampToEST(props.stateInfo?.data?.SnippetInfo?.createdOn)
                                  : "Timestamp unavailable"
                                : props.stateInfo?.data &&
                                  props.stateInfo?.data?.MediaURL[index].StartTime != null &&
                                  props.stateInfo?.data?.MediaURL[index].StartTime._seconds
                                ? constants.formatTimeStampToEST(props.stateInfo?.data?.MediaURL[index].StartTime)
                                : "Timestamp unavailable"}
                            </Typography>
                            <Typography variant="caption" color="text.secondary" align="left" component="div">
                              {props.isSnippetCall ? formatSnippetDuration() : formatDuration(index)}
                            </Typography>
                          </CardContent>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                );
              })
            ) : (
              <Alert className={styles.alertBox} severity="warning">
                {constants.ERROR_NO_RECORDING_AVAILALE}
              </Alert>
            )}
          </div>
        ) : undefined}

        {props.stateInfo?.data?.AudioURL?.length > 0 ? (
          <React.Fragment>
            {!props.stateInfo?.data?.SnippetInfo?.isAudio ? (
              <Divider
                sx={{
                  marginTop: "8px",
                }}
              />
            ) : undefined}
            <AudioCallAnalysis
              {...props}
              stateData={props.stateInfo?.data}
              setSentences={props.setSentences}
              seekFromGraph={props.seekFromGraph}
              setMediaUrl={props.setMediaUrl}
              setPlaylistIndex={props.changePlaylistIndexFromAudio}
              setIsAudio={props.setIsAudio}
              videoRef={props.videoRef}
              isAudioPlayingInPlaylist={props.isAudioPlayingInPlaylist}
              setindexAudio={props.setindexAudio}
              audioIndex={props.audioIndex}
              muteAudio={props.muteAudio}
              resetSpeed={props.resetSpeed}
            ></AudioCallAnalysis>
          </React.Fragment>
        ) : undefined}
        {props.stateInfo?.data?.Snippets && props.stateInfo?.data?.Snippets?.length > 0 ? (
          <div>
            <Divider
              sx={{
                marginTop: "8px",
              }}
            />
            <SnippetPanel Snippets={props.stateInfo.data.Snippets}></SnippetPanel>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default PlaylistPanel;
