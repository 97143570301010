// import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete from "@mui/material/Autocomplete";
import { withStyles } from "@material-ui/core/styles";

const StyledAutocomplete = withStyles({
  root: {
    marginBottom: "8px",
  },
})(Autocomplete);

export default StyledAutocomplete;
