import config from "../config/config";
import fetchError from "../helper/customException";

export default async function getAllFilesService(docId, page, rowsPerPage, next, options, clientKey) {
  try {
    let from = new Date();
    from.setDate(from.getDate() - 10);
    let to = new Date();
    const response = await fetch(
      config.PCA_SERVER_URL + "files",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          docId: docId,
          pageSize: rowsPerPage,
          pageNo: page,
          next: next,
          clientKey: clientKey,
          options: options
            ? {
                timeframe: {
                  start: options.timeframe.start,
                  end: options.timeframe.end,
                },
              }
            : {
                timeframe: {
                  start: from,
                  end: to,
                },
              },
        }),
      },
      2 * 10 * 60 * 1000
    );
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response;
  } catch (err) {
    if (typeof err.text === "function") {
      //error in fetch call from server side
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      //error in front end code
      throw new Error(err);
    }
  }
}
