import React, { useEffect, useState } from "react";
import * as constants from "../../../assets/constants";
import styles from "./AnnotationModal.module.css";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import { TextField } from "@material-ui/core";
import { Button } from "@mui/material";
import { Typography } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import HtmlTooltip from "../../../assets/components/HtmlTooltip";
import editFileById from "../../../services/editFileService";
import * as dataManipulations from "../../../assets/dataManipulations";
import { removeUtteranceFlagsSelected, createUtteranceFlagsSelected, saveUtteranceFlagsAndKPIs } from "../../../services/utteranceFlagService";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import StyledAutocomplete from "../../../assets/components/StyledAutocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import StyledBasicChip from "../../../assets/components/StyledBasicChip/StyledBasicChip";
import classNames from "classnames";
import config from "../../../config/config";

const AnnotationModal = (props) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const theme = useTheme();
  const [disableSave, setDisableSave] = useState(true);
  const [categorySel, setCategorySel] = useState("");
  const [subcategorySel, setSubcategorySel] = useState("");
  const [utteranceFlagsCategory, setUtteranceFlagsCategory] = React.useState([]);
  const [utteranceFlagsSubcategories, setUtteranceFlagsSubcategories] = React.useState([]);
  const [utteranceFlagOptions, setUtteranceFlagOptions] = React.useState([]);
  const [currentUtteranceFlagsSelected, setCurrentUtteranceFlagsSelected] = React.useState([]);
  const [currentUtterancePreviousFlagsSelected, setCurrentUtterancePreviousFlagsSelected] = React.useState([]);

  useEffect(() => {}, [props.userInfo]);

  useEffect(() => {}, [props.tempSentences, props.index]);

  useEffect(() => {
    setDisableSave(true);
    clearSelections();
  }, [props.index]);

  useEffect(() => {
    (() => {
      try {
        // get utterance flag summary
        if (props.utteranceFlags?.length !== 0) {
          setUtteranceFlagsCategory([...new Set(props.utteranceFlags.map((item) => item.Category))]);
          setUtteranceFlagsSubcategories([...new Set(props.utteranceFlags.map((item) => item.Subcategory))]);
        }
      } catch (err) {}
    })();
  }, [props.utteranceFlags]);

  useEffect(() => {}, [props.utteranceFlagsSelected]);

  const handleFlagChange = (e, value) => {
    setDisableSave(false);
    setCurrentUtteranceFlagsSelected(value);
  };

  const saveFlagChange = async () => {
    try {
      // find unique in both objects
      const unique = (a) => (o) => !a.some(({ FlagID }) => o.FlagID === FlagID);
      let uniqueFirst = currentUtterancePreviousFlagsSelected.filter(unique(currentUtteranceFlagsSelected));
      let uniqueSecond = currentUtteranceFlagsSelected.filter(unique(currentUtterancePreviousFlagsSelected));

      let removeSelectedFlags = {
        UtteranceFlagIDArr: [],
        UserAddedKPIFlagIDArr: [],
      };
      let createSelectedFlags = {
        UtteranceFlagIDArr: [],
        UserAddedKPIFlagIDArr: [],
      };
      // utterance Delete for API for updates
      if (uniqueFirst?.length !== 0) {
        // API Utterance
        uniqueFirst.forEach((obj) => {
          if (obj.UserAddedKPI) {
            removeSelectedFlags.UserAddedKPIFlagIDArr.push(obj);
          } else {
            removeSelectedFlags.UtteranceFlagIDArr.push(obj.FlagID);
          }
        });
      }
      // utterance Save API for updates
      if (uniqueSecond?.length !== 0) {
        // API Utterance
        uniqueSecond.forEach((obj) => {
          if (obj.UserAddedKPI) {
            createSelectedFlags.UserAddedKPIFlagIDArr.push(obj);
          } else {
            createSelectedFlags.UtteranceFlagIDArr.push(obj.FlagID);
          }
        });
      }
      // when only utterance flags are affected
      if (removeSelectedFlags.UserAddedKPIFlagIDArr.length === 0 && createSelectedFlags.UserAddedKPIFlagIDArr.length === 0) {
        // remove utterance flags
        if (removeSelectedFlags.UtteranceFlagIDArr.length > 0) {
          let params = {
            ConversationID: props.fileId,
            FlagIDArr: removeSelectedFlags.UtteranceFlagIDArr,
            MessageID: props.tempSentences[props.index].msgID,
          };
          await removeUtteranceFlagsSelected(params);
        }
        // save utterance flags
        if (createSelectedFlags.UtteranceFlagIDArr.length > 0) {
          let params = {
            ConversationID: props.fileId,
            FlagIDArr: createSelectedFlags.UtteranceFlagIDArr,
            UserKey: props.userKey,
            MessageID: props.tempSentences[props.index].msgID,
            Content: props.tempSentences[props.index]?.sentence,
            StartTime: parseInt(props.tempSentences[props.index].start),
          };
          await createUtteranceFlagsSelected(params);
        }
      } else {
        // save both utterance flags and user-added-KPIs
        let params = {
          ClientKey: props.clientKey,
          ConversationID: props.fileId,
          UserKey: props.userKey,
          MessageID: props.tempSentences[props.index].msgID,
          Content: props.tempSentences[props.index]?.sentence,
          StartTime: parseInt(props.tempSentences[props.index].start),
          Category: categorySel,
          RemoveFlags: removeSelectedFlags,
          CreateFlags: createSelectedFlags,
        };
        await saveUtteranceFlagsAndKPIs(params);
      }
      // Refresh utterance flag summary
      if (uniqueFirst?.length !== 0 || uniqueSecond?.length !== 0) {
        await props.refreshUtteranceFlags();
      }
      clearSelections();
      props.handleModalClose();
      setDisableSave(true);
    } catch (err) {
      await props.refreshUtteranceFlags();
      clearSelections();
      props.handleModalClose();
      setDisableSave(true);
      props.setCustomErrorObject({
        isError: true,
        msgError: "ERR: Failed to save selected flags",
      });
    }
  };

  const clearSelections = () => {
    setCategorySel("");
    setSubcategorySel("");
    setCurrentUtteranceFlagsSelected([]);
    setCurrentUtterancePreviousFlagsSelected([]);
  };

  const handleCategoryChange = (event) => {
    setSubcategorySel("");
    setCategorySel(event.target.value);
    if (utteranceFlagsCategory.includes(event.target.value)) {
      let res = props.tempSentences[props.index].utteranceFlags.filter(
        (item) => item.MessageID === props.tempSentences[props.index].msgID && event.target.value === item.Category
      );
      setCurrentUtteranceFlagsSelected(res);
      setCurrentUtterancePreviousFlagsSelected(res);
    }
  };

  const handleSubcategoryChange = (event) => {
    setSubcategorySel(event.target.value);
    if (utteranceFlagsSubcategories.includes(event.target.value)) {
      let res = props.tempSentences[props.index].utteranceFlags.filter(
        (item) => item.MessageID === props.tempSentences[props.index].msgID && event.target.value === item.Subcategory
      );
      let flagsOpts = getDescriptionBySubcategory(props.index, event.target.value);
      setUtteranceFlagOptions(flagsOpts);
      setCurrentUtteranceFlagsSelected(res);
      setCurrentUtterancePreviousFlagsSelected(res);
    }
  };

  const handleKPIChange = (rowIndex, event, meddic) => {
    let messageId = props.tempSentences[props.index].msgID;
    let kpi_arr = event.target.value;

    if (kpi_arr[kpi_arr.length - 1] === "None") {
      kpi_arr = ["None"];
    } else {
      kpi_arr = kpi_arr.filter((kpi) => {
        return kpi !== "None";
      });
    }

    if (meddic && config.MEDDIC_SINGLESELECT === "true") {
      kpi_arr = [kpi_arr[kpi_arr.length - 1]];
    }
    let participants = props.isVideoFile ? props.data.Participants : [];
    let sen = dataManipulations.getSentenceData(props.data.sentences, participants, props.isVideoFile, props.utteranceFlagsSelected);
    props.updateMyArray(sen);

    let sentencesToSet = props.tempSentences.map((item) => {
      if (meddic) {
        if (messageId === item.msgID) {
          return {
            ...item,
            mkpi: kpi_arr,
          };
        } else {
          return {
            ...item,
          };
        }
      } else {
        if (messageId === item.msgID) {
          return {
            ...item,
            kpi: kpi_arr,
          };
        } else {
          return {
            ...item,
          };
        }
      }
    });
    props.updateTempSentences([...sentencesToSet]);
    setDisableSave(false);
  };

  const getFiltetedSentence = (sentences, tempSentences) => {
    let filterArray = [];

    for (const element of sentences) {
      let filterElement = tempSentences.find((e) => e.msgID === element.msgID);
      if (filterElement) {
        element.kpi = filterElement.kpi;
        element.mkpi = filterElement.mkpi;
      }
      filterArray.push(element);
    }
    return filterArray;
  };

  const saveKPIChange = async () => {
    try {
      const { setSentences, kpiInfos } = props;
      let fileId = props.data.ConversationID;
      let filterArray = getFiltetedSentence(props.sentences, props.tempSentences);
      let filteredSentences = filterArray.filter((sentence) => {
        return sentence.sentence;
      });

      let sentencesToSend = constants.getRawSentences(props.data.sentences, filteredSentences, kpiInfos);
      setSentences(sentencesToSend);
      await editFileById(fileId, sentencesToSend, props.isMeddic, props.userKey);
      props.handleOpenSnack(true, "File saved with new changes");
      clearSelections();
      props.handleModalClose();
      setDisableSave(true);
    } catch (err) {}
  };

  const getSubCategoriesByCategory = (category) => {
    let catList = props.utteranceFlags.filter((cat) => cat.Category === category);
    let noneIndex = catList.findIndex((item) => item.Subcategory === "None");
    catList.splice(noneIndex, 1);
    return [...new Set(catList.map((item) => item.Subcategory))];
  };

  const getDescriptionBySubcategory = (index, subcategory) => {
    let subcatDescList = props.utteranceFlags.filter((cat) => cat.Subcategory === subcategory);
    let res = [];
    if (props.tempSentences[index]?.utteranceFlags?.length > 0) {
      res = subcatDescList.map((item) => {
        const idx = props.tempSentences[index]?.utteranceFlags?.findIndex((c) => c.FlagID === item.FlagID);
        const objNode = idx !== -1 ? props.tempSentences[index]?.utteranceFlags[idx] : {};
        return {
          ...item,
          ...objNode,
        };
      });
      return res;
    } else {
      return subcatDescList;
    }
  };

  const getDescriptionByCategory = (index, category) => {
    let catDescList = props.utteranceFlags.filter((cat) => cat.Category === category);
    let res = [];
    if (props.tempSentences[index]?.utteranceFlags?.length > 0) {
      res = catDescList.map((item) => {
        const idx = props.tempSentences[index]?.utteranceFlags?.findIndex((c) => c.FlagID === item.FlagID);
        const objNode = idx !== -1 ? props.tempSentences[index]?.utteranceFlags[idx] : {};
        return {
          ...item,
          ...objNode,
        };
      });
      return res;
    } else {
      return catDescList;
    }
  };

  const getStylesMaterial = (name, selectedCriticalBehaviours) => {
    if (selectedCriticalBehaviours === undefined) selectedCriticalBehaviours = "None";
    return selectedCriticalBehaviours && selectedCriticalBehaviours.indexOf(name) === -1
      ? {
          fontWeight: 300,
        }
      : {
          fontWeight: 300,
          backgroundColor: "#f9f9f9",
          color: "#1077a8",
        };
  };

  const renderOption = (props, option, { selected }) => {
    return (
      <div
        style={{
          cursor: props.isAdmin ? "pointer" : selected ? "not-allowed" : "pointer",
        }}
      >
        <li
          {...props}
          style={{
            background: selected ? "#F5F5F5" : undefined,
            color: selected ? "#1077a8" : undefined,
            pointerEvents: props.isAdmin ? "auto" : option.Reviewed ? "none" : "auto",
          }}
        >
          <Typography className="optionUL" key={option.FlagID} value={option.FlagID}>
            {option.Description}{" "}
          </Typography>
        </li>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Modal
        disableRestoreFocus={true}
        open={props.openAnnotationModal}
        onClose={props.handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper className={styles.annotationModalContainer}>
          <WhiteTextTypography variant="subtitle2">Add Annotation</WhiteTextTypography>
          <br />
          {/* CATEGORY SELECT */}
          <FormControl fullWidth={true}>
            <InputLabel className={styles.inputLabel} id="demo-simple-select-helper-label">
              Category
            </InputLabel>
            <Select
              sx={{ borderRadius: "10px", fontFamily: "Inter, sans-serif" }}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={categorySel}
              label="Type"
              onChange={handleCategoryChange}
            >
              {props.isAdmin ? <StyledMenuItem value={"Key Moment"}>Key Moment</StyledMenuItem> : undefined}
              {props.isMeddic &&
              (props.tempSentences[props.index]?.speaker === "HOST" ||
                props.tempSentences[props.index]?.speaker === "AGENT" ||
                props.tempSentences[props.index]?.speaker === "HUMAN_AGENT") &&
              props.isAdmin ? (
                <StyledMenuItem value={"MEDDICC"}>MEDDICC</StyledMenuItem>
              ) : undefined}
              {utteranceFlagsCategory.length > 0
                ? utteranceFlagsCategory.map((item, index) => (
                    <StyledMenuItem key={`am-modal-mi-${index}`} value={item}>
                      {item}
                    </StyledMenuItem>
                  ))
                : undefined}
            </Select>
          </FormControl>
          <br></br>
          <br></br>
          {/* SUB-CATEGORY / KPI OPTIONS SELECT */}
          <FormControl fullWidth={true}>
            {categorySel === "Key Moment" ? (
              // props.isEdit ? (
              props.tempSentences[props.index]?.sentence ? (
                <React.Fragment>
                  <InputLabel className={styles.inputLabel} id="demo-simple-select-helper-label">
                    Key Moment
                  </InputLabel>
                  <Select
                    sx={{ borderRadius: "10px", fontFamily: "Inter, sans-serif" }}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={props.tempSentences[props.index].kpi}
                    onChange={(event) => handleKPIChange(props.index, event, false)}
                    input={<OutlinedInput label="Text" />}
                    MenuProps={MenuProps}
                  >
                    {/* CUSTOMER KPI OPTIONS SELECT */}
                    {props.sentences[props.index].speaker === constants.CUSTOMER || props.sentences[props.index].speaker === constants.END_USER
                      ? props.optionsForCustomer.map((name, key) =>
                          props.list[props.index].KPI[0] ? (
                            <StyledMenuItem key={key} value={name} style={getStylesMaterial(name, props.list[props.index].KPI[0].value, theme)}>
                              {name}
                            </StyledMenuItem>
                          ) : null
                        )
                      : // {/* AGENT KPI OPTIONS SELECT */}
                        props.optionsForAgent.map((name, key) =>
                          props.list[props.index].KPI[0] ? (
                            <StyledMenuItem key={key} value={name} style={getStylesMaterial(name, props.list[props.index].KPI[0].value, theme)}>
                              {name}
                            </StyledMenuItem>
                          ) : null
                        )}
                  </Select>
                </React.Fragment>
              ) : undefined
            ) : null}
            {/* MEDDICC KPI OPTIONS SELECT */}
            {categorySel === "MEDDICC" ? (
              props.tempSentences[props.index].sentence ? (
                <React.Fragment>
                  <InputLabel className={styles.inputLabel} id="demo-simple-select-helper-label">
                    Meddicc
                  </InputLabel>
                  <Select
                    sx={{ borderRadius: "10px", fontFamily: "Inter, sans-serif" }}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={props.tempSentences[props.index].mkpi}
                    onChange={(event) => handleKPIChange(props.index, event, true)}
                    input={<OutlinedInput label="Text" />}
                    MenuProps={MenuProps}
                  >
                    {props.optionsForMeddic.map((name, key) =>
                      props.list[props.index].MEDDIC_KPI ? (
                        <StyledMenuItem key={key} value={name} style={getStylesMaterial(name, props.list[props.index].MEDDIC_KPI[0]?.value, theme)}>
                          {name}
                        </StyledMenuItem>
                      ) : null
                    )}
                  </Select>
                </React.Fragment>
              ) : undefined
            ) : null}
            {/* FLAG OPTIONS SELECT */}
            {utteranceFlagsCategory.includes(categorySel) ? (
              getSubCategoriesByCategory(categorySel)[0] !== null ? (
                // display subcategories
                <FormControl fullWidth={true}>
                  <InputLabel className={styles.inputLabel} id="demo-simple-select-helper-label">
                    Subcategory
                  </InputLabel>
                  <Select
                    sx={{ borderRadius: "10px", fontFamily: "Inter, sans-serif" }}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={subcategorySel}
                    label="Subcategory"
                    onChange={handleSubcategoryChange}
                  >
                    {getSubCategoriesByCategory(categorySel).map((item, index) => (
                      <StyledMenuItem key={`am-fc-ufc-mi-${index}`} value={item}>
                        {item}
                      </StyledMenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : // display description for no subcategory
              props.tempSentences[props.index].sentence ? (
                <React.Fragment>
                  <StyledAutocomplete
                    id="utterance-flags1"
                    options={getDescriptionByCategory(props.index, categorySel)}
                    value={currentUtteranceFlagsSelected}
                    noOptionsText="No Flag Reasons"
                    disableCloseOnSelect={true}
                    multiple
                    getOptionLabel={(option) => option.Description}
                    limitTags={1}
                    renderOption={renderOption}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => (
                        <StyledBasicChip
                          key={`am-ac-sbc-${index}`}
                          {...getTagProps({ index })}
                          label={option.Description}
                          isAdmin={!option.Reviewed || props.isAdmin}
                        />
                      ));
                    }}
                    renderInput={(params) => <StyledTextField {...params} variant="outlined" label="Select item(s)" />}
                    clearIcon={<ClearIcon fontSize="small" style={{ display: "none" }} />}
                    onChange={(event, value) => handleFlagChange(event, value, props.tempSentences[props.index].msgID)}
                    isOptionEqualToValue={(option, value) => option.FlagID === value.FlagID}
                  />
                </React.Fragment>
              ) : undefined
            ) : undefined}
          </FormControl>
          <br></br>
          <br></br>
          {/* CATEGORY SELECT */}
          <FormControl fullWidth={true}>
            {utteranceFlagsSubcategories.includes(subcategorySel) ? (
              utteranceFlagOptions[0] !== null ? (
                props.tempSentences[props.index].sentence ? (
                  <React.Fragment>
                    <StyledAutocomplete
                      id="utterance-flags"
                      options={utteranceFlagOptions}
                      value={currentUtteranceFlagsSelected}
                      noOptionsText="No Flag Reasons"
                      disableCloseOnSelect={true}
                      multiple
                      getOptionLabel={(option) => option.Description}
                      limitTags={1}
                      renderOption={renderOption}
                      renderInput={(params) => (
                        <StyledTextField {...params} sx={{ borderRadius: "10px" }} variant="outlined" label="Select item(s)" />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                          <StyledBasicChip
                            key={`am-fc-sbc-${index}`}
                            {...getTagProps({ index })}
                            label={option.Description}
                            isAdmin={props.isAdmin ? true : option.Reviewed ? false : true}
                          />
                        ));
                      }}
                      clearIcon={<ClearIcon fontSize="small" style={{ display: "none" }} />}
                      onChange={(event, value) => handleFlagChange(event, value, props.tempSentences[props.index].msgID)}
                      isOptionEqualToValue={(option, value) => option.FlagID === value.FlagID}
                    />
                  </React.Fragment>
                ) : undefined
              ) : undefined
            ) : undefined}
          </FormControl>
          <div className={styles.saveCardDiv}>
            <React.Fragment>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      <b>Save</b>
                    </Typography>
                    <em>{"Click to save udpated annotations"}</em>
                  </React.Fragment>
                }
                placement="left"
                arrow
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 200 }}
              >
                <Button
                  className={classNames(styles.actionButton, styles.actionButtonSave)}
                  sx={{ margin: "20px 5px", fontFamily: "Inter, sans-serif" }}
                  onClick={utteranceFlagsCategory.includes(categorySel) ? saveFlagChange : saveKPIChange}
                  variant="outlined"
                  disabled={disableSave}
                >
                  Save
                </Button>
              </HtmlTooltip>

              <Button
                className={classNames(styles.actionButton)}
                sx={{ margin: "20px 5px", fontFamily: "Inter, sans-serif" }}
                onClick={props.handleModalClose}
                variant="outlined"
              >
                Cancel
              </Button>
            </React.Fragment>
            {/* ) : null} */}
          </div>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};

export default AnnotationModal;

const WhiteTextTypography = withStyles({
  root: {
    color: "#1971C2",
    fontFamily: "Inter, sans-serif",
  },
})(Typography);

const StyledTextField = withStyles({
  root: {
    "& input": {
      color: "#495057",
      fontSize: "14px",
    },
    "& fieldset": {
      borderRadius: "10px",
      fontSize: "14px",
    },
    "& textarea": {
      color: "#495057",
      fontSize: "14px",
    },
  },
})(TextField);

const StyledMenuItem = withStyles({
  root: {
    fontFamily: "Inter, sans-serif !important",
  },
})(MenuItem);
