import { withStyles } from "@material-ui/core/styles";
import StarsIcon from "@mui/icons-material/Stars";

export const VeryPositiveIcon = withStyles(() => ({
  root: {
    color: "#0D8244",
    width: "inherit !important",
    height: "inherit !important",
    verticalAlign: "inherit",
  },
}))(StarsIcon);
