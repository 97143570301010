import React from "react";
import styles from "./Error.module.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Stack } from "@mui/material";
import { Typography } from "@material-ui/core";
import * as constants from "../../assets/constants";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: {}, info: {} };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error: error, info: info });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={styles.errorContainer}>
          <Stack spacing={3} direction="column" alignItems="center">
            <ErrorOutlineIcon className={styles.errorIcon} fontSize="large" />
            <Typography className={styles.errorMessage} variant="body1" gutterBottom component="div">
              {constants.ERROR_MSG_LOADING}
            </Typography>
          </Stack>
        </div>
      );
    }
    return this.props.children;
  }
}
