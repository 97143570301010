import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import styles from "./StyledBasicChip.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";

/* 
    Generic Component
    Expected Props: 
        isAdmin; label; onDelete;

*/

const StyledBasicChip = (props) => {
  const [display, setDisplay] = useState(false);
  const showButton = (e) => {
    e.preventDefault();
    setDisplay(true);
  };

  const hideButton = (e) => {
    e.preventDefault();
    setDisplay(false);
  };

  return (
    <Chip
      className={styles.chipMain}
      label={
        <div className={props.isAdmin ? (display ? styles.chipHover : undefined) : styles.disabledButton}>
          <span className={styles.chipLabel}>{props.label}</span>
        </div>
      }
      onMouseEnter={(e) => showButton(e)}
      onMouseLeave={(e) => hideButton(e)}
      icon={
        <div className={display ? styles.displayed : styles.notdisplayed}>
          {props.isAdmin ? (
            <IconButton onClick={props.onDelete} className={styles.buttonContainer} fontSize="small">
              <CloseRoundedIcon color="primary" fontSize="small" sx={{ color: "white" }} />
            </IconButton>
          ) : undefined}
        </div>
      }
    ></Chip>
  );
};

export default StyledBasicChip;
