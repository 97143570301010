import React from "react";
import * as cookieUtils from "../assets/cookie-utils";
import getUtteranceFlagCount from "../services/getUtteranceFlagCount.js";
import getUtteranceFlagTaggedData from "../services/getUtteranceFlagTaggedData";
import styles from "./userAddedKPIs.module.css";
import { Button } from "@material-ui/core";
import { Table, Switch } from "antd";
import { Link } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";
import utteranceFlagsPromotion from "../services/utteranceFlagsPromotion";
import updateUtteranceReviewedStatus from "../services/updateUtteranceReviewedStatus.js";
import deleteUtteranceFlag from "../services/deleteUtteranceFlag.js";
import LoadingButton from "@mui/lab/LoadingButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import StyledFlagChip from "../assets/components/StyledFlagChip/StyledFlagChip";
import { Redirect } from "react-router-dom";
import config from "../config/config";
import BootstrapTooltip from "../assets/components/BootstrapTooltip";

export default function UtteranceFlags() {
  const urlParams = new URLSearchParams(window.location.search);
  const category = urlParams.get("category") ? decodeURIComponent(urlParams.get("category")) : "";
  const clientKey = urlParams.get("clientkey") ? decodeURIComponent(urlParams.get("clientkey")) : "";

  let initialUserInfo = {
    userType: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    status: "",
    role: "",
    office: "",
    userKey: 0,
    clientKey: 0,
  };
  const [userInfo, setUserInfo] = React.useState(initialUserInfo);
  const [uttFlags, setUttFlags] = React.useState([]);
  const [promotedKPI, setPromotedKPI] = React.useState([]);
  const [setError] = React.useState({
    isError: false,
    msgError: null,
  });
  // const [isHovered, setIsHovered] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [reviewed, setReviewed] = React.useState(1);
  const [isClientAdmin, setIsClientAdmin] = React.useState(true);
  const [UFConvertedIntoUAK, setUFConvertedIntoUAK] = React.useState("");
  const [flagModified, setFlagModified] = React.useState(false);
  const [UtteranceFlagFilterEnabled, setUtteranceFlagFilterEnabled] = React.useState("not applied");
  const [minimumDataPointsToRetrain] = React.useState(config.MINIMUM_DATA_POINTS_TO_PROMOTE);

  React.useEffect(() => {
    (async () => {
      try {
        let activeUser = await cookieUtils.fetchActiveUserSession();
        setUserInfo(activeUser);
        setIsClientAdmin(activeUser.isAdmin);
      } catch (err) {
        setError({
          isError: true,
          msgError: "ERR: Failed to validate user token",
        });
      }
    })();
  }, [userInfo.clientKey]);

  React.useEffect(() => {
    (async () => {
      try {
        let response = await getUtteranceFlagCount(category, clientKey);
        setUttFlags(response.data.countUttFlags);
        // Only update UFConvertedIntoUAK if the data changes
        if (JSON.stringify(response.data.UserAddedKPIs) !== JSON.stringify(UFConvertedIntoUAK)) {
          setUFConvertedIntoUAK(response.data.UserAddedKPIs);
        }
        setFlagModified(false);
      } catch (err) {
        setError({
          isError: true,
          msgError: "ERR: Failed to validate user token",
        });
      }
    })();
  }, [clientKey, reviewed, flagModified]);

  React.useEffect(() => {
    (async () => {
      try {
        let response = await getUtteranceFlagTaggedData(category, clientKey, reviewed, currentPage, UtteranceFlagFilterEnabled);
        setItems(response.data);
      } catch (err) {
        setError({
          isError: true,
          msgError: "ERR: Failed to fetch data",
        });
      }
    })();
  }, [currentPage, reviewed, UFConvertedIntoUAK, UtteranceFlagFilterEnabled, flagModified]);

  const handlePromotion = (val) => {
    if (!promotedKPI.includes(val)) {
      const newArr = [...promotedKPI, val];
      setPromotedKPI(newArr);
    }
  };

  const removePromotion = (val) => {
    const updatedArr = promotedKPI.filter((item) => item !== val);
    setPromotedKPI(updatedArr);
  };

  const onReviewChange = (checked) => {
    if (!checked) {
      setReviewed(0);
    } else {
      setReviewed(1);
    }
    setUtteranceFlagFilterEnabled("not applied");
    setCurrentPage(1);
  };

  const handleReviewChange = async (record) => {
    record.UserKey = userInfo.userKey;
    record.FlagIDArr = [record.FlagID];
    await updateUtteranceReviewedStatus(record);
    setFlagModified(true);
  };

  const handleUtteranceFlagDelete = async (record) => {
    record.userKey = userInfo.userKey;
    await deleteUtteranceFlag(record);
    setFlagModified(true);
  };

  const promotionPressed = async () => {
    var dataToSend = {
      KPIsToPromote: promotedKPI,
      clientKey: parseInt(clientKey),
      modelCategory: category,
      userKey: userInfo.userKey,
    };
    setLoadingButton(true);
    try {
      let response = await utteranceFlagsPromotion(dataToSend);
      setFlagModified(true);
      setPromotedKPI([]);
      alert(response.message);
      setLoadingButton(false);
      window.location.href = `/UserAddedKPIs?category=${category}&clientkey=${clientKey}`;
    } catch (e) {
      setError({
        isError: false,
        msgError: "Error in Promotion",
      });
    }
  };

  const handleUtteranceFlagChange = (event) => {
    setUtteranceFlagFilterEnabled(event.target.value);
    setCurrentPage(1);
  };

  const reviewedUtteranceFlags = uttFlags?.filter((flag) => flag.Reviewed === 1);

  const dropDownFacetValuesForReviewed = uttFlags?.filter((flag) => flag.Reviewed === 1);

  const dropDownFacetValuesForUnReviewed = uttFlags?.filter((flag) => flag.Reviewed === 0 && flag.Count >= 1);

  const columns = [
    {
      title: "S.NO",
      dataIndex: "sno",
      width: "2%",
      render: (text, record, index) => index + 1 + ".",
    },
    {
      title: "File",
      key: "actions",
      width: "2%",
      render: (text, record) => (
        <Link
          target="_blank"
          to={{
            pathname: `/${record.ConversationID}`,
          }}
        >
          <LaunchIcon style={{ fill: "#16a4e6" }} />
        </Link>
      ),
    },
    {
      title: "Sentence",
      dataIndex: "Content",
      width: "35%",
    },
    {
      title: "Category",
      dataIndex: "Category",
      width: "15%",
    },
    {
      title: "Subcategory",
      dataIndex: "Subcategory",
      width: "15%",
    },
    {
      title: "Flag",
      dataIndex: "FlagName",
      width: "15%",
      render: (text, record) => {
        return record.Reviewed ? (
          <BootstrapTooltip title="Discard the Utterance" placement="left">
            <div>
              <StyledFlagChip
                isAdmin={true}
                chipText={text.toString()}
                isUtteranceFlag={true}
                isReviewed={record.Reviewed}
                isFastForward={false}
                handleFlagDelete={() => handleUtteranceFlagDelete(record)}
              ></StyledFlagChip>
            </div>
          </BootstrapTooltip>
        ) : (
          <BootstrapTooltip title="Either mark the utterance as reviewed or remove it" placement="left">
            <div>
              <StyledFlagChip
                isAdmin={true}
                chipText={text.toString()}
                isUtteranceFlag={true}
                isReviewed={record.Reviewed}
                isFastForward={false}
                handleFlagDelete={() => handleUtteranceFlagDelete(record)}
                handleFlagReview={() => handleReviewChange(record)}
              ></StyledFlagChip>
            </div>
          </BootstrapTooltip>
        );
      },
    },
  ];

  if (userInfo.clientKey !== 0) {
    if (!isClientAdmin || userInfo.clientKey !== 1) {
      return <Redirect to={"/files"}></Redirect>;
    }
  }

  return (
    <>
      {/* Code for Utterance flag labels */}
      <div className={styles.initialButtons}>
        <Link
          to={{
            pathname: "/choice",
            search: "",
          }}
        >
          <Button variant="outlined" className={styles.routingButtons}>
            <strong>Back</strong>
          </Button>
        </Link>
        {UFConvertedIntoUAK?.length < 1 ? null : (
          <>
            <Link
              to={{
                pathname: "/UserAddedKPIs",
                search: `${window.location.search}`,
              }}
            >
              <Button variant="outlined" className={styles.routingButtons}>
                <strong>User Added KPIs</strong>
              </Button>
            </Link>
            <Link
              to={{
                pathname: "/Model-History",
                search: `${window.location.search}`,
              }}
            >
              <Button variant="outlined" className={styles.routingButtons}>
                <strong>Model History</strong>
              </Button>
            </Link>
          </>
        )}
      </div>
      <div style={{ marginTop: -20, display: "block" }}>
        <strong>Records for {category}</strong>
      </div>
      <div className={styles.initialContainers}>
        {" "}
        <strong className={styles.sectionHeading}>Reviewed Utterance Flags</strong>
        <strong className={styles.sectionHeading}>Promotion Tab</strong>
        <strong className={styles.sectionHeading}>UserAddedKPIs</strong>
      </div>
      <div className={styles.initialContainers}>
        <div className={styles.flagsContainer}>
          {reviewedUtteranceFlags?.length > 0 ? (
            reviewedUtteranceFlags?.map((flag, i) => (
              <div key={i} className={flag.Count >= minimumDataPointsToRetrain ? styles.flagBodyGreen : styles.flagBodyRed}>
                {flag.FlagName}: <strong>{flag.Count}</strong>
                {flag.Count >= minimumDataPointsToRetrain ? (
                  <button className={styles.hoverButton} onClick={() => handlePromotion(flag.FlagName)}>
                    &#10004;
                  </button>
                ) : (
                  <></>
                )}
              </div>
            ))
          ) : (
            <div className={styles.noReviewedFlags}>No Utterance Flags are reviewed</div>
          )}
        </div>
        {/* Code for Promotion Section */}
        <div className={styles.PromotionContainer}>
          {promotedKPI?.length > 0 ? (
            promotedKPI?.map((promotedFlag, i) => (
              <div key={i} className={styles.promotedFlagDiv}>
                {promotedFlag}
                <button className={styles.hoverButton} onClick={() => removePromotion(promotedFlag)}>
                  &#10060;
                </button>
              </div>
            ))
          ) : (
            <>Threshold to Promote an UF to UAK is: {minimumDataPointsToRetrain}</>
          )}
          <br></br>
          {promotedKPI?.length > 0 ? (
            loadingButton === false ? (
              <Button variant="outlined" onClick={promotionPressed}>
                Promote
              </Button>
            ) : (
              <LoadingButton loading variant="outlined">
                Promote
              </LoadingButton>
            )
          ) : null}
        </div>
        <div className={styles.UserAddedKPIsContainer}>
          {UFConvertedIntoUAK?.length > 0 ? (
            UFConvertedIntoUAK?.map((flag, index) => (
              <div key={`uf_cvt_${index}`}>
                <strong>{flag.FlagName}</strong>
              </div>
            ))
          ) : (
            <div>Promoted UFs will appear here</div>
          )}
        </div>
      </div>
      {/* Code for records section */}
      <div className={styles.UFRecordsContainer}>
        <div>
          <Table
            columns={columns}
            dataSource={items.files}
            bordered
            title={() => (
              <div className={styles.tableHeader}>
                <div className={styles.switch}>
                  Unreviewed
                  <Switch size="small" style={{ marginLeft: 10, marginRight: 10 }} defaultChecked onChange={onReviewChange} />
                  Reviewed
                </div>
                <FormControl sx={{ m: 2, minWidth: 200 }}>
                  <InputLabel id="model-category-select">Utterance Flag</InputLabel>
                  <Select
                    labelId="model-category-select"
                    id="model-category"
                    label="Utterance flag"
                    value={UtteranceFlagFilterEnabled === "not applied" ? "" : UtteranceFlagFilterEnabled}
                    onChange={handleUtteranceFlagChange}
                  >
                    <MenuItem key="NA" value="not applied">
                      NA
                    </MenuItem>

                    {reviewed === 1
                      ? dropDownFacetValuesForReviewed?.map((options, i) => (
                          <MenuItem key={i} value={options.FlagName}>
                            {options.FlagName}
                          </MenuItem>
                        ))
                      : dropDownFacetValuesForUnReviewed?.map((options, i) => (
                          <MenuItem key={i} value={options.FlagName}>
                            {options.FlagName}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </div>
            )}
            pagination={{
              total: items.totalCount,
              simple: true,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
