import React, { useEffect } from "react";
import callNotesStyles from "./CallNotes.css";
import { Typography } from "@material-ui/core";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Badge from "@mui/material/Badge";
import Alert from "@material-ui/lab/Alert";
import HtmlTooltip from "../../assets/components/HtmlTooltip";
import Fade from "@material-ui/core/Fade";
import DescriptionIcon from "@mui/icons-material/Description";
import { get } from "lodash";

const CallNotes = (props) => {
  const styles = callNotesStyles();

  const initialErrorObject = {
    isError: false,
    msgError: "",
  };

  const initialNotesInfo = "No notes added during call.";

  const [callNotesInfo, setCallNotesInfo] = React.useState(initialNotesInfo);
  const [errorObject, setErrorObject] = React.useState(initialErrorObject);

  useEffect(() => {
    (async () => {
      try {
        // get call flag summary
        let callNotesRes = get(props.callMetadataInfo?.data, "Notes", "");
        if (callNotesRes) {
          setCallNotesInfo(callNotesRes);
        }
      } catch (err) {
        setErrorObject({
          isError: true,
          msgError: "ERR: Failed to initialize call notes component",
        });
      }
    })();
  }, [props.callMetadataInfo]);

  return (
    <React.Fragment>
      <div className={styles.callNoteRoot}>
        {props.dockState ? (
          <Accordion elevation={0} defaultExpanded={true}>
            <AccordionSummary
              className={styles.accordion_default_with_hover}
              expandIcon={<ExpandMoreIcon className={styles.svgExpandMoreIconDefault} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <DescriptionIcon color="action" style={{ marginRight: "4px" }} />
              <Typography className={styles.drawer_label}>Notes</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingBottom: "0px", paddingTop: "0px" }}>
              {errorObject.isError ? (
                <Alert className={styles.alert} severity="error">
                  {errorObject.msgError}
                </Alert>
              ) : undefined}
              <Typography className={styles.callNoteText} variant="body2" gutterBottom align="left" paragraph={true}>
                {callNotesInfo}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ) : (
          <div className={styles.callNotesDocked}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography className={styles.participantText}>Notes</Typography>
                </React.Fragment>
              }
              placement="right"
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 200 }}
            >
              <Button variant="text" sx={{ borderRadius: "10px", outline: "0 !important", height: "52px" }} onClick={props.handleDrawerOpen}>
                <Badge badgeContent={0} color="primary">
                  <DescriptionIcon color="action" />
                </Badge>
              </Button>
            </HtmlTooltip>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CallNotes;
