import { makeStyles } from "@material-ui/core/styles";

const callFlagReviewStyles = makeStyles((theme) => ({
  callFlagRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  valueContainer: {
    display: "inline-flex",
    flexWrap: "wrap",
    "& > :not(:last-child)": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& > *": {
      marginBottom: theme.spacing(1),
    },
    marginBottom: theme.spacing(1),
    textAlign: "left",
  },
  reviewButton: {
    width: "-webkit-fill-available",
    outline: "0 !important",
    borderRadius: "5px",
  },
  chipColor: {
    border: "3px solid rgba(25, 113, 194, 0.5) !important",
    transition: "border 0.5s ease-in !important",
  },
  reviewText: {
    textTransform: "none",
    fontSize: "16px",
  },
  menuItemDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  optionUL: {
    fontWeight: 300,
    fontSize: "16px",
    paddingLeft: "30px",
  },
  groupHeaderIcon: {
    paddingLeft: "8px",
  },
  hideRemoveOption: {
    width: "0px",
    height: "0px",
  },
  reviewDiv: {
    marginTop: "6px",
    marginRight: "8px",
  },
  alert: {
    marginBottom: theme.spacing(1),
  },
  flagButton: {
    width: "inherit",
  },
  actionButton: {
    backgroundColor: "#EBEBEB !important",
    color: "black !important",
    outline: "0 !important",
    width: "inherit",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#D9D9D9 !important",
    },
  },
  participantText: {
    textAlign: "left",
    fontSize: "12px",
    textTransform: "capitalize",
  },
  callFlagsDocked: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  drawer_label: {
    marginRight: "4px",
    fontFamily: "Inter",
    fontWeight: "600",
    color: "#495057",
  },
  svgExpandMoreIconDefault: {
    backgroundColor: "white",
    borderRadius: "50%",
  },
  accordion_default_with_hover: {
    margin: "12px 16px 8px 16px !important",
    padding: "0px !important",
    minHeight: "0px !important",
    "& .MuiAccordionSummary-content": {
      margin: "0px !important",
    },
    background: "#FFFFFF",
    "&:hover": {
      background: "#F2F3F5",
      borderRadius: "2px",
    },
  },
}));

export default callFlagReviewStyles;
