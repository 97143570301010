/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Button, Grid, IconButton, Modal } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import ReactTooltip from "react-tooltip";
import { AutoSizer } from "react-virtualized";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import editFileById from "../../services/editFileService";
import addContentCardFeedback from "../../services/contentCardFeedbackService";
import * as constants from "../../assets/constants";
import { List, CellMeasurer, CellMeasurerCache } from "react-virtualized";
import * as dataManipulations from "../../assets/dataManipulations";
import { debounce } from "lodash";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { removeUtteranceFlagsSelected, reviewUtteranceFlagsSelected, removeUtteranceFlagsAndKPIs } from "../../services/utteranceFlagService";
import { withStyles } from "@material-ui/core/styles";
import StyledContainer from "../../assets/components/StyledContainer";
import Highlighter from "react-highlight-words";
import styles from "./CallTranscript.module.css";
import classNames from "classnames";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import CardModal from "./card-modal/CardModal";
import AnnotationModal from "./annotation-modal/AnnotationModal";
import StyledFlagChip from "../../assets/components/StyledFlagChip/StyledFlagChip";
import StyledKPIChip from "../../assets/components/StyledKPIChip/StyledKPIChip";
import { LinearProgress } from "@mui/material";
import Divider from "@mui/material/Divider";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import Link from "@mui/material/Link";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PreviewContent from "../preview-content/PreviewContent";
import Fade from "@material-ui/core/Fade";
import Tooltip from "@mui/material/Tooltip";

const CallTranscript = (props) => {
  let cache = new CellMeasurerCache({
    defaultHeight: 50,
    fixedWidth: true,
  });

  // const ctxt = this;

  let participants = props.isVideoFile ? props.data.Participants : [];
  const queryStr = props.location;
  const snippetStartTime = constants.extractParameter(queryStr, constants.START_TIME_PARAM);
  let sentencesList = props.data.sentences;
  if (props.isVideoFile) {
    sentencesList = props.data.sentences.filter((element) => {
      if (element.MediaIndex === props.playlistIndex) return element;
    });
    if (sentencesList.length === 0) {
      sentencesList.push(constants.noTranscriptObjectByMediaIndex(props.playlistIndex));
    }
  }
  const [sentences, updateMyArray] = useState([]);
  const [tempSentences, updateTempSentences] = useState([]);
  const [openAnnotationModal, setOpenAnnotationModal] = useState(false);
  const [openCardModal, setOpenCardModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [list, setList] = useState([]);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [speedDialToggle, setSpeedDialToggle] = useState(false);
  const [msgId, setMessageId] = useState("");
  const [isSpeedDialFunctionCalled, setSpeedDialFunctionCalled] = useState({ flag: false, messageId: "" });
  const [highlightWord, setHighlightWord] = useState([]);
  const [wordCount, setWordCount] = useState(0);
  const [currentHightLight, setCurrentHighlight] = useState(0);
  const [wordHighLightData, setwordHighLightData] = useState([]);
  const [wordInUtteranceToHiglight, setwordInUtteranceToHiglight] = useState(-1);
  const [activeIndexOfHighlightWord, setactiveIndexOfHighlightWord] = useState(0);
  const [openPreviewContent, setOpenPreviewContent] = useState({
    open: false,
    cardContent: [],
  });
  const [script_adherence_threshold] = React.useState({
    low: 0.8,
    high: 0.95,
  });

  const actions = [
    { name: "Create card", tooltipText: "Add gryph card(s)" },
    {
      name: "Add annotation",
      tooltipText: "Add/Update key moment(s), utterance flag(s)",
    },
  ];

  useEffect(() => seekOnSentenceTable(snippetStartTime), []);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {}, [props.callMetadataInfo]);

  useEffect(() => {
    setMessageId(tempSentences[props.scrollIndex]?.msgID);
    isSpeedDialFunctionCalled.flag && tempSentences[props.scrollIndex]?.msgID === isSpeedDialFunctionCalled.messageId
      ? setSpeedDialToggle(true)
      : setSpeedDialToggle(false);
    setSpeedDialFunctionCalled({
      ...isSpeedDialFunctionCalled,
      flag: false,
    });
  }, [props.scrollIndex]);

  useEffect(() => {
    let sen = dataManipulations.getSentenceData(sentencesList, participants, props.isVideoFile, props.utteranceFlagsSelected);

    let filterTempSentence = sen;

    if (props.isVideoFile) {
      filterTempSentence = sen.filter((element) => {
        if (element.mediaIndex === props.playlistIndex) return element;
      });
    }
    setMessageId(filterTempSentence[props.scrollIndex]?.msgID);
    updateMyArray(filterTempSentence);
    updateTempSentences(filterTempSentence);
  }, [props.utteranceFlagsSelected]);

  useEffect(() => {
    setList(sentencesList);
    updateTempSentences(dataManipulations.getSentenceData(sentencesList, participants, props.isVideoFile, props.utteranceFlagsSelected));
  }, [props.playlistIndex, triggerUpdate]);

  const handleFlagDelete = async (item) => {
    if (item.FlagID !== 0) {
      // API Utterance
      try {
        if (!item.UserAddedKPI) {
          await removeUtteranceFlagsSelected({
            ConversationID: props.fileId,
            MessageID: item.MessageID,
            FlagIDArr: [item.FlagID],
          });
        } else {
          let removeSelectedFlags = {
            UtteranceFlagIDArr: [],
            UserAddedKPIFlagIDArr: [item],
          };
          let params = {
            ClientKey: props.clientKey,
            UserKey: props.userKey,
            ConversationID: props.fileId,
            MessageID: item.MessageID,
            Category: item.Category,
            RemoveFlags: removeSelectedFlags,
          };
          await removeUtteranceFlagsAndKPIs(params);
        }
      } catch (err) {
        props.handleOpenSnack(true, "ERR: Failed to delete selected flag");
      }
    }
    // Refresh utteranceSummary
    await props.refreshUtteranceFlags();
  };

  const handleFlagReview = async (FlagID, MessageID) => {
    if (FlagID !== 0) {
      // API Utterance
      try {
        await reviewUtteranceFlagsSelected({
          ClientKey: props.clientKey,
          UserKey: props.userKey,
          ConversationID: props.fileId,
          MessageID: MessageID,
          FlagIDArr: [FlagID],
        });
      } catch (err) {
        props.handleOpenSnack(true, "ERR: Failed to review selected flag");
      }
    }
    // Refresh utteranceSummary
    await props.refreshUtteranceFlags();
  };

  const handleModalOpen = (index, action) => {
    setIndex(index);
    switch (action) {
      case actions[0].name:
        setOpenCardModal(true);
        break;
      case actions[1].name:
        setOpenAnnotationModal(true);
        break;
      default:
        setOpenCardModal(false);
        setOpenCardModal(false);
        break;
    }
  };

  const handleModalClose = () => {
    setOpenAnnotationModal(false);
    setOpenCardModal(false);
    setSpeedDialToggle(!speedDialToggle);
  };

  const onPreviewCloseHandler = () => {
    setOpenPreviewContent({ open: false, contnet: [] });
  };

  const searchSentence = (e) => {
    e.preventDefault();
    let searchWord = e.target.value.toLowerCase();
    if (searchWord === "" || searchWord === " ") {
      setCurrentHighlight(0);
      setWordCount(0);
    } else {
      const countAndData = countAndDataForNextPrevIndex(searchWord, tempSentences);
      setwordHighLightData(countAndData);
      if (countAndData.length > 0) {
        setactiveIndexOfHighlightWord(countAndData[0].wordInSentence);
        setwordInUtteranceToHiglight(countAndData[0].sen);
        props.setScrollToIndex(countAndData[0].sen);
        setCurrentHighlight(1);
      } else {
        setCurrentHighlight(0);
      }
      setWordCount(countAndData.length);
    }

    setHighlightWord([searchWord]);
  };

  const nextSentenceHighlight = () => {
    if (currentHightLight < wordCount) {
      setactiveIndexOfHighlightWord(wordHighLightData[currentHightLight].wordInSentence);
      setwordInUtteranceToHiglight(wordHighLightData[currentHightLight].sen);
      props.setScrollToIndex(wordHighLightData[currentHightLight].sen);
      setCurrentHighlight(currentHightLight + 1);
    }
  };

  const prevSentenceHighlight = () => {
    if (currentHightLight >= 1) {
      setactiveIndexOfHighlightWord(wordHighLightData[currentHightLight - 2].wordInSentence);
      setCurrentHighlight(currentHightLight - 2);
      setwordInUtteranceToHiglight(wordHighLightData[currentHightLight - 2].sen);
      props.setScrollToIndex(wordHighLightData[currentHightLight - 2].sen);
      setCurrentHighlight(currentHightLight - 1);
    }
  };

  const searchCloseButton = () => {
    setCurrentHighlight(0);
    setWordCount(0);
    setHighlightWord([]);
    props.showSearchBoxFun(false);
  };

  const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
    leading: false,
    trailing: true,
  });

  const getAvatarIcon = (id, index) => {
    let item;
    if (props.isVideoFile) {
      item = props.data.Participants.find((rec) => rec.id === id);
    }
    if (tempSentences[index].msgID === "PCA_CUSTOM") {
      return (
        <Avatar sx={{ width: 40, height: 40 }} className={styles.gryphonCustomColor}>
          <Typography style={{ textAlign: "left", fontWeight: "600" }} variant="body2">
            {constants.getAcroyn(constants.GRYPHON)}
          </Typography>
        </Avatar>
      );
    } else if (item || !props.isVideoFile) {
      return (
        <Avatar
          sx={{ width: 40, height: 40 }}
          className={
            index === props.scrollIndex &&
            ((props.isVideoFile && list[index].ParticipantRole !== "HOST") || (!props.isVideoFile && list[index].ParticipantRole !== "HUMAN_AGENT"))
              ? `${styles.notHostIndex} ${styles.endUserColor}`
              : index === props.scrollIndex &&
                ((props.isVideoFile && list[index].ParticipantRole === "HOST") ||
                  (!props.isVideoFile && list[index].ParticipantRole === "HUMAN_AGENT"))
              ? `${styles.hostIndex} ${styles.hostColor}`
              : (props.isVideoFile && list[index].ParticipantRole !== "HOST") || (!props.isVideoFile && list[index].ParticipantRole !== "HUMAN_AGENT")
              ? `${styles.endUser} ${styles.endUserColor}`
              : `${styles.host} ${styles.hostColor}`
          }
        >
          <Typography style={{ textAlign: "left", fontWeight: "600" }} variant="body2">
            {!props.isVideoFile
              ? constants.getAcroyn(
                  constants.getAgentUpdatedSpeaker(
                    tempSentences[index].speaker,
                    props.callMetadataInfo?.data?.FirstName,
                    props.callMetadataInfo?.data?.LastName
                  )
                )
              : constants.getAcroyn(tempSentences[index].participant)}
          </Typography>
        </Avatar>
      );
    } else
      return (
        <Avatar
          src="/broken-image.jpg"
          sx={{ width: 40, height: 40, background: "#FFF", color: "#087f5b" }}
          className={index === props.scrollIndex ? styles.notHostIndex : styles.endUser}
        ></Avatar>
      );
  };

  const seekOnSentenceTable = (currentTime) => {
    props.seekOnSentenceTable(currentTime);
  };

  const handleKPIDelete = async (chipToDelete, index, isMed, messageId) => {
    const { setSentences } = props;
    let participants = props.isVideoFile ? props.data.Participants : [];
    const dataChip = sentencesList;
    let filterKPI = [];
    const chipFilterByMessageId = dataChip.findIndex((e) => {
      return e.MessageID === messageId;
    });
    if (!isMed) {
      if (chipFilterByMessageId !== -1) filterKPI = dataChip[chipFilterByMessageId].KPI.filter((chip) => chip.value !== chipToDelete);
    } else {
      if (chipFilterByMessageId !== -1) filterKPI = dataChip[chipFilterByMessageId].MEDDIC_KPI.filter((chip) => chip.value !== chipToDelete);
    }

    if (filterKPI.length === 0) {
      if (!isMed) {
        filterKPI = [{ value: "None", KpiID: "kp0" }];
      } else {
        filterKPI = [{ value: "None", KpiID: "mkp0" }];
      }
    }

    let newData = dataChip.map((el) => {
      if (el.MessageID === messageId) {
        if (!isMed) return Object.assign({}, el, { KPI: filterKPI });
        else return Object.assign({}, el, { MEDDIC_KPI: filterKPI });
      }
      return el;
    });
    let sen = dataManipulations.getSentenceData(newData, participants, props.isVideoFile, props.utteranceFlagsSelected);
    if (!isMed) {
      sen[chipFilterByMessageId].kpi = sen[chipFilterByMessageId].kpi.filter((chip) => chip !== chipToDelete);
    } else {
      sen[chipFilterByMessageId].mkpi = sen[chipFilterByMessageId].mkpi.filter((chip) => chip !== chipToDelete);
    }
    updateMyArray(sen);
    updateTempSentences(sen);
    setSentences(newData);
    setTriggerUpdate(!triggerUpdate);
    sentencesList = [...newData];
    props.data.sentences = [...newData];
    try {
      let fileId = props.data.ConversationID;
      await editFileById(fileId, newData, props.isMeddic, props.userKey);
      props.handleOpenSnack(true, "File saved with new changes");
    } catch (err) {
      props.handleOpenSnack(true, "ERR: Failed to delete KPI");
    }
  };

  const updateLikeDislike = async (cardID, messageID, userAction, cardIndex) => {
    let requestData = {
      UserKey: props.userKey,
      CardID: cardID,
      ConversationID: props.fileId,
      MessageID: messageID,
      UserAction: userAction,
    };

    const { setSentences } = props;
    const tempSentenceList = sentencesList;
    let participants = props.isVideoFile ? props.data.Participants : [];

    let newData = tempSentenceList.map((el) => {
      if (el.MessageID === messageID) {
        let gryphCard = el.GryphCards;
        gryphCard[cardIndex].Feedback = requestData;
        return Object.assign({}, el, { GryphCards: gryphCard });
      }
      return el;
    });

    let sen = dataManipulations.getSentenceData(newData, participants, props.isVideoFile, props.utteranceFlagsSelected);
    updateMyArray(sen);
    updateTempSentences(sen);
    setSentences(newData);
    sentencesList = [...newData];
    props.data.sentences = [...newData];
    await addContentCardFeedback(requestData);
  };

  const findChunksHighlightKeywords = ({ searchWords, textToHighlight }) => {
    let chunks = [];
    if (searchWords?.length !== 0) {
      let word = "";
      let l = 0;
      for (let t = 0; t < searchWords?.length; t++) {
        // ret = '';
        for (let i = 0; i < textToHighlight.length; i++) {
          l = searchWords[t].length;
          word = textToHighlight.substr(i, l);
          let codebefore = 0;
          let codeafter = 0;
          if (i > 0) {
            codebefore = textToHighlight[i - 1].charCodeAt(0);
          }
          if (i + l < textToHighlight.length) {
            codeafter = textToHighlight[i + l].charCodeAt(0);
          }
          let letterbefore = false;
          let letterafter = false;
          // check to see if there is a letter before or after the keyword. That would indicate the keyword was not actually matched.
          if ((codebefore >= 65 && codebefore <= 90) || (codebefore >= 97 && codebefore <= 122)) {
            letterbefore = true;
          }
          if ((codeafter >= 65 && codeafter <= 90) || (codeafter >= 97 && codeafter <= 122)) {
            letterafter = true;
          }
          if (word !== "" && !letterbefore && !letterafter && word.toLowerCase() === searchWords[t].toLowerCase()) {
            chunks.push({ start: i, end: i + l });
            i = i + l - 1; // adding the whole word, so advance to the letter after
          }
        }
      }
    }
    return chunks;
  };

  const countAndDataForNextPrevIndex = (searchWords, textToHighlight) => {
    let chunks = [];
    if (searchWords?.length !== 0) {
      let word = "";
      let l = 0;
      for (let sen = 0; sen < textToHighlight.length; sen++) {
        let wordInSentence = 0;
        let chunk = [];
        let includesSearchWord = false;
        let keywords = textToHighlight[sen].keywords;
        if (keywords.length > 0) {
          includesSearchWord = keywords.some((element) => {
            return element.toLowerCase() === searchWords.toLowerCase();
          });
        }

        if (!includesSearchWord) {
          keywords = keywords.concat([searchWords]);
        }

        for (let t = 0; t < keywords.length; t++) {
          for (let i = 0; i < textToHighlight[sen].sentence.length; i++) {
            l = keywords[t].length;
            word = textToHighlight[sen].sentence.substr(i, l);
            let codebefore = 0;
            let codeafter = 0;
            if (i > 0) {
              let tempCodeBefore = textToHighlight[sen].sentence;
              codebefore = tempCodeBefore[i - 1].charCodeAt(0);
            }
            if (i + l < textToHighlight[sen].sentence.length) {
              let tempCodeAfter = textToHighlight[sen].sentence;
              codeafter = tempCodeAfter[i + l].charCodeAt(0);
            }
            let letterbefore = false;
            let letterafter = false;
            // check to see if there is a letter before or after the keyword. That would indicate the keyword was not actually matched.
            if ((codebefore >= 65 && codebefore <= 90) || (codebefore >= 97 && codebefore <= 122)) {
              letterbefore = true;
            }
            if ((codeafter >= 65 && codeafter <= 90) || (codeafter >= 97 && codeafter <= 122)) {
              letterafter = true;
            }
            if (word !== "" && !letterbefore && !letterafter) {
              if (word.toLowerCase() === keywords[t].toLowerCase()) {
                chunk.push({ sen, start: i, end: i + l, wordInSentence, word });
                i = i + l - 1; // adding the whole word, so advance to the letter after
                wordInSentence++;
              }
            }
          }
        }
        chunks.push(chunk);
      }
    }

    let returnHighlightedWord = [];

    // In the chunks array we are getting all the keywords data for all utterance, in the code below we are segregating utterance in which search words present.
    for (let i = 0; i < chunks.length; i++) {
      if (chunks[i].length > 0) {
        let chunk = chunks[i].sort((a, b) => (a.start > b.start ? 1 : -1));
        for (let j = 0; j < chunk.length; j++) {
          chunk[j].wordInSentence = j;
          if (chunk[j].word.toLowerCase() === searchWords.toLowerCase()) returnHighlightedWord.push(chunk[j]);
        }
      }
    }
    return returnHighlightedWord;
  };

  const changeSpeedDialToggle = (currentIndexMsgId) => {
    props.pauseMedia();
    setMessageId(currentIndexMsgId);
    setSpeedDialFunctionCalled({ flag: true, messageId: currentIndexMsgId });
    setSpeedDialToggle(!speedDialToggle);
  };

  // Unique key within array of rows
  const rowRenderer = ({ key, index, parent, style }) => {
    let rowElementArr = [];
    let gryphCardElementArr = [];
    let gryphCard = tempSentences[index]?.gryphCards ? tempSentences[index]?.gryphCards : [];
    let sentenceElement = (
      <Grid key={`row_renderer_sentence_${index}`} item xs={10} lg={10.75} id="scroll-container" sx={{ width: "100%" }}>
        <ListItemText
          primary={
            <Paper
              sx={{ margin: "0px", padding: "8px" }}
              className={[
                styles.sentenceBox,
                tempSentences[index].msgID === "PCA_CUSTOM"
                  ? styles.notGryphonCustomIndex
                  : index === props.scrollIndex &&
                    ((props.isVideoFile && list[index].ParticipantRole !== "HOST") ||
                      (!props.isVideoFile && list[index].ParticipantRole !== "HUMAN_AGENT"))
                  ? styles.notHostIndex
                  : index === props.scrollIndex &&
                    ((props.isVideoFile && list[index].ParticipantRole === "HOST") ||
                      (!props.isVideoFile && list[index].ParticipantRole === "HUMAN_AGENT"))
                  ? styles.hostIndex
                  : (props.isVideoFile && list[index].ParticipantRole !== "HOST") ||
                    (!props.isVideoFile && list[index].ParticipantRole !== "HUMAN_AGENT")
                  ? styles.endUser
                  : styles.host,
              ].join(" ")}
              variant="outlined"
              key={list[index].MessageID}
            >
              <StyledContainer style={{ cursor: "pointer" }} onClick={() => props.newClick(list[index], index)}>
                <Typography style={{ textAlign: "left", wordWrap: "break-word" }} variant="body2">
                  <Highlighter
                    highlightClassName={styles.transcriptHighlight}
                    searchWords={tempSentences[index]?.keywords ? tempSentences[index]?.keywords.concat(highlightWord) : highlightWord}
                    autoEscape={true}
                    textToHighlight={tempSentences[index].sentence}
                    activeIndex={activeIndexOfHighlightWord}
                    activeClassName={
                      wordInUtteranceToHiglight === index && props.showSearchBox ? styles.activeClassName : styles.searchTranscriptHighlight
                    }
                    findChunks={findChunksHighlightKeywords}
                  />
                </Typography>
              </StyledContainer>
              <div className={"kpi-container"}>
                {tempSentences[index].msgID !== "PCA_CUSTOM" ? (
                  <>
                    <div className={styles.annotationChipsBox}>
                      <StyledContainer>
                        {Array.isArray(list[index].KPI)
                          ? list[index].KPI.map((kpi) => {
                              return (
                                <React.Fragment key={`kpicontainer_${kpi.value}`}>
                                  {kpi.value !== "None" && kpi.value ? (
                                    <StyledKPIChip
                                      isAdmin={props.isAdmin}
                                      isMeddiccChip={false}
                                      label={kpi.value}
                                      polarity={constants.getPolarityById(kpi.KpiID, props.kpiInfos)}
                                      onDelete={() => handleKPIDelete(kpi.value, index, false, tempSentences[index].msgID)}
                                    />
                                  ) : null}
                                </React.Fragment>
                              );
                            })
                          : null}

                        {list[index].MEDDIC_KPI && props.isMeddic
                          ? list[index].MEDDIC_KPI.map((kpi) => {
                              return (
                                <React.Fragment key="1">
                                  {kpi.value !== "None" ? (
                                    <StyledKPIChip
                                      isAdmin={props.isAdmin}
                                      isMeddiccChip={true}
                                      label={kpi.value}
                                      polarity={constants.getPolarityById(kpi.KpiID, props.kpiInfos)}
                                      onDelete={() => handleKPIDelete(kpi.value, index, true, tempSentences[index].msgID)}
                                    />
                                  ) : null}
                                </React.Fragment>
                              );
                            })
                          : null}

                        {tempSentences[index].utteranceFlags?.length > 0
                          ? tempSentences[index].utteranceFlags?.map((item) => {
                              return (
                                <React.Fragment key={`utt_frag_+${item.FlagID}+_+${item.MessageID}`}>
                                  {item.UserAddedKPI ? (
                                    <StyledKPIChip
                                      key={`utt_+${item.FlagID}+_+${item.MessageID}`}
                                      isAdmin={props.isAdmin}
                                      isMeddiccChip={false}
                                      label={item.Description}
                                      polarity={"neutral"}
                                      onDelete={() => handleFlagDelete(item)}
                                    />
                                  ) : (
                                    <StyledFlagChip
                                      key={`utt_+${item.FlagID}+_+${item.MessageID}`}
                                      chipText={item.Description}
                                      isAdmin={props.isAdmin}
                                      isReviewed={item.Reviewed}
                                      isUtteranceFlag={true}
                                      isFastForward={false}
                                      handleFlagDelete={() => handleFlagDelete(item)}
                                      handleFlagReview={() => handleFlagReview(item.FlagID, item.MessageID)}
                                    ></StyledFlagChip>
                                  )}
                                </React.Fragment>
                              );
                            })
                          : null}
                      </StyledContainer>
                    </div>
                    <div className={styles.speedDialActionButtonBox}>
                      <SpeedDial
                        onClick={() => changeSpeedDialToggle(tempSentences[index].msgID)}
                        open={speedDialToggle}
                        className={classNames(styles.annotationSpeedDial)}
                        FabProps={{ size: "small" }}
                        ariaLabel="annotation-speedDial"
                        icon={
                          <IconButton sx={{ padding: "6px" }}>
                            <AddCircleOutlineIcon color="primary" />
                          </IconButton>
                        }
                        direction={"left"}
                      >
                        {tempSentences[index].msgID === msgId ? (
                          <SpeedDialAction
                            className={styles.annotationSpeedDialAction}
                            FabProps={{ variant: "extended", size: "medium" }}
                            key="key_create_card"
                            icon={
                              <Typography className={styles.annotationSpeedDialText} component="div" variant="caption" align="center" color="inherit">
                                Create card
                              </Typography>
                            }
                            tooltipTitle="Add gryph card(s)"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleModalOpen(index, "Create card");
                            }}
                          />
                        ) : null}

                        {tempSentences[index].msgID === msgId && (props.isAdmin || props.utteranceFlags.length !== 0) ? (
                          <SpeedDialAction
                            className={styles.annotationSpeedDialAction}
                            FabProps={{ variant: "extended", size: "medium" }}
                            key="key_add_annotation"
                            icon={
                              <Typography className={styles.annotationSpeedDialText} component="div" variant="caption" align="center" color="inherit">
                                Add annotation
                              </Typography>
                            }
                            tooltipTitle="Add/Update key moment(s), utterance flag(s)"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleModalOpen(index, "Add annotation");
                            }}
                          />
                        ) : null}
                      </SpeedDial>
                    </div>
                  </>
                ) : undefined}
              </div>
            </Paper>
          }
        />
      </Grid>
    );
    let avatarElement = (
      <Grid key={`row_renderer_avatar_${index}`} item xs={2} lg={1.25} className={"avatar-container"}>
        <ListItemText
          data-background-color={
            tempSentences[index].msgID === "PCA_CUSTOM"
              ? "rgba(73, 80, 87, 0.5)"
              : list[index].ParticipantRole === "HOST" || list[index].ParticipantRole === "HUMAN_AGENT"
              ? "rgba(25, 113, 194)"
              : "rgba(8, 127, 91)"
          }
          className={list[index].ParticipantRole === "HOST" || list[index].ParticipantRole === "HUMAN_AGENT" ? "float-right" : "float-left"}
          data-tip={
            `<React.Fragment><Typography variant="subtitle2" color="inherit">
            ${
              tempSentences[index].msgID === "PCA_CUSTOM"
                ? constants.GRYPHON
                : props.isVideoFile
                ? tempSentences[index].participant
                : constants.getAgentUpdatedSpeaker(
                    tempSentences[index].speaker,
                    props.callMetadataInfo?.data?.FirstName,
                    props.callMetadataInfo?.data?.LastName
                  )
            }` +
            " </Typography></br>" +
            `<Typography variant="subtitle2" align="center" color="inherit"> ${
              tempSentences[index].msgID === "PCA_CUSTOM" ? "" : constants.toHHMMSS(parseInt(tempSentences[index].start), props.isLongCall)
            }` +
            "</Typography></React.Fragment>"
          }
        >
          {props.isVideoFile
            ? getAvatarIcon(list[index].ParticipantID, index, list[index].StartTime.second)
            : getAvatarIcon(list[index].ParticipantRole, index, list[index].StartTime.second)}
        </ListItemText>
        {list[index].ParticipantRole === "HOST" || list[index].ParticipantRole === "HUMAN_AGENT" ? (
          list[index].ScriptAdherence && list[index].ScriptAdherence?.Script ? (
            <div className={styles.sc_ah_box_details}>
              <StyledTooltip
                title={
                  <React.Fragment>
                    <div className={styles.sc_ah_tooltip_title} name="sc_ah_tooltip_title">
                      <span>{list[index].ScriptAdherence?.Script ? list[index].ScriptAdherence?.Script : "Script unavailable"}</span>
                    </div>
                  </React.Fragment>
                }
                placement="bottom"
                arrow
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 400 }}
              >
                {list[index].ScriptAdherence?.AdherenceScore
                  ? constants.getScriptAdherenceLabelThresholdIcon(script_adherence_threshold, list[index].ScriptAdherence?.AdherenceScore)
                  : constants.getScriptAdherenceLabelThresholdIcon(script_adherence_threshold, 0)}
              </StyledTooltip>
            </div>
          ) : undefined
        ) : undefined}
      </Grid>
    );

    let gryphCardDetailsUtterance = (
      <Grid key={`row_renderer_sentence_${index}_gryphUtterance`} item xs={10} lg={10.75} sx={{ width: "100%" }}>
        <ListItemText
          primary={
            <Paper
              sx={{ margin: "0px", padding: "8px" }}
              className={[
                styles.sentenceBox,
                styles.notGryphonCustomIndex,
                list[index].ParticipantRole === "HOST" || list[index].ParticipantRole === "HUMAN_AGENT" ? styles.hostAlign : styles.customerAlign,
              ].join(" ")}
              variant="outlined"
              key={`${list[index].MessageID}gryphon_details_utterance`}
            >
              <StyledContainer>
                <Typography sx={{ textAlign: "left" }} variant="body2">
                  {gryphCard?.length ? (
                    <>
                      {gryphCard?.length === 1 ? (
                        <Typography style={{ marginBottom: "5px" }}> {" Gryph detected a competitor mention."} </Typography>
                      ) : (
                        <Typography style={{ marginBottom: "5px" }}> {" Gryph detected a keywords that correspond to multiple cards."} </Typography>
                      )}
                      {gryphCard?.map((card, cardIndex) => {
                        return (
                          <Stack key={`stack-${cardIndex}`} direction="row" justifyContent="space-between" alignItems="flex-start">
                            <Link
                              href="#"
                              underline="always"
                              onClick={() => {
                                setOpenPreviewContent({
                                  open: true,
                                  cardContent: card,
                                });
                              }}
                            >
                              {JSON.parse(card.Content)?.cardtitle}
                            </Link>
                            <div className={styles.gryphCardLikeDislikeDiv}>
                              <Button
                                className={styles.gryphLikeButton}
                                disabled={card.Feedback?.UserAction === 4 ? true : false}
                                onClick={() => updateLikeDislike(card.CardID, tempSentences[index].msgID, 4, cardIndex)}
                              >
                                <ThumbUpIcon style={card.Feedback?.UserAction === 4 ? { color: "#0070d2", height: "20px" } : { height: "20px" }} />
                              </Button>
                              <Button
                                className={styles.gryphDislikeButton}
                                disabled={card.Feedback?.UserAction === 3 ? true : false}
                                onClick={() => updateLikeDislike(card.CardID, tempSentences[index].msgID, 3, cardIndex)}
                              >
                                <ThumbDownIcon style={card.Feedback?.UserAction === 3 ? { color: "#0070d2", height: "20px" } : { height: "20px" }} />
                              </Button>
                            </div>
                          </Stack>
                        );
                      })}
                    </>
                  ) : null}
                </Typography>
              </StyledContainer>
            </Paper>
          }
        />
      </Grid>
    );

    let gryphCardDetailsAvatar = (
      <Grid key={`row_renderer_avatar_${index}_gryph`} item xs={2} lg={1.25} className={"avatar-container"}>
        <ListItemText data-background-color={"rgba(25, 113, 194)"} className={"float-right"}>
          <Avatar sx={{ width: 40, height: 40 }} className={styles.gryphonCustomColor}>
            <Typography style={{ textAlign: "left", fontWeight: "600" }} variant="body2">
              {constants.getAcroyn(constants.GRYPHON)}
            </Typography>
          </Avatar>
        </ListItemText>
      </Grid>
    );

    if (list[index].ParticipantRole === "HOST" || list[index].ParticipantRole === "HUMAN_AGENT") {
      rowElementArr.push(sentenceElement);
      rowElementArr.push(avatarElement);
      gryphCardElementArr.push(gryphCardDetailsUtterance);
      gryphCardElementArr.push(gryphCardDetailsAvatar);
    } else {
      rowElementArr.push(avatarElement);
      rowElementArr.push(sentenceElement);
      gryphCardElementArr.push(gryphCardDetailsAvatar);
      gryphCardElementArr.push(gryphCardDetailsUtterance);
    }

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        <StyledListItem alignItems="flex-start" style={style}>
          <Stack sx={{ width: "inherit" }}>
            <Stack direction="row" spacing={{ xs: 2.75, lg: 1.5 }} sx={{ width: "100% !important" }}>
              {rowElementArr}
            </Stack>
            {gryphCard.length > 0 ? (
              <Stack direction="row" spacing={{ xs: 2.75, lg: 1.5 }} sx={{ width: "100% !important" }}>
                {gryphCardElementArr}
              </Stack>
            ) : null}
          </Stack>
        </StyledListItem>
      </CellMeasurer>
    );
  };

  return (
    <React.Fragment>
      {props.showSearchBox ? (
        <div className="searchBox">
          <input autoFocus="autoFocus" className="searchTextBox" key="gsf" type="text" placeholder="Search" onChange={searchSentence} />
          <Typography className="searchCount">
            {currentHightLight < 10 ? "0" + currentHightLight : currentHightLight}/{wordCount < 10 ? "0" + wordCount : wordCount}
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem className="searchSeparator" />
          <IconButton
            disabled={currentHightLight <= 1 ? true : false}
            className="searchUpButton disableButtonDefaultDesign"
            onClick={prevSentenceHighlight}
          >
            <KeyboardArrowUpIcon />
          </IconButton>
          <IconButton
            disabled={currentHightLight >= wordCount ? true : false}
            className="searchDownButton disableButtonDefaultDesign"
            onClick={nextSentenceHighlight}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
          <IconButton className="searchCloseButton disableButtonDefaultDesign" onClick={searchCloseButton}>
            <CloseIcon />
          </IconButton>
        </div>
      ) : null}

      {list.length > 0 ? (
        <React.Fragment>
          <div
            style={{
              flex: "1 1 auto",
              height: "calc(100vh - 130px)",
              paddingBottom: "30px",
            }}
          >
            <ReactTooltip html={true} place="right" effect="solid" multiline={true} />
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowCount={list.length}
                  rowRenderer={rowRenderer}
                  deferredMeasurementCache={cache}
                  rowHeight={cache.rowHeight}
                  scrollToIndex={props.scrollIndex}
                  scrollToAlignment="center"
                  onScroll={rebuildTooltip}
                  style={{
                    position: "relative",
                    overflowY: "auto",
                  }}
                />
              )}
            </AutoSizer>
          </div>
        </React.Fragment>
      ) : (
        <div
          style={{
            flex: "1 1 auto",
            height: "calc(100vh - 135px)",
            paddingBottom: "30px",
          }}
        >
          <LinearProgress />
        </div>
      )}

      <AnnotationModal
        {...props}
        index={index}
        list={list}
        sentences={sentences}
        tempSentences={tempSentences}
        openAnnotationModal={openAnnotationModal}
        handleModalClose={handleModalClose}
        updateMyArray={updateMyArray}
        updateTempSentences={updateTempSentences}
        triggerUpdate={triggerUpdate}
        setTriggerUpdate={setTriggerUpdate}
      />

      <CardModal
        {...props}
        index={index}
        tempSentences={tempSentences}
        openCardModal={openCardModal}
        handleModalClose={handleModalClose}
        disableRestoreFocus={false}
      />

      <Modal open={openPreviewContent.open} onClose={() => setOpenPreviewContent({ open: false, cardContent: [] })}>
        <PreviewContent onPreviewCloseHandler={onPreviewCloseHandler} cardContent={openPreviewContent.cardContent} />
      </Modal>
    </React.Fragment>
  );
};

export default CallTranscript;

const StyledListItem = withStyles({
  root: {
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
  },
})(ListItem);

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: "#495057 !important",
    boxShadow: "0px 6px 18px 0px #0000000F",
    borderRadius: "10px !important",
    padding: "8px !important",
    marginBottom: "12px !important",
    cursor: "pointer",
  },
  arrow: {
    color: "#495057 !important",
  },
})(Tooltip);
