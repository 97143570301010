import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import styles from "./StyledFlagChip.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FastForwardRoundedIcon from "@mui/icons-material/FastForwardRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

/* 
    Generic Component
    Expected Props: 
        isAdmin; isUtteranceFlag; isReviewed; isFastForward

*/

const StyledFlagChip = (props) => {
  const [display, setDisplay] = useState(false);
  const showButton = (e) => {
    e.preventDefault();
    setDisplay(true);
  };

  const hideButton = (e) => {
    e.preventDefault();
    setDisplay(false);
  };

  return (
    <Chip
      className={props.isUtteranceFlag ? styles.utteranceChipMain : styles.callChipMain}
      label={
        <div className={!props.isReviewed || props.isAdmin || props.isFastForward ? (display ? styles.chipHover : undefined) : styles.disabledButton}>
          {props.isReviewed ? <CheckCircleIcon fontSize="small" className={styles.reviewedIconChip} /> : undefined}
          <span className={styles.chipLabel}>{props.chipText}</span>
        </div>
      }
      onMouseEnter={(e) => showButton(e)}
      onMouseLeave={(e) => hideButton(e)}
      icon={
        <div className={display ? styles.displayed : styles.notdisplayed}>
          {!props.isFastForward ? (
            !props.isReviewed && props.isAdmin ? (
              <IconButton onClick={props.handleFlagReview} className={styles.buttonContainer} fontSize="small">
                <CheckRoundedIcon color="primary" fontSize="small" sx={{ color: "white" }} />
              </IconButton>
            ) : undefined
          ) : undefined}
          {!props.isFastForward ? (
            props.isAdmin || !props.isReviewed ? (
              <IconButton onClick={props.handleFlagDelete} className={styles.buttonContainer} fontSize="small">
                <CloseRoundedIcon color="primary" fontSize="small" sx={{ color: "white" }} />
              </IconButton>
            ) : undefined
          ) : undefined}
          {props.isUtteranceFlag && props.isFastForward ? (
            <IconButton onClick={props.handleFastForward} className={styles.buttonContainer} fontSize="small">
              <FastForwardRoundedIcon fontSize="small" sx={{ color: "white" }} />
            </IconButton>
          ) : undefined}
        </div>
      }
    ></Chip>
  );
};

export default StyledFlagChip;
