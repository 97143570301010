import { makeStyles } from "@material-ui/core/styles";

const playlistPanelStyles = makeStyles(() => ({
  playlistPanelRoot: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
  },
  mediaPlaylist: {
    display: "grid",
    justifyItems: "start",
  },
  alertBox: {
    marginLeft: "16px",
    width: "93%",
  },
}));

export default playlistPanelStyles;
