import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import callSnippetStyles from "./CallSnippet.css";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { ValidationTextField, ValidationTextFieldFullWidth } from "../../assets/components/ValidationTextField";
import getSnippet, { saveSnippet, checkSnippetExists } from "../../services/callSnippetService";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import HtmlTooltip from "../../assets/components/HtmlTooltip";
import * as constants from "../../assets/constants";
import NumberFormat from "react-number-format";
import { Paper } from "@mui/material";
import { DateTime } from "luxon";

const CallSnippet = (props) => {
  const styles = callSnippetStyles();
  const initialErrorObject = {
    isError: false,
    msgError: "",
  };

  const [stateDetails] = React.useState(props.dataForSnip);
  const [helpText, setHelpText] = React.useState("");
  const [inputRegex, setInputRegex] = React.useState("");
  const [inputSnippetNameRegex] = React.useState(constants.REGEX_SNIPPET_NAME);
  const [snippetName, setSnippetName] = React.useState("");
  const [inputFormat, setInputFormat] = React.useState("");
  const [errorObject, setErrorObject] = React.useState(initialErrorObject);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [rawDataInfo, setRawDataInfo] = React.useState({});
  const [snippetURL, setSnippetURL] = React.useState("");
  const [timerObj, setTimerObj] = React.useState(0);

  useEffect(() => {
    setDefaults();
  }, []);

  useEffect(() => {
    clearTimeout(timerObj);
    setTimerObj(
      setTimeout(() => {
        setErrorObject({
          isError: false,
          msgError: "",
        });
      }, 600)
    );
  }, [props.dataForSnip.dragStartTime, props.dataForSnip.dragEndTime]);

  const setDefaults = () => {
    if (stateDetails.isLongCall) {
      setHelpText("hh:mm:ss");
      setInputRegex(constants.REGEX_HHMMSS);
      setInputFormat(constants.FORMAT_HHMMSS);
      setRawDataInfo(stateDetails.rawData);
    } else {
      setHelpText("mm:ss");
      setInputRegex(constants.REGEX_MMSS);
      setInputFormat(constants.FORMAT_MMSS);
      setRawDataInfo(stateDetails.rawData);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const formatAndSetSnippetURL = (url) => {
    let resURL = url + window.location.search;
    setSnippetURL(resURL);
  };

  const handleShareSnippetClick = async () => {
    try {
      let selectedStartTimeProp = toHHMMSS(props.dataForSnip.dragStartTime);
      let selectedEndTimeProp = toHHMMSS(props.dataForSnip.dragEndTime);
      let snipTime = props.dataForSnip.dragEndTime - props.dataForSnip.dragStartTime;
      let requestObject = {
        fileType: props.isVideoFile ? (props.isAudioPlayingInPlaylist ? "m4a" : props.fileType) : props.fileType,
        startSel: props.dataForSnip.dragStartTime,
        endSel: props.dataForSnip.dragEndTime,
        snipDuration: snipTime,
        inputBucketURL: !props.isVideoFile
          ? stateDetails.data.BucketURL
          : constants.signedURLToFilePath(stateDetails.data.MediaURL[props.playlistIndex]?.BucketURL),
        fileID: stateDetails.data.ConversationID,
        sentences: [],
        isVideoFile: props.isVideoFile,
        snipMediaIndex: props.playlistIndex,
      };
      // deepcode ignore reDOS
      const reg = new RegExp(inputRegex);
      // deepcode ignore reDOS
      const snipReg = new RegExp(inputSnippetNameRegex);
      // Validate inputs
      if (!reg.test(selectedStartTimeProp) || !reg.test(selectedEndTimeProp)) {
        setErrorObject({
          isError: true,
          msgError: "ERR: Invalid START or END",
        });
        return;
      } else if (selectedStartTimeProp === "" || selectedEndTimeProp === "") {
        setErrorObject({
          isError: true,
          msgError: "ERR: START or END cannot be empty",
        });
        return;
      } else if (selectedStartTimeProp === "00:00" && selectedEndTimeProp === toHHMMSS(stateDetails.data.Duration)) {
        setErrorObject({
          isError: true,
          msgError: "ERR: Cannot snip entire call",
        });
        return;
      } else if (toSeconds(selectedStartTimeProp) > toSeconds(selectedEndTimeProp)) {
        setErrorObject({
          isError: true,
          msgError: "ERR: START should be less than END time",
        });
        return;
      } else if (toSeconds(selectedStartTimeProp) < toSeconds("00:00") || toSeconds(selectedEndTimeProp) > stateDetails.data.Duration) {
        setErrorObject({
          isError: true,
          msgError: "ERR: START and END should be within call duration",
        });
        return;
      } else if (snipTime < 5) {
        setErrorObject({
          isError: true,
          msgError: "ERR: Minimum call snip duration should be greater than 5s",
        });
        return;
      } else if (!snipReg.test(snippetName)) {
        setErrorObject({
          isError: true,
          msgError: "ERR: Name cannot contain following characters: \", ' or `",
        });
        return;
      }
      // Call to API
      setOpen(!open);
      let snippetInfo = await checkSnippetExists(requestObject);
      if (!snippetInfo.data.snippetExists) {
        let snippedSentences = await getSnippedSentences(
          rawDataInfo.fileContent.sentences,
          selectedStartTimeProp,
          selectedEndTimeProp,
          props.isVideoFile
        );
        let res = await getSnippet(requestObject);
        if (!res.data.code) {
          let url = await updateAndSaveResponseObject({
            snipResult: res,
            snipSentences: snippedSentences,
            snipDuration: requestObject.snipDuration,
            snipStart: props.dataForSnip.dragStartTime,
            snipEnd: props.dataForSnip.dragEndTime,
            snipName: snippetName,
            isVideoFile: props.isVideoFile,
            snipMediaIndex: props.playlistIndex,
          });
          formatAndSetSnippetURL(url);
          setOpen(false);
          setOpenModal(true);
        } else {
          setErrorObject({
            isError: true,
            msgError: "ERR: Failed to create snippet",
          });
          setOpen(false);
        }
      } else {
        formatAndSetSnippetURL(snippetInfo.data.url);
        setOpen(false);
        setOpenModal(true);
      }
    } catch (e) {
      setErrorObject({
        isError: true,
        msgError: "ERR: Failed to create snippet",
      });
      setOpen(false);
    }
  };

  const getSnippedSentences = async (sentenceArr, selectedStartTime, selectedEndTime, isVideoFile) => {
    let snipSentences = [];
    if (!isVideoFile) {
      sentenceArr.forEach((e) => {
        let element = JSON.parse(JSON.stringify(e));
        if (
          (element.StartTime.second >= toSeconds(selectedStartTime) && element.EndTime.second <= toSeconds(selectedEndTime)) ||
          (element.StartTime.second < toSeconds(selectedStartTime) && element.EndTime.second > toSeconds(selectedStartTime)) ||
          (element.StartTime.second > toSeconds(selectedStartTime) && element.StartTime.second < toSeconds(selectedEndTime))
        ) {
          let st = element.StartTime.second - toSeconds(selectedStartTime);
          element.StartTime.second = Math.sign(st) > 0 ? st : 0;
          let et = element.EndTime.second - toSeconds(selectedStartTime);
          element.EndTime.second = et;
          snipSentences.push(element);
        }
      });
    } else {
      // FILTERING PLAYLIST SENTENCES
      let pIndexSentences = sentenceArr.filter((sen) => sen.MediaIndex === props.playlistIndex);
      // SETTING MEDIAINDEX TO 0
      let updatedIndexSentences = [];
      for (let i = 0; i < pIndexSentences.length; i++) {
        let tempObj = pIndexSentences[i];
        tempObj.MediaIndex = 0;
        updatedIndexSentences.push(tempObj);
      }
      snipSentences = updatedIndexSentences;
    }
    return snipSentences;
  };

  const updateAndSaveResponseObject = async (req) => {
    try {
      let snipMediaIndex = req.snipMediaIndex;
      const newResponseObject = {
        id: req.snipResult.data.fileID.toString().slice(0, -4),
        createdOnMillis: DateTime.now().toUTC().toMillis(),
        sourceStartTime: req.snipStart,
        sourceEndTime: req.snipEnd,
        snippetName: req.snipName,
        isAudio: req.isVideoFile ? props.isAudioPlayingInPlaylist : true,
      };
      if (!req.isVideoFile) {
        // AUDIO
        newResponseObject.fileContent = {
          ...stateDetails?.rawData?.fileContent,
          BucketURL: req.snipResult.data.outputBucketURL,
          Duration: req.snipDuration,
          OverallSentiment: "",
          sentences: req.snipSentences,
        };
      } else {
        // VIDEO
        let mediaURLObjByIndex = { ...stateDetails?.rawData?.fileContent?.MediaURL[snipMediaIndex] };
        mediaURLObjByIndex.BucketURL = constants.signedURLToFilePath(stateDetails?.rawData?.fileContent?.MediaURL[snipMediaIndex]?.BucketURL, false);
        mediaURLObjByIndex.ThumbnailURL = constants.signedURLToFilePath(
          stateDetails?.rawData?.fileContent?.MediaURL[snipMediaIndex]?.ThumbnailURL,
          false
        );
        let audioURLObjByIndex = constants.signedURLToFilePath(stateDetails?.rawData?.fileContent?.AudioURL[snipMediaIndex], true);
        newResponseObject.fileContent = {
          ...stateDetails?.rawData?.fileContent,
          BucketURL: props.isAudioPlayingInPlaylist ? audioURLObjByIndex : mediaURLObjByIndex.BucketURL,
          Duration: req.snipDuration,
          OverallSentiment: "",
          sentences: req.snipSentences,
          AudioURL: props.isAudioPlayingInPlaylist ? [audioURLObjByIndex] : [],
          MediaURL: [mediaURLObjByIndex],
          TranscriptsURL: [],
        };
      }
      delete newResponseObject.fileContent["Snippets"];
      let res = await saveSnippet(newResponseObject);
      if (res.success) {
        return res.data.url;
      }
    } catch (e) {
      setErrorObject({
        isError: true,
        msgError: "ERR: Failed to save snippet",
      });
      setOpen(false);
    }
  };

  const toHHMMSS = (timestamp) => {
    var hours = Math.floor(timestamp / 60 / 60);
    var minutes = Math.floor(timestamp / 60) - hours * 60;
    var seconds = timestamp % 60;
    if (stateDetails.isLongCall) {
      return hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
    }
    return minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(snippetURL);
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleOpenLink = () => {
    if (!stateDetails.isSnippetCall) {
      window.open(snippetURL, "_blank");
    } else {
      let curr = window.location.href;
      curr = curr.split("_snip")[0];
      window.open(curr, "_blank");
    }
  };

  const toSeconds = (time) => {
    let hms = time.toString();
    if (hms.length < 3) {
      // hms = hms;
    } else if (hms.length < 6) {
      const a = hms.split(":");
      hms = +a[0] * 60 + +a[1];
    } else {
      const a = hms.split(":");
      hms = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    }
    return hms;
  };

  const handleSnippetNameChange = (e) => {
    setErrorObject({
      isError: false,
      msgError: "",
    });
    let formattedName = e.target.value;
    setSnippetName(formattedName);
  };

  const blurSnippetNameChange = (e) => {
    let formattedName = e.target.value;
    setSnippetName(formattedName);
  };

  return (
    <React.Fragment>
      <div className={styles.alert}>{errorObject.isError ? <Alert severity="error">{errorObject.msgError}</Alert> : undefined}</div>
      <div className={styles.snippetContainer}>
        {props.dataForSnip.isSnippetOpen ? (
          <Paper className={styles.form} elevation={2}>
            <form>
              <div className={styles.snippetInfoDiv}>
                <Typography className={styles.snippetInfoText}>
                  Enter start and end times, or click and drag through the graph to select a segment.
                </Typography>
              </div>
              <div className={styles.divTime}>
                <NumberFormat
                  format={inputFormat}
                  inputMode="numeric"
                  displayType="input"
                  mask="_"
                  placeholder={toHHMMSS(0)}
                  variant="outlined"
                  label="Start"
                  value={toHHMMSS(props.dataForSnip.dragStartTime)}
                  helperText={helpText}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ pattern: inputRegex }}
                  onValueChange={props.onHandleStartChange}
                  onBlur={props.onBlurStartTimeChange}
                  customInput={ValidationTextField}
                />
                <span className={styles.spanTime}>-</span>
                <NumberFormat
                  format={inputFormat}
                  inputMode="numeric"
                  displayType="input"
                  mask="_"
                  placeholder={toHHMMSS(0)}
                  variant="outlined"
                  label="End"
                  value={toHHMMSS(props.dataForSnip.dragEndTime)}
                  helperText={helpText}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ pattern: inputRegex }}
                  onValueChange={props.onHandleEndChange}
                  onBlur={props.onBlurEndTimeChange}
                  customInput={ValidationTextField}
                />
              </div>
              <div className={styles.snippetButtonDiv}>
                <ValidationTextFieldFullWidth
                  displayType="input"
                  placeholder="Enter a snippet name here"
                  variant="outlined"
                  label="Snippet name (optional)"
                  value={snippetName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ pattern: inputSnippetNameRegex, maxlength: 30 }}
                  onChange={handleSnippetNameChange}
                  onBlur={blurSnippetNameChange}
                />
                <Button className={styles.snippetButton} variant="contained" disableElevation onClick={handleShareSnippetClick}>
                  <Typography className={styles.snippetText}>Share Snippet </Typography>
                </Button>
              </div>
              <Backdrop className={styles.backdrop} open={open} onClick={handleClose}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={openSnack}
                autoHideDuration={2000}
                onClose={handleCloseSnack}
                message="Copied to clipboard"
                action={
                  <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              />
              <Modal
                className={styles.modal}
                open={openModal}
                onClose={handleModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openModal}>
                  <div className={styles.paper}>
                    <div>
                      <TextField
                        inputProps={{ type: "url" }}
                        defaultValue={snippetURL}
                        helperText="Access your snippet here"
                        label="URL"
                        variant="outlined"
                        className={styles.snippetTextField}
                      />
                      <HtmlTooltip
                        className={styles.snippetActionButton}
                        title={
                          <React.Fragment>
                            <em>{"Open Link"}</em>
                          </React.Fragment>
                        }
                        arrow
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        placement="bottom"
                      >
                        <Fab size="small">
                          <OpenInNewIcon className={styles.snippetActionButton} onClick={handleOpenLink} />
                        </Fab>
                      </HtmlTooltip>
                      <HtmlTooltip
                        className={styles.snippetActionButton}
                        title={
                          <React.Fragment>
                            <em>{"Copy to clipboard"}</em>
                          </React.Fragment>
                        }
                        arrow
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        placement="bottom"
                      >
                        <Fab size="small">
                          <FileCopyIcon onClick={handleCopyToClipboard} />
                        </Fab>
                      </HtmlTooltip>
                    </div>
                  </div>
                </Fade>
              </Modal>
            </form>
          </Paper>
        ) : undefined}
      </div>
    </React.Fragment>
  );
};

export default CallSnippet;
