import React from "react";
import { Clear } from "@mui/icons-material";
import { TextField } from "@material-ui/core";
import styles from "./ViewSection.module.css";
import { withStyles } from "@material-ui/core/styles";

export const ViewSection = (props) => {
  const { onSectionDetailsChange, sectionData, sectionLength, handleDelete, sectionId } = props;

  return (
    <>
      <div className={styles.MainSectionLayout} key={`viewsection_${sectionId}`}>
        <div className={styles.MainInnerLayout} key={`viewsection_con_${sectionId}`}>
          <div className={styles.SectionInputLayout} key={`viewsection_title_${sectionId}`}>
            <StyledTextField
              className={styles.CardTitleStyled}
              name="Section Title"
              variant="outlined"
              label="Section title"
              onChange={(event) => onSectionDetailsChange("sectionTitle", event.target.value, false, sectionId)}
              value={sectionData.sectionTitle}
            />
          </div>

          {sectionLength > 1 && (
            <div className={styles.SectionCloseIconWrapper}>
              <Clear color="primary" onClick={() => handleDelete(sectionId)} />
            </div>
          )}
        </div>

        {props.children}
      </div>
    </>
  );
};

const StyledTextField = withStyles({
  root: {
    "& input": {
      color: "#495057",
      fontSize: "14px",
    },
    "& fieldset": {
      borderRadius: "10px",
      fontSize: "14px",
    },
    "& textarea": {
      color: "#495057",
      fontSize: "14px",
    },
  },
})(TextField);
