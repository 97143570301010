import React from "react";
import * as constants from "../../constants";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

export default function KpiChip(props) {
  const StyledButton = withStyles(() => ({
    root: {
      fontSize: "8px",
      fontFamily: "Inter, sans-serif",
      "&.yellow": {
        backgroundColor: "#fff",
      },
      "&.red": {
        backgroundColor: "#fff",
      },
      "&.green": {
        backgroundColor: "#fff",
      },
      margin: "2px 4px",
      padding: "1px 5px",
    },
  }))(Button);

  return (
    <div>
      <StyledButton
        size="small"
        className={constants.getColorByKpi(props.kpiInfos, props.value)}
        // key={props.key}
        label={props.value}
      >
        {props.value}
      </StyledButton>
    </div>
  );
}
