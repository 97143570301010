import ListSubheader from "@material-ui/core/ListSubheader";
import { withStyles } from "@material-ui/core/styles";

const StyledListSubheader = withStyles({
  root: {
    color: "#000000",
    fontWeight: 600,
    fontSize: "14px",
  },
})(ListSubheader);

export default StyledListSubheader;
