import React, { useEffect, useState, useRef } from "react";
import { addEventListeners, removeEventListeners } from "./util/eventListenerUtil";
import { extendUserSession } from "../../assets/cookie-utils";
import { DateTime } from "luxon";
import * as cookieUtils from "../../assets/cookie-utils";

export const TimeoutLogic = () => {
  const [isExtendSessionWindowOpen, setExtendSessionWindowOpen] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const timerId = useRef(null);

  let EXTEND_SESSION_INTERVAL = 1 * 60000; // 1 min

  useEffect(() => {
    const createExtendSessionTimeout = () =>
      setTimeout(() => {
        setExtendSessionWindowOpen(true);
      }, EXTEND_SESSION_INTERVAL);

    const listener = () => {
      if (isExtendSessionWindowOpen) {
        extendUserSession();
        clearTimeout(timeout);
        setExtendSessionWindowOpen(false);
      }
    };

    // Initialization
    let timeout = isExtendSessionWindowOpen ? undefined : createExtendSessionTimeout();
    addEventListeners(listener);

    // Cleanup
    return () => {
      removeEventListeners(listener);
    };
  }, [isExtendSessionWindowOpen]);

  useEffect(() => {
    const autoLogout = async () => {
      if (document.visibilityState === "hidden") {
        // let activeUser = await cookieUtils.fetchActiveUserSession();
        // let expireDateTime = DateTime.fromSQL(activeUser?.expires);
        // let timeToExpireMillis = expireDateTime.diff(DateTime.now());
        // const id = window.setTimeout(() => setIsLoggedOut(true), timeToExpireMillis?.milliseconds);
        // timerId.current = id;
      } else {
        extendUserSession();
        // window.clearTimeout(timerId.current);
      }
    };

    document.addEventListener("visibilitychange", autoLogout);

    return () => {
      document.removeEventListener("visibilitychange", autoLogout);
    };
  }, []);

  useEffect(async () => {
    if (isLoggedOut) {
      let activeUser = await cookieUtils.fetchActiveUserSession();
      if (activeUser.retCode !== 1) {
        setIsLoggedOut(false);
        window.clearTimeout(timerId.current);
        let expireDateTime = DateTime.fromSQL(activeUser?.expires);
        let timeToExpireMillis = expireDateTime.diff(DateTime.now());
        const id = window.setTimeout(() => setIsLoggedOut(true), timeToExpireMillis?.milliseconds);
        timerId.current = id;
      }
    }
  }, [isLoggedOut]);

  return <></>;
};

export default TimeoutLogic;
