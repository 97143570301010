import config from "../config/config";
import fetchError from "../helper/customException";

export default async function getUtteranceFlagTaggedData(category, clientKey, reviewed, currentPage, UtteranceFlagFilterEnabled) {
  // let queryString = `?category=${category}&clientkey=${clientKey}&reviewed=${reviewed}&page=${currentPage}&filter=${UtteranceFlagFilterEnabled}`;
  const queryParams = {
    category,
    clientkey: clientKey,
    reviewed,
    page: currentPage,
    filter: UtteranceFlagFilterEnabled,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  try {
    const response = await fetch(
      config.PCA_SERVER_URL + `getUtteranceFlagTaggedData?${queryString}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
      2 * 10 * 60 * 1000
    );
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response;
  } catch (err) {
    if (typeof err.text === "function") {
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      throw new Error(err);
    }
  }
}
