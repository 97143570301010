/* eslint-disable no-use-before-define */
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import BulletSquare from "../Bullet/Bullet";
import "./selector.css";
import * as constants from "../../constants";
import KpiChip from "../KpiChip/KpiChip";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "20%",
    margin: theme.spacing(0.5),
    height: "auto",
  },
}));

export default function AnimatedMulti(props) {
  const classes = useStyles();

  return (
    <Autocomplete
      size="small"
      className={classes.root}
      disableCloseOnSelect={true}
      multiple
      limitTags={1}
      id={props.id}
      onChange={(event, newValue) => props.optionchanger(newValue, props.name)}
      options={props.options}
      value={props.value}
      renderInput={(params) => <TextField {...params} variant="outlined" label={props.id} />}
      renderOption={(option) => (
        <span className="chipOption">
          <BulletSquare name={option} type={props.name} color={constants.getUniqueColorForEachKpi(props.kpiInfos, option)} />
          <Typography noWrap>{option}</Typography>
        </span>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={`selector-chip-${index}`}
            size="small"
            label={
              <span className="chipTag">
                <BulletSquare name={option} color={constants.getUniqueColorForEachKpi(props.kpiInfos, option)} type={props.name} />
                <KpiChip key={props.name} kpiInfos={props.kpiInfos} value={option} />
              </span>
            }
            {...getTagProps({ index })}
            style={{ height: "100%", background: "#fff", border: "1px solid #BDBDBD" }}
          />
        ))
      }
    />
  );
}
