import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import getAllFilesService from "../services/getAllFiles";
import { Link } from "react-router-dom";
import * as constants from "../assets/constants";
import "./listOfCalls.css";
import LuxonUtils from "@date-io/luxon";
import { DateTime } from "luxon";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import * as cookieUtils from "../assets/cookie-utils";

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#f9f9f9",
    color: "#1077a8",
    fontWeight: 300,
    fontSize: "16px",
    border: "none",
  },
  body: {
    "@media screen and (max-width: 1200px)": {
      fontSize: "12px",
    },
    fontSize: "14px",
    color: "inherit",
    backgroundColor: "#fff",
    border: "none",
    padding: "12px",
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    color: "#656565",
    backgroundColor: "#fff",
    border: "none",
    "&:hover": {
      color: "#000 !important",
      border: "1px solid #16a4e6",
      borderRadius: "10px",
      backgroundColor: "#fff",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    overflowX: "auto",
    display: "block",
    height: "90%",
  },
  paper: {
    minHeight: 453,
    boxShadow: "none",
    overflow: "hidden",
    height: "84%",
  },
  table: {
    minWidth: 700,
    borderRadius: "0px",
  },
  statistics: {
    marginBottom: "20px",
    height: "15%",
  },
  chip: {
    margin: "4px",
  },
  chipSelected: {},
  filters: {
    textAlign: "left",
  },
  date: {
    marginRight: "2em",
  },
  n: {
    color: "inherit",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: "#16a4e6",
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  pageControl: {
    display: "flex",
    justifyContent: "flex-end",
    height: "7%",
    bottom: 0,
    marginLeft: "auto",
  },
  pagiantionText: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#1971C2",
    },
    secondary: {
      main: "#EFEDE8",
    },
  },
});

const secondsToMinutes = (seconds) => {
  let minutes = Math.floor(seconds / 60);
  let secs = Math.floor(seconds % 60);
  let timeStr = `${minutes}m ${secs}s`;
  return timeStr;
};

const getDuration = (file) => {
  try {
    let defaultDuration = file.Duration;
    return secondsToMinutes(defaultDuration);
  } catch (err) {}
};

const yesterdayDate = (dd) => {
  const yesterday = DateTime.fromISO(dd).minus({ days: 1 }).setLocale("en-US").setZone("America/New_York");
  return yesterday;
};

export default function CustomizedTables() {
  const classes = useStyles();

  let initialUserInfo = {
    userType: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    status: "",
    role: "",
    office: "",
    userKey: 0,
    clientKey: 0, // this needs to be taken from environmental variable
  };
  const [userInfo, setUserInfo] = React.useState(initialUserInfo);
  const [isClientAdmin, setIsClientAdmin] = React.useState(false);
  const [rowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [fileData, setFileData] = React.useState(null);
  const [error, setError] = React.useState({
    isError: false,
    message: null,
  });

  let from = DateTime.now().minus({ days: 10 }).setLocale("en-US").setZone("America/New_York").set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  let to = DateTime.now().setLocale("en-US").setZone("America/New_York").set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
  let filters = JSON.parse(sessionStorage.getItem("filters"));
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    filters && filters.timeframe && filters.timeframe.start ? filters.timeframe.start : from
  );
  const [selectedToDate, setSelectedToDate] = React.useState(filters && filters.timeframe && filters.timeframe.end ? filters.timeframe.end : to);

  const handleDateChange = async (date, whichDate) => {
    if (whichDate === "toDate") {
      date.setLocale("en-US").setZone("America/New_York").set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
      setSelectedToDate(date);
      sessionStorage.setItem(
        "filters",
        JSON.stringify({
          ...JSON.parse(sessionStorage.getItem("filters")),
          timeframe: {
            start: selectedFromDate,
            end: date,
          },
        })
      );
    } else if (whichDate === "fromDate") {
      date.setLocale("en-US").setZone("America/New_York").set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      setSelectedFromDate(date);
      sessionStorage.setItem(
        "filters",
        JSON.stringify({
          ...JSON.parse(sessionStorage.getItem("filters")),
          timeframe: {
            start: date,
            end: selectedToDate,
          },
        })
      );
    }
    try {
      let response = await getAllFilesService(
        null,
        page,
        rowsPerPage,
        false,
        {
          timeframe: {
            start: whichDate === "fromDate" ? date : selectedFromDate,
            end: whichDate === "toDate" ? date : selectedToDate,
          },
        },
        userInfo.clientKey
      );
      if (response && response.data) {
        setPage(0);
        setFileData((fileData) => ({
          ...fileData,
          data: response.data.fileList,
          kpiLabels: response.data.kpiLabels,
        }));
      }
    } catch (err) {
      if (err.name === "fetchError") {
        if (err.errCode >= 400 && err.errCode < 500) {
          setError({
            isError: true,
            message: "Bad Request",
          });
        } else if (err.errCode >= 500 && err.errCode < 600) {
          setError({
            isError: true,
            message: "Server Error! Contact Site Admin",
          });
        }
      } else {
        setError({
          isError: true,
          message: "Website is unavailable! Contact Site Admin",
        });
      }
    }
  };

  const nextPage = async () => {
    let newPage = page + 1;
    let next = true;
    let docId = next ? fileData.data[fileData.data.length - 1].id : fileData.data[0].id;
    try {
      let response = await getAllFilesService(
        docId,
        newPage + 1,
        rowsPerPage,
        next,
        {
          timeframe: {
            start: selectedFromDate,
            end: selectedToDate,
          },
        },
        userInfo.clientKey
      );
      if (response && response.data) {
        setFileData((fileData) => ({
          ...fileData,
          data: response.data.fileList,
          kpiLabels: response.data.kpiLabels,
        }));
        setPage(newPage);
      }
    } catch (err) {
      if (err.name === "fetchError") {
        if (err.errCode >= 400 && err.errCode < 500) {
          setError({
            isError: true,
            message: "Bad Request",
          });
        } else if (err.errCode >= 500 && err.errCode < 600) {
          setError({
            isError: true,
            message: "Server Error! Contact Site Admin",
          });
        }
      } else {
        setError({
          isError: true,
          message: "Website is unavailable! Contact Site Admin",
        });
      }
    }
  };

  const prevPage = async () => {
    let newPage = page - 1;
    let next = false;
    let docId = next ? fileData.data[fileData.data.length - 1]?.id : fileData.data[0]?.id;
    try {
      let response = await getAllFilesService(
        docId,
        newPage + 1,
        rowsPerPage,
        next,
        {
          timeframe: {
            start: selectedFromDate,
            end: selectedToDate,
          },
        },
        userInfo.clientKey
      );
      if (response && response.data) {
        setFileData((fileData) => ({
          ...fileData,
          data: response.data.fileList,
          kpiLabels: response.data.kpiLabels,
        }));
        setPage(newPage);
      }
    } catch (err) {
      if (err.name === "fetchError") {
        if (err.errCode >= 400 && err.errCode < 500) {
          setError({
            isError: true,
            message: "Bad Request",
          });
        } else if (err.errCode >= 500 && err.errCode < 600) {
          setError({
            isError: true,
            message: "Server Error! Contact Site Admin",
          });
        }
      } else {
        setError({
          isError: true,
          message: "Website is unavailable! Contact Site Admin",
        });
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        let activeUser = await cookieUtils.fetchActiveUserSession();
        setUserInfo(activeUser);
        setIsClientAdmin(activeUser.isAdmin);
      } catch (err) {
        setError({
          isError: true,
          msgError: "ERR: Failed to validate user token",
        });
      }
    })();

    let filters = sessionStorage.getItem("filters");
    let options = null;

    if (!filters) {
      filters = "{}";
      sessionStorage.setItem("filters", filters); //imp
    } else {
      filters = JSON.parse(filters);

      options = {
        timeframe: {
          start: filters.timeframe && filters.timeframe.start ? filters.timeframe.start : selectedFromDate,
          end: filters.timeframe && filters.timeframe.end ? filters.timeframe.end : selectedToDate,
        },
      };
    }

    (async () => {
      try {
        if (userInfo.clientKey !== 0 && userInfo.clientKey !== -1) {
          setPage(0);
          let response = await getAllFilesService(null, page, rowsPerPage, false, options, userInfo.clientKey);
          if (response && response.data)
            setFileData((fileData) => ({
              ...fileData,
              data: response.data.fileList,
              kpiLabels: response.data.kpiLabels,
            }));
        }
      } catch (err) {
        if (err.name === "fetchError") {
          if (err.errCode >= 400 && err.errCode < 500) {
            setError({
              isError: true,
              message: "Bad Request",
            });
          } else if (err.errCode >= 500 && err.errCode < 600) {
            setError({
              isError: true,
              message: "Server Error! Contact Site Admin",
            });
          }
        } else {
          setError({
            isError: true,
            message: "Website is unavailable! Contact Site Admin",
          });
        }
      }
    })();
    // eslint-disable-next-line
  }, [userInfo.clientKey]);

  return error.isError ? (
    <div
      style={{
        color: "red",
        marginTop: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {error.message}
    </div>
  ) : (
    <div className={classes.root}>
      <div className={classes.statistics}>
        <Grid direction="row" container>
          <Grid container>
            <ThemeProvider theme={theme}>
              {/* userKey changes */}
              {isClientAdmin && userInfo.clientKey === 1 ? (
                <Link
                  to={{
                    pathname: "/choice",
                    search: `${window.location.search}`,
                  }}
                >
                  <Button label="Admin View" style={{ border: "none", backgroundColor: "#b3b0b0" }}>
                    <strong>Admin View</strong>
                  </Button>
                </Link>
              ) : undefined}

              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <Grid style={{ margin: "20px" }} container direction="row" justifyContent="flex-end">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    margin="dense"
                    color="primary"
                    className={classes.date}
                    id="date-picker-dialog-1"
                    label="From Date (ET)"
                    format="MM/dd/yyyy"
                    maxDate={yesterdayDate(selectedToDate)} //maxDate
                    value={selectedFromDate}
                    onChange={(date) => handleDateChange(date, "fromDate")}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    margin="dense"
                    color="primary"
                    className={classes.date}
                    id="date-picker-dialog-2"
                    label="To Date (ET)"
                    format="MM/dd/yyyy"
                    minDate={selectedFromDate} //minDate
                    maxDate={DateTime.now().setLocale("en-US").setZone("America/New_York")} //maxDate
                    value={selectedToDate}
                    onChange={(date) => handleDateChange(date, "toDate")}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>

              {fileData && fileData.data ? (
                <>
                  <TableContainer className={classes.paper}>
                    <Table className={classes.table} aria-label="customized table">
                      <TableHead>
                        <TableRow hover className={classes.tableRow}>
                          <StyledTableCell align="center">No.</StyledTableCell>
                          <StyledTableCell>File/Conversation ID</StyledTableCell>
                          <StyledTableCell>Meeting Host</StyledTableCell>
                          <StyledTableCell>Overall Sentiment</StyledTableCell>
                          <StyledTableCell>Duration</StyledTableCell>
                          <StyledTableCell>Conversation Timestamp</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fileData.data
                          ? fileData.data.map((row, key) => {
                              return (
                                <StyledTableRow key={row.id}>
                                  <StyledTableCell align="center" width="5%" className={classes.noul}>
                                    {page * 10 + key + 1 + "."}{" "}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    <Link
                                      target="_blank"
                                      className={classes.n}
                                      variant="body2"
                                      to={{
                                        pathname: `/${row.id}`,
                                        state: { fileId: row.id },
                                        search: `${window.location.search}`,
                                      }}
                                    >
                                      <span>{row.id}</span>
                                    </Link>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {row.file?.Participants?.filter((p) => p.custom_role === "HUMAN_AGENT")[0]?.name
                                      ? row.file?.Participants?.filter((p) => p.custom_role === "HUMAN_AGENT")[0]?.name
                                      : "AGENT"}
                                  </StyledTableCell>
                                  <StyledTableCell>{row.file.OverallSentiment}</StyledTableCell>
                                  <StyledTableCell>{getDuration(row.file)}</StyledTableCell>
                                  <StyledTableCell>{constants.formatTimeStampToEST(row.file.Timestamp)}</StyledTableCell>
                                </StyledTableRow>
                              );
                            })
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className={classes.pageControl}>
                    <div className={classes.pagiantionText}>
                      {page * 10 + 1} - {page * 10 + 10} of many
                    </div>
                    <Button onClick={prevPage} disabled={page === 0}>
                      <ChevronLeftIcon />
                    </Button>
                    <Button onClick={nextPage} disabled={fileData.data.length < 10}>
                      <ChevronRightIcon />
                    </Button>
                  </div>
                </>
              ) : (
                <p>Fetching File Data...</p>
              )}
            </ThemeProvider>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
