import { makeStyles } from "@material-ui/core/styles";

const detailsPanelStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  menuPaper: {
    maxHeight: 370,
    maxWidth: 300,
  },
  editIconStyles: {
    width: "1rem !important",
    height: "1rem !important",
  },
  editIconDisabledStyles: {
    width: "1rem !important",
    height: "1rem !important",
    color: "gray",
  },
  customToolTip: {
    background: "#495057",
    boxShadow: "0px 6px 18px rgb(0 0 0 / 6%)",
    borderRadius: "10px",
  },
  formControlCallDetails: {
    borderRadius: "10px",
    height: "52px",
    marginBottom: "1rem",
    width: "300px",
    marginTop: "4px",
  },
}));

export default detailsPanelStyles;
