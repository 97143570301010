import { makeStyles } from "@material-ui/core/styles";

const callSnippetStyles = makeStyles((theme) => ({
  shareCard: {
    borderRadius: "30px",
    background: "#F0F3F4",
    marginLeft: "12px",
    float: "right",
    display: "inline",
  },
  snippetContainer: {
    display: "block",
  },
  cardForm: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  divTime: {
    backgroundColor: "white",
    display: "inline-flex",
    margin: theme.spacing(1),
    color: "#555",
  },
  form: {
    width: "inherit",
    borderRadius: "15px !important",
    padding: "8px",
  },
  spanTime: {
    margin: theme.spacing(1),
  },
  alert: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    border: "0px",
    padding: "15px",
    width: "35%",
    "@media screen and (max-width: 1525px)": {
      width: "45%",
    },
  },
  snippetTextField: {
    width: "80%",
  },
  cardContentDiv: {
    paddingBottom: "12px",
  },
  snippetActionButtonDiv: {
    display: "inline",
    width: "20%",
  },
  snippetActionButton: {
    margin: "8px",
  },
  snippetButton: {
    width: "-webkit-fill-available",
    outline: "0 !important",
    borderRadius: "10px !important",
    marginTop: "12px !important",
  },
  snippetButtonDiv: {
    margin: "8px",
  },
  snippetText: {
    textTransform: "none",
    fontSize: "16px",
  },
  snippetInfoText: {
    textAlign: "left",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
  },
  snippetInfoDiv: {
    margin: theme.spacing(1),
  },
}));

export default callSnippetStyles;
