import React from "react";
import * as cookieUtils from "../assets/cookie-utils";
import styles from "./uak-config.module.css";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import getModelCategory from "../services/getModelCategory";
import saveUserAddedKpisConfig from "../services/saveUserAddedKpisConfig.js";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import getFlagsBasedOnCategory from "../services/getFlagsBasedOnCategory.js";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, timelineItemClasses } from "@mui/lab";
import { CheckCircleOutline, CancelOutlined, DoubleArrow, HighlightOff } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DialogActions from "@mui/material/DialogActions";
import { Redirect } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const DiscardButton = styled(Button)({
  display: "flex",
  alignItems: "center",
});

const ApproveButton = styled(Button)({
  display: "flex",
  alignItems: "center",
});

export default function UserAddedKpisConfigurationTool() {
  let initialUserInfo = {
    userType: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    status: "",
    role: "",
    office: "",
    userKey: 0,
    clientKey: 0,
  };
  const [userInfo, setUserInfo] = React.useState(initialUserInfo);
  const [isClientAdmin, setIsClientAdmin] = React.useState(true);
  const [clientKey, setClientKey] = React.useState(null);
  const [modelCategories, setModelCategories] = React.useState([]);
  const [selectedModelCategory, setSelectedModelCategory] = React.useState("");
  const [selectedSubCategory, setSelectedSubCategory] = React.useState("");
  const [availableFlags, setAvailableFlags] = React.useState("");
  const [subcategories, setSubcategories] = React.useState("");
  const [flags, setFlags] = React.useState("");
  const [addedCategory, setAddedCategory] = React.useState(false);
  const [addedSubCategory, setAddedSubCategory] = React.useState(false);
  const [addedFlag, setAddedFlag] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [addTempCategory, setAddTempCategory] = React.useState("");
  const [addTempSubCategory, setAddTempSubCategory] = React.useState("");
  const [addTempFlag, setAddTempFlag] = React.useState("");
  const [renderSubCategoryBlock, setRenderSubCategoryBlock] = React.useState(false);
  const [specialChar, setSpecialChar] = React.useState(null);
  const [hideSubCategoryButton, setHideSubCategoryButton] = React.useState(false);
  const [rawData, setRawData] = React.useState([]);
  const [payload, setPayload] = React.useState({
    categories: {},
  });

  React.useEffect(() => {
    (async () => {
      try {
        let activeUser = await cookieUtils.fetchActiveUserSession();
        setUserInfo(activeUser);
        setIsClientAdmin(activeUser.isAdmin);
      } catch (err) {}
    })();
  }, [userInfo.clientKey]);

  React.useEffect(async () => {
    setModelCategories([]);
    setSelectedModelCategory([]);
    setRenderSubCategoryBlock(false);
    setRawData([]);
    if (clientKey !== null && clientKey !== "") {
      try {
        let response = await getModelCategory(clientKey);
        setModelCategories(response.data);
        setPayload({
          categories: {},
        });
      } catch (err) {}
    }
  }, [clientKey]);

  React.useEffect(async () => {
    setHideSubCategoryButton(false);
    if (selectedModelCategory !== "" && clientKey !== null) {
      try {
        let response = await getFlagsBasedOnCategory(selectedModelCategory, clientKey);
        setAvailableFlags(response.data.allData);
        setSubcategories(response.data.subCategories);
        setRawData(response.data.rawData?.filter((cat) => cat.Category !== "General"));
      } catch (err) {}
    }
  }, [selectedModelCategory]);

  React.useEffect(async () => {
    if (JSON.stringify(subcategories) === JSON.stringify([null])) {
      skipSubCategoryHandler();
    }
  }, [subcategories]);

  // Adding a new category
  const addCategory = (addTempCategory) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      categories: {
        ...prevPayload.categories,
        [addTempCategory]: {
          subCategories: {},
        },
      },
    }));
  };

  // Adding a new subcategory to a specific category
  const addSubCategory = (category, addTempSubCategory) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      categories: {
        ...prevPayload.categories,
        [category]: {
          subCategories: {
            // ...prevPayload.categories[category].subCategories,
            ...(prevPayload.categories?.[category]?.subCategories || {}),
            [addTempSubCategory]: {
              flags: [],
            },
          },
        },
      },
    }));
  };

  // Adding a new flag to a specific subcategory in a specific category
  const addFlag = (category, subCategory, addTempFlag) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      categories: {
        ...(prevPayload.categories || {}),
        [category]: {
          subCategories: {
            ...(prevPayload.categories?.[category]?.subCategories || {}),
            [subCategory]: {
              flags: [...(prevPayload.categories?.[category]?.subCategories?.[subCategory]?.flags || []), addTempFlag],
            },
          },
        },
      },
    }));
  };

  const sendUserAddedKpisConfig = async () => {
    try {
      await saveUserAddedKpisConfig(payload, clientKey);
      alert(`Model Categories are successfully created for clientkey: ${clientKey}`);
      window.location.reload();
    } catch (e) {}
  };

  function debounce(func, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  const debounceOnChange = debounce((value) => {
    setFlags("");
    setClientKey(value);
  }, 1200); // 1200 milliseconds debounce delay

  const handleModelCategoryChange = (item) => {
    setSelectedModelCategory(item);
    setRenderSubCategoryBlock(true);
    setSelectedSubCategory("");
    setFlags("");
  };

  const handleSubCategoryChange = (subcategory) => {
    setSelectedSubCategory(subcategory);
    let filterFlags = availableFlags.filter((x) => x.Subcategory === subcategory);
    let flagsToRender = filterFlags.map((x) => x.Description);
    setFlags(flagsToRender);
  };

  const removeCategory = (categoryName) => {
    setPayload((prevData) => {
      const newData = { ...prevData };

      if (newData.categories[categoryName]) {
        delete newData.categories[categoryName];
      }

      return newData;
    });
  };
  const removeSubCategory = (categoryName, subCategoryName) => {
    setPayload((prevData) => {
      const newData = { ...prevData };

      if (newData.categories[categoryName] && newData.categories[categoryName].subCategories[subCategoryName]) {
        delete newData.categories[categoryName].subCategories[subCategoryName];
      }

      if (Object.keys(newData.categories[categoryName].subCategories).length === 0) {
        delete newData.categories[categoryName];
      }

      return newData;
    });
  };

  const removeFlag = (categoryName, subCategoryName, flagToRemove) => {
    setPayload((prevData) => {
      const newData = { ...prevData };

      if (newData.categories[categoryName] && newData.categories[categoryName].subCategories[subCategoryName]) {
        const subCategory = newData.categories[categoryName].subCategories[subCategoryName];

        if (subCategory.flags) {
          // Remove the specified flag
          subCategory.flags = subCategory.flags.filter((flag) => flag !== flagToRemove);

          // Check if there are no more flags, delete the entire subcategory
          if (subCategory.flags.length === 0) {
            delete newData.categories[categoryName].subCategories[subCategoryName];

            // Check if there are no more subcategories, delete the entire category
            if (Object.keys(newData.categories[categoryName].subCategories).length === 0) {
              delete newData.categories[categoryName];
            }
          }
        }
      }

      return newData;
    });
  };

  function getSubcategories(data, category) {
    // Check if the category exists in the data
    if (data.categories && data.categories[category] && data.categories[category].subCategories) {
      // Extract subcategories from the specified category
      const extractedSubcategories = Object.keys(data.categories[category].subCategories);
      // Filter out subcategories that exist in modelCategories
      const subcategoriesNotInModel = extractedSubcategories.filter((subCategory) => !subcategories.includes(subCategory) && subCategory !== "null");
      return subcategoriesNotInModel;
    } else {
      // Category not found or no subcategories, return an empty array
      return [];
    }
  }

  function getFlags(data, category, subcategory) {
    // Check if the category and subcategory exist in the data
    if (
      data.categories &&
      data.categories[category] &&
      data.categories[category].subCategories &&
      data.categories[category].subCategories[subcategory] &&
      data.categories[category].subCategories[subcategory].flags
    ) {
      // Extract flags from the specified category and subcategory
      const extractedFlags = data.categories[category].subCategories[subcategory].flags;
      const flagsNotInModel = extractedFlags.filter((flag) => !flags.includes(flag));

      return flagsNotInModel;
    } else {
      // Category, subcategory, or flags not found, return an empty array
      return [];
    }
  }

  function getCategories(data) {
    // Check if categories exist in the data
    if (data.categories) {
      // Extract category names from the data
      const categories = Object.keys(data.categories);
      const categoriesNotInModel = categories.filter((category) => !modelCategories?.includes(category));
      return categoriesNotInModel;
    } else {
      // Categories not found, return an empty array
      return [];
    }
  }

  const getDuplicateFlagInfo = () => {
    for (const category in payload.categories) {
      const flagsToCheck = payload.categories[category].subCategories;
      const rawdataForCategory = rawData.filter((item) => item.Category === category);

      for (const subCategory in flagsToCheck) {
        const flags = flagsToCheck[subCategory].flags;

        for (const flag of flags) {
          if (rawdataForCategory.some((item) => item.Description.toLowerCase().includes(flag.toLowerCase()))) {
            return { flag, category };
          }
        }
      }
    }

    return null;
  };

  //method to check if there are duplicate utterance flags comparing rawdata and newly added data
  const duplicateFlagInfo = getDuplicateFlagInfo();

  const getDuplicateFlagInPayloadInfo = () => {
    const flagOccurrences = {};

    for (const category in payload.categories) {
      const subCategories = payload.categories[category].subCategories;

      for (const subCategory in subCategories) {
        const flags = subCategories[subCategory].flags;

        for (const flag of flags) {
          const lowercaseFlag = flag.toLowerCase();

          if (flagOccurrences[lowercaseFlag]) {
            flagOccurrences[lowercaseFlag].push({ flag, category, subCategory });
          } else {
            flagOccurrences[lowercaseFlag] = [{ flag, category, subCategory }];
          }
        }
      }
    }

    const duplicateFlagInfo = Object.values(flagOccurrences).find((flags) => flags.length > 1);

    return duplicateFlagInfo ? duplicateFlagInfo[0] : null;
  };

  //method to check if there are duplicate utterance flags within newly added data
  const duplicateFlagInPayloadInfo = getDuplicateFlagInPayloadInfo();

  const addCategoryHandler = () => {
    setAddedCategory(true);
  };

  const addSubCategoryHandler = () => {
    setAddedSubCategory(true);
  };

  const skipSubCategoryHandler = () => {
    setSelectedSubCategory(null);
    setRenderSubCategoryBlock(true);
    setHideSubCategoryButton(true);
    let filterFlags = availableFlags.filter((x) => x.Subcategory === null);
    let flagsToRender = filterFlags.map((x) => x.Description);
    setFlags(flagsToRender);
  };

  const isAnySubcategoryFlagEmpty = Object.values(payload.categories).some((category) =>
    Object.values(category.subCategories).some((subCategory) => subCategory.flags.length === 0)
  );

  const isAnySubcategoryEmpty = (payload) => {
    const categoryNames = Object.keys(payload.categories);
    for (const categoryName of categoryNames) {
      const subCategories = payload.categories[categoryName]?.subCategories;
      if (subCategories && Object.keys(subCategories).length > 0) {
        return false; // Button should not be disabled
      }
    }
    return true; // Button should be disabled
  };

  const isAnyCategoryEmpty = (payload) => {
    const categoryNames = Object.keys(payload.categories);
    for (const categoryName of categoryNames) {
      const subCategories = payload.categories[categoryName]?.subCategories;
      if (!subCategories || Object.keys(subCategories).length === 0) {
        return true; // Category is empty, button should be disabled
      }
    }
    return false; // No empty categories, button should not be disabled
  };

  const isNoCategories = Object.keys(payload.categories).length === 0;

  const addFlagHandler = () => {
    setAddedFlag(true);
  };

  const debounceOnAddCategory = debounce((value) => {
    setAddTempCategory(value);
  }, 500);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const approveCategory = () => {
    if (
      (!modelCategories || !modelCategories.map((category) => category.toLowerCase()).includes(addTempCategory.toLowerCase())) &&
      (!getCategories(payload) ||
        !getCategories(payload)
          .map((category) => category.toLowerCase())
          .includes(addTempCategory.toLowerCase()))
    ) {
      addCategory(addTempCategory);
      setAddedCategory(false);
    } else {
      alert("Error: Category already exists. Cannot add duplicate entry.");
      setAddedCategory(false);
    }
  };

  const discardCategory = () => {
    setAddedCategory(false);
  };

  const debounceOnAddSubCategory = debounce((value) => {
    setAddTempSubCategory(value);
  }, 500);

  const approveSubCategory = () => {
    if (
      (!subcategories || !subcategories.map((subCategory) => subCategory?.toLowerCase()).includes(addTempSubCategory?.toLowerCase())) &&
      (!getSubcategories(payload, selectedModelCategory) ||
        !getSubcategories(payload, selectedModelCategory)
          .map((category) => category.toLowerCase())
          .includes(addTempSubCategory.toLowerCase()))
    ) {
      addSubCategory(selectedModelCategory, addTempSubCategory);
      setAddedSubCategory(false);
    } else {
      alert("Error: Sub Category already exists. Cannot add duplicate entry.");
      setAddedSubCategory(false);
    }
  };

  const discardSubCategory = () => {
    setAddedSubCategory(false);
  };

  const debounceOnAddFlag = debounce((value) => {
    setAddTempFlag(value);
  }, 500);

  const handleInput = (e) => {
    // Check if the input contains any special character other than space
    const match = e.target.value.match(/[^A-Za-z0-9\s]/);

    if (match) {
      // Set the special character to display in the div
      setSpecialChar(match[0]);
    } else {
      // No special character, clear the div
      setSpecialChar(null);
      debounceOnAddFlag(e.target.value);
    }
  };

  const approveFlag = () => {
    if (
      (!flags || !flags.map((flag) => flag.toLowerCase()).includes(addTempFlag.toLowerCase())) &&
      (!getFlags(payload, selectedModelCategory, selectedSubCategory) ||
        !getFlags(payload, selectedModelCategory, selectedSubCategory)
          .map((flag) => flag.toLowerCase())
          .includes(addTempFlag.toLowerCase()))
    ) {
      addFlag(selectedModelCategory, selectedSubCategory, addTempFlag);
      setAddedFlag(false);
    } else {
      alert("Error: Flag already exists. Cannot add duplicate entry.");
      setAddedFlag(false);
    }
  };

  const discardFlag = () => {
    setAddedFlag(false);
    setSpecialChar(null);
  };

  if (userInfo.clientKey !== 0) {
    if (!isClientAdmin || userInfo.clientKey !== 1) {
      return <Redirect to={"/files"}></Redirect>;
    }
  }
  return (
    <div className={styles.mainContainer}>
      <div className={styles.columnOne}>
        <FormControl sx={{ m: 1, width: 120, marginTop: 2 }}>
          <TextField
            id="demo-simple-select"
            label="ClientKey"
            variant="outlined"
            type="text"
            onChange={(e) => debounceOnChange(e.target.value)}
            onInput={(e) => {
              e.preventDefault();
              const inputValue = e.target.value;
              const numericValue = inputValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
              e.target.value = numericValue;
              debounceOnChange(numericValue);
            }}
            inputProps={{
              maxLength: 11,
              inputMode: "numeric",
            }}
          />
        </FormControl>
        <React.Fragment>
          {modelCategories?.length > 0 || getCategories(payload)?.length > 0 ? (
            <>
              <b>
                <h4 className={styles.headings}>Categories</h4>
              </b>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {modelCategories?.map((item, index) => (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot color="primary" />
                      {index < modelCategories.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Button
                        onClick={() => handleModelCategoryChange(item)}
                        variant={item === selectedModelCategory ? "contained" : "outlined"}
                        className={styles.timelineButtons}
                      >
                        {item}
                      </Button>
                    </TimelineContent>
                  </TimelineItem>
                ))}
                {getCategories(payload).map((item, index) => (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot color="primary" variant="outlined" />
                      {index < getCategories(payload).length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Button
                        onClick={() => handleModelCategoryChange(item)}
                        variant={item === selectedModelCategory ? "contained" : "outlined"}
                        className={styles.timelineButtons}
                      >
                        {item}
                      </Button>
                      <Button className={styles.removeButton} onClick={() => removeCategory(item)}>
                        <HighlightOff />
                      </Button>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </>
          ) : (
            <></>
          )}
          {clientKey !== null && clientKey !== "" && !addedCategory ? (
            <>
              <Button
                component="label"
                className={styles.addCategoryBtn}
                variant="outlined"
                startIcon={<AddCircleIcon fontSize="large" />}
                onClick={addCategoryHandler}
              >
                Add Category
              </Button>
            </>
          ) : (
            <></>
          )}
          {addedCategory ? (
            <>
              <TextField
                className={styles.addCategoryBtn}
                label="Add Category"
                variant="outlined"
                onChange={(e) => debounceOnAddCategory(e.target.value)}
                inputProps={{
                  maxLength: 50,
                }}
              />
              <div className={styles.addSubCategoryButtons}>
                <ApproveButton variant="outlined" color="primary" size="large" onClick={approveCategory} disabled={addTempCategory.trim() === ""}>
                  <CheckCircleOutline />
                </ApproveButton>
                <DiscardButton variant="outlined" color="error" size="large" onClick={discardCategory}>
                  <CancelOutlined />
                </DiscardButton>
              </div>
            </>
          ) : (
            <></>
          )}
        </React.Fragment>
      </div>
      {renderSubCategoryBlock ? (
        <div className={styles.columnTwo}>
          <b>
            <h4 className={styles.headings}>Sub categories</h4>
          </b>

          <div className={styles.timeLineContainerSecond}>
            {selectedSubCategory === null && subcategories.length === 1 && JSON.stringify(subcategories) === JSON.stringify([null]) ? (
              <b style={{ display: "block", marginTop: 10 }}>No subcategories available for the selected category.</b>
            ) : (
              <>
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  {subcategories !== null
                    ? subcategories
                        ?.filter((item) => item !== null) // Filter out null items
                        ?.map((item, index) => (
                          <TimelineItem key={index}>
                            <TimelineSeparator>
                              <TimelineDot color="primary" />
                              {index < subcategories.length - 1 && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                              <Button
                                onClick={() => handleSubCategoryChange(item)}
                                variant={item === selectedSubCategory ? "contained" : "outlined"}
                                className={styles.timelineButtons}
                              >
                                {item}
                              </Button>
                            </TimelineContent>
                          </TimelineItem>
                        ))
                    : []}

                  {getSubcategories(payload, selectedModelCategory).map((item, index) => (
                    <TimelineItem key={index}>
                      <TimelineSeparator>
                        <TimelineDot color="primary" variant="outlined" />
                        {index < getSubcategories(payload, selectedModelCategory).length - 1 && <TimelineConnector />}
                      </TimelineSeparator>
                      <TimelineContent>
                        <Button
                          onClick={() => handleSubCategoryChange(item)}
                          variant={item === selectedSubCategory ? "contained" : "outlined"}
                          className={styles.timelineButtons}
                        >
                          {item}
                        </Button>
                        <Button className={styles.removeButton} onClick={() => removeSubCategory(selectedModelCategory, item)}>
                          <HighlightOff />
                        </Button>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </>
            )}
          </div>

          <>
            {!addedSubCategory && !hideSubCategoryButton ? (
              <Button
                component="label"
                className={styles.addSubCategoryBtn}
                variant="outlined"
                startIcon={<AddCircleIcon fontSize="large" />}
                onClick={addSubCategoryHandler}
              >
                Add Sub Category
              </Button>
            ) : (
              <></>
            )}

            {addedSubCategory ? (
              <>
                <TextField
                  label="Add Sub Category"
                  variant="outlined"
                  onChange={(e) => debounceOnAddSubCategory(e.target.value)}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
                <div className={styles.addSubCategoryButtons}>
                  <ApproveButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={approveSubCategory}
                    disabled={addTempSubCategory.trim() === ""}
                  >
                    <CheckCircleOutline />
                  </ApproveButton>
                  <DiscardButton variant="outlined" color="error" size="large" onClick={discardSubCategory}>
                    <CancelOutlined />
                  </DiscardButton>
                </div>
              </>
            ) : (
              <></>
            )}
            {subcategories.length === 0 && getSubcategories(payload, selectedModelCategory).length === 0 ? (
              <>
                <Button
                  component="label"
                  className={styles.skipButton}
                  variant="outlined"
                  color="secondary"
                  startIcon={<DoubleArrow fontSize="large" />}
                  onClick={skipSubCategoryHandler}
                >
                  Skip SubCategory
                </Button>
              </>
            ) : (
              <></>
            )}
          </>
        </div>
      ) : (
        <></>
      )}
      {flags !== "" ? (
        <div className={styles.columnThree}>
          <b>
            <h4 className={styles.headings}>Utterance Flags</h4>
          </b>
          <div className={styles.timeLineContainerSecond}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {flags.map((item, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot color="primary" />
                    {index < flags.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>{item}</TimelineContent>
                </TimelineItem>
              ))}
              {getFlags(payload, selectedModelCategory, selectedSubCategory).map((item, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot color="primary" variant="outlined" />
                    {index < getFlags(payload, selectedModelCategory, selectedSubCategory).length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    {item}
                    <Button className={styles.removeButton} onClick={() => removeFlag(selectedModelCategory, selectedSubCategory, item)}>
                      <HighlightOff />
                    </Button>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </div>

          <>
            {!addedFlag ? (
              <Button
                component="label"
                className={styles.addSubCategoryBtn}
                variant="outlined"
                startIcon={<AddCircleOutlineIcon fontSize="large" />}
                onClick={addFlagHandler}
              >
                Add Utterance Flag
              </Button>
            ) : (
              <></>
            )}
            {addedFlag ? (
              <>
                <TextField
                  label="Add Utterance Flag"
                  variant="outlined"
                  onChange={handleInput}
                  inputProps={{
                    maxLength: 32,
                  }}
                />
                <div className={styles.addSubCategoryButtons}>
                  <ApproveButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={approveFlag}
                    disabled={specialChar !== null || addTempFlag.trim() === ""}
                  >
                    <CheckCircleOutline />
                  </ApproveButton>
                  <DiscardButton variant="outlined" color="error" size="large" onClick={discardFlag}>
                    <CancelOutlined />
                  </DiscardButton>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
          {specialChar && (
            <div className={styles.errorDiv}>
              <ErrorOutlineIcon style={{ color: "darkred", marginRight: 5 }} />
              {`Err: UtteranceFlag cannot contain any special characters. Character detected: ${specialChar}`}
            </div>
          )}
          <Tooltip title={isNoCategories ? "Enabling this button requires the addition of any configuration" : ""} placement="bottom">
            <span>
              <Button
                variant="contained"
                color="error"
                size="large"
                onClick={handleOpenDialog}
                disabled={isNoCategories}
                className={styles.submitButton}
              >
                Submit Configuration
              </Button>
            </span>
          </Tooltip>
          <>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>
                Would you like to add the following configuration for client key: <b>{clientKey}</b>?
              </DialogTitle>
              <DialogContent>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell>Subcategory</TableCell>
                        <TableCell>Utterance Flags</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(payload.categories).map(([category, { subCategories }]) => (
                        <React.Fragment key={category}>
                          {Object.entries(subCategories).length > 0 && // Check for empty object
                            Object.entries(subCategories).map(([subCategory, { flags }]) => (
                              <TableRow key={`${category}-${subCategory}`}>
                                <TableCell>{category}</TableCell>
                                <TableCell>{subCategory}</TableCell>
                                <TableCell>{flags.join(", ")}</TableCell>
                              </TableRow>
                            ))}
                          {Object.entries(subCategories).length === 0 && ( // Handle empty object
                            <TableRow key={`${category}-placeholder`}>
                              <TableCell>{category}</TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {duplicateFlagInfo ? (
                  <div className={styles.errorDiv}>
                    <ErrorOutlineIcon style={{ color: "darkred", marginRight: 5 }} />
                    Err: Utterance flag <b>{duplicateFlagInfo.flag}</b> already exists in category <b>{duplicateFlagInfo.category}</b>.
                  </div>
                ) : null}
                {duplicateFlagInPayloadInfo ? (
                  <div className={styles.errorDiv}>
                    <ErrorOutlineIcon style={{ color: "darkred", marginRight: 5 }} />
                    Err: Duplicate utterance flag found <b>{duplicateFlagInPayloadInfo.flag}</b> in newly added utterance flags for category{" "}
                    <b>{duplicateFlagInPayloadInfo.category}</b>.
                  </div>
                ) : null}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>No</Button>
                <Tooltip
                  title={
                    isAnySubcategoryFlagEmpty ||
                    isNoCategories ||
                    duplicateFlagInfo !== null ||
                    duplicateFlagInPayloadInfo !== null ||
                    isAnySubcategoryEmpty(payload) ||
                    isAnyCategoryEmpty(payload)
                      ? "Utterance Flags cannot be empty"
                      : ""
                  }
                  placement="top"
                >
                  <span>
                    <Button
                      onClick={sendUserAddedKpisConfig}
                      disabled={
                        isAnySubcategoryFlagEmpty ||
                        isNoCategories ||
                        duplicateFlagInfo !== null ||
                        duplicateFlagInPayloadInfo !== null ||
                        isAnySubcategoryEmpty(payload) ||
                        isAnyCategoryEmpty(payload)
                      }
                    >
                      Yes
                    </Button>
                  </span>
                </Tooltip>
              </DialogActions>
            </Dialog>
          </>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
