import config from "../config/config";
import fetchError from "../helper/customException";
import axios from "axios";
import { get } from "lodash";

export default async function getCRMDetailsSearch(requestObject) {
  try {
    const url = `${config.PCA_SERVER_URL}searchCRMCallDetails`;
    const response = await axios.post(url, requestObject);
    const respOptions = get(response, "data.data", []);

    return respOptions;
  } catch (err) {
    if (typeof err.text === "function") {
      //error in fetch call from server side
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      //error in front end code
      throw new Error(err);
    }
  }
}
