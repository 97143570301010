import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { withStyles } from "@material-ui/core/styles";

const StyledToggleButtonGroup = withStyles(() => ({
  root: {
    backgroundColor: "#EEEEEE !important",
    color: "black !important",
    outline: "0 !important",
    borderRadius: "20px",
  },
  grouped: {
    border: 0,
    borderRadius: "20px",
    outline: "0 !important",
  },
}))(ToggleButtonGroup);

export default StyledToggleButtonGroup;
