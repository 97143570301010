import config from "../config/config";
import fetchError from "../helper/customException";

export default async function getEditedFiles(modelCategory, clientKey, dateRange, currentPage) {
  try {
    const queryParams = new URLSearchParams({
      modelCategory,
      clientKey: parseInt(clientKey),
      date: dateRange.join(","),
      Page: currentPage,
    });

    const url = `${config.PCA_SERVER_URL}getUAKEditedFiles?${queryParams}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response;
  } catch (err) {
    if (typeof err.text === "function") {
      // Error in fetch call from the server side
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      // Error in front-end code
      throw new Error(err);
    }
  }
}
