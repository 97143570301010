import React, { useEffect } from "react";
import * as constants from "../../assets/constants";
import styles from "./CustomError.module.css";
import { Typography } from "@material-ui/core";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Stack } from "@mui/material";

export default function CustomError(props) {
  const initialErrorObject = {
    err: false,
    errCode: 0,
    errMessage: "DEFAULT_MSG",
    errSourceURL: window.location.href,
  };
  const [error, setError] = React.useState(initialErrorObject);

  useEffect(() => {
    setError(props.errResponse);
  }, [props.errResponse]);

  const getCustomErrorMessage = (errCode) => {
    switch (errCode) {
      case 401:
        return constants.ERROR_MSG_UNAUTHORIZED;
      case 421:
        return constants.ERROR_MSG_NOT_PROCESSED;
      default:
        return constants.ERROR_MSG_LOADING;
    }
  };

  return (
    <React.Fragment>
      <div className={styles.customErrorContainer}>
        <Stack spacing={3} direction="column" alignItems="center">
          <ErrorOutlineIcon className={styles.customErrorIcon} fontSize="large" />
          <Typography className={styles.customErrorMessage} variant="body1" gutterBottom component="div">
            {getCustomErrorMessage(error.errCode)}
          </Typography>
        </Stack>
      </div>
    </React.Fragment>
  );
}
