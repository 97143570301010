import React, { useEffect, useState, useLayoutEffect } from "react";
import ReactDOMServer from "react-dom/server";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as constants from "../../assets/constants";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Avatar from "@mui/material/Avatar";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import styles from "./SentimentGraph.module.css";
import { Stack } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import Modal from "@mui/material/Modal";
import classNames from "classnames";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

const SentimentGraph = (props) => {
  const theme = useTheme();
  const [openSettingModel, setOpenSettingModel] = useState(false);
  const [KPI, setKPI] = useState(props.kpisSelectedToShowOnGraph || []);
  const [mKPI, setMKPI] = useState(props.meddicKpisSelectedToShowOnGraph || []);
  const [cKPI, setCKPI] = useState(props.customerKpisSelectedToShowOnGraph || []);
  const [dgStartTime, setDgStartTime] = useState(props.dragStartTime);
  const [dgEndTime, setDgEndTime] = useState(props.dragEndTime);
  const [numberParticipants] = useState(props.state.data.Participants.length || 2);
  let chartData = {
    startDurationSeriesRange: {},
    startDragRange: {},
    startDragRangeMaxValue: null,
    startDragRangeMaxX: null,
    endDurationSeriesRange: {},
    endDragRange: {},
    endDragRangeMinValue: null,
    endDragRangeMinX: null,
    chart: null,
    durationAxis: null,
    categoryAxis: null,
    categoryAxis2: null,
    series: null,
    kpiSeries: null,
    zoomStart: null,
    zoomEnd: null,
    data: null,
    kpiData: null,
    chartContainer: null,
    salesContainer: null,
    meddiccContainer: null,
  };

  useEffect(() => {
    if (props.dragStartTime) setDgStartTime(props.dragStartTime);
    if (props.dragEndTime) setDgEndTime(props.dragEndTime);
  }, [props.dragStartTime, props.dragEndTime]);

  useLayoutEffect(() => {
    if (props.kpisSelectedToShowOnGraph) setKPI(props.kpisSelectedToShowOnGraph);
    if (props.meddicKpisSelectedToShowOnGraph) setMKPI(props.meddicKpisSelectedToShowOnGraph);
    if (props.customerKpisSelectedToShowOnGraph) setCKPI(props.customerKpisSelectedToShowOnGraph);

    createChart();
    props.toggleOffSnip();
    if (props.snippetStartTime > 0) {
      props.seekFromGraph(props.snippetStartTime);
    }
    props.setGraphRef({ createChart, createSnipCursors, disposeSnipCursors });
    props.setChartData(chartData);
  }, [
    props.state.data.sentences,
    props.kpisSelectedToShowOnGraph,
    props.meddicKpisSelectedToShowOnGraph,
    props.customerKpisSelectedToShowOnGraph,
    props.recordingIndex,
  ]);

  const createChart = () => {
    am4core.useTheme(am4themes_animated);
    am4core.addLicense(constants.AMCHARTS_LICENSE);
    am4core.options.autoDispose = true;

    // Chart container
    chartData.chartContainer = am4core.create("chartdiv", am4core.Container);
    chartData.chartContainer.layout = "vertical";
    chartData.chartContainer.width = am4core.percent(100);
    chartData.chartContainer.height = am4core.percent(100);

    // Sales KPIs container
    chartData.salesContainer = am4core.create("salesdiv", am4core.Container);
    chartData.salesContainer.width = am4core.percent(100);
    chartData.salesContainer.height = am4core.percent(100);
    chartData.salesContainer.layout = "grid";

    chartData.chart = chartData.chartContainer.createChild(am4charts.XYChart);
    let recordingIndex = props.recordingIndex;
    let sentencesArr = props.state.noTranscript ? [] : props.state.data.sentences;
    chartData.data = constants.transformForChartData(
      sentencesArr,
      props.duration,
      props.state.data.Participants,
      props.state.data.MediaType,
      props.state.data.MediaURL?.length > 0 ? props.state.data.MediaURL : props.state.data.AudioURL
    );
    // Get KPI Data
    chartData.kpiData = constants.getKPIData(
      chartData.data,
      [props.kpisSelectedToShowOnGraph, props.customerKpisSelectedToShowOnGraph, props.meddicKpisSelectedToShowOnGraph],
      props.state.data.Participants,
      props.state.data.MediaType,
      props.kpiInfos,
      props.isVideoFile ? props.state.data.MediaURL.length : 1,
      props.isMeddic,
      props.isVideoFile
    );

    chartData.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chartData.chart.data = chartData.data[recordingIndex];
    createMessageModal();

    chartData.categoryAxis = chartData.chart.yAxes.push(new am4charts.CategoryAxis());
    chartData.categoryAxis.cursorTooltipEnabled = false;
    chartData.categoryAxis.dataFields.category = "ParticipantId";
    chartData.categoryAxis.autoGridCount = false;
    chartData.categoryAxis.gridCount = numberParticipants;
    chartData.categoryAxis.minGridDistance = 20;
    chartData.categoryAxis.renderer.grid.template.location = 0;
    chartData.categoryAxis.renderer.grid.template.strokeOpacity = 0;
    chartData.categoryAxis.renderer.inversed = true;
    chartData.categoryAxis.renderer.labels.template.adapter.add("html", function (value, target) {
      let participantName = target?.dataItem?.dataContext?.ParticipantName;
      let participantRole = target?.dataItem?.dataContext?.ParticipantRole;
      return ReactDOMServer.renderToStaticMarkup(
        <Avatar
          sx={{
            border: constants.checkIfHost(participantRole) ? "1px solid #1971C2 !important" : "1px solid #087F5B !important",
            backgroundColor: "white",
            color: constants.checkIfHost(participantRole) ? "#1971C2" : "087F5B",
            width: 28,
            height: 28,
          }}
        >
          <span className={constants.checkIfHost(participantRole) ? styles.hostAvatarTitle : styles.customerAvatarTitle}>
            {" "}
            {constants.getAcroyn(participantName)}
          </span>
        </Avatar>
      );
    });
    chartData.categoryAxis.renderer.labels.template.tooltip = new am4core.Tooltip();
    chartData.categoryAxis.renderer.labels.template.tooltipX = am4core.percent(50);
    chartData.categoryAxis.renderer.labels.template.tooltipY = am4core.percent(0);
    chartData.categoryAxis.renderer.labels.template.tooltipText = "{ParticipantName}";
    chartData.categoryAxis.renderer.labels.template.propertyFields.fill = "ParticipantColor";
    chartData.categoryAxis.renderer.labels.template.tooltip.label.wrap = true;
    chartData.categoryAxis.renderer.labels.template.tooltip.label.maxWidth = 100;

    chartData.categoryAxis2 = chartData.chart.yAxes.push(new am4charts.CategoryAxis());
    chartData.categoryAxis2.tooltip.disabled = true;
    chartData.categoryAxis2.height = numberParticipants === 2 ? am4core.percent(100) : am4core.percent(20);
    chartData.categoryAxis2.zIndex = 3;
    chartData.categoryAxis2.marginTop = 20;
    chartData.categoryAxis2.dataFields.category = "ParticipantId";
    chartData.categoryAxis2.minGridDistance = 20;
    chartData.categoryAxis2.autoGridCount = false;
    chartData.categoryAxis2.gridCount = numberParticipants;
    chartData.categoryAxis2.cursorTooltipEnabled = false;
    chartData.categoryAxis2.renderer.grid.template.location = 0.5;
    chartData.categoryAxis2.renderer.labels.template.disabled = true;
    chartData.categoryAxis2.renderer.grid.template.disabled = true;
    chartData.categoryAxis2.endLocation = 0;

    chartData.durationAxis = chartData.chart.xAxes.push(new am4charts.DurationAxis());
    chartData.durationAxis.baseUnit = "second";
    chartData.durationAxis.title.text = "Duration (hh:mm:ss)";
    chartData.durationAxis.title.hide();
    chartData.durationAxis.strictMinMax = true;
    chartData.durationAxis.hidden = false;
    chartData.durationAxis.renderer.grid.template.strokeOpacity = 0;
    chartData.chart.durationFormatter.durationFormat = ""; // hh:mm:ss

    chartData.series = chartData.chart.series.push(new am4charts.ColumnSeries());
    chartData.series.cursorTooltipEnabled = false;
    chartData.series.dataFields.openValueX = "StartTime";
    chartData.series.dataFields.valueX = "EndTime";
    chartData.series.dataFields.categoryY = "ParticipantId";
    chartData.series.columns.template.tooltip = new am4core.Tooltip();
    chartData.series.columns.template.tooltipX = am4core.percent(50);
    chartData.series.columns.template.tooltipY = am4core.percent(0);
    chartData.series.columns.template.tooltipText = "Sentiment: [bold]{SentimentAnalysis.score}[/]";
    chartData.series.columns.template.width = am4core.percent(80);
    chartData.series.columns.template.height = 16;
    chartData.series.columns.template.propertyFields.fill = "color"; // get color from data
    chartData.series.columns.template.propertyFields.stroke = "color";
    chartData.series.columns.template.strokeOpacity = 1;
    chartData.series.columns.template.column.adapter.add("cornerRadiusTopLeft", getFirstRadius);
    chartData.series.columns.template.column.adapter.add("cornerRadiusBottomLeft", getFirstRadius);
    chartData.series.columns.template.column.adapter.add("cornerRadiusBottomRight", getLastRadius);
    chartData.series.columns.template.column.adapter.add("cornerRadiusTopRight", getLastRadius);

    chartData.kpiSeries = chartData.chart.series.push(new am4charts.LineSeries());
    chartData.kpiSeries.name = "LineSeries";
    chartData.kpiSeries.hiddenInLegend = true;
    chartData.kpiSeries.connect = true;
    chartData.kpiSeries.cursorTooltipEnabled = false;
    chartData.kpiSeries.dataFields.valueX = "StartTime";
    chartData.kpiSeries.dataFields.categoryY = "ParticipantId";
    chartData.kpiSeries.stroke = am4core.color("#ADB5BD");
    chartData.kpiSeries.strokeWidth = 1;
    chartData.kpiSeries.strokeOpacity = 1;
    chartData.kpiSeries.locationY = 0;
    chartData.kpiSeries.dy = 8;
    chartData.kpiSeries.dataItems.template.locations.categoryY = 1;
    chartData.kpiSeries.yAxis = chartData.categoryAxis2;
    chartData.kpiSeries.data = chartData.kpiData[recordingIndex];

    let arrows = chartData.kpiSeries.bullets.push(new am4core.RoundedRectangle());
    arrows.propertyFields.rotation = "rotation";
    arrows.propertyFields.width = "width";
    arrows.propertyFields.height = "height";
    arrows.propertyFields.cornerRadiusBottomLeft = "cornerRadiusBottomLeft";
    arrows.propertyFields.cornerRadiusTopLeft = "cornerRadiusTopLeft";
    arrows.propertyFields.cornerRadiusBottomRight = "cornerRadiusBottomRight";
    arrows.propertyFields.cornerRadiusTopRight = "cornerRadiusTopRight";
    arrows.horizontalCenter = "middle";
    arrows.verticalCenter = "middle";
    arrows.direction = "top";
    arrows.locationY = 0;
    arrows.dy = 8;
    arrows.strokeWidth = 0;
    arrows.propertyFields.fill = "color";
    arrows.tooltip = new am4core.Tooltip();
    arrows.propertyFields.tooltipText = "kpi";
    arrows.disabled = true;
    arrows.propertyFields.disabled = "disabled";

    chartData.chart.legend = new am4charts.Legend();
    chartData.chart.legend.useDefaultMarker = true;
    chartData.chart.legend.scrollable = true;
    chartData.chart.legend.position = "top";
    chartData.chart.legend.parent = chartData.salesContainer;
    chartData.chart.legend.itemContainers.template.paddingTop = 2;
    chartData.chart.legend.itemContainers.template.paddingBottom = 2;
    chartData.chart.legend.itemContainers.template.paddingLeft = 2;
    chartData.chart.legend.itemContainers.template.paddingRight = 2;
    chartData.chart.legend.itemContainers.template.events.on("toggled", (ev) => {
      chartData.series.columns.each(function (column) {
        if (column.fill.hex === ev.target.dataItem.dataContext.fill.hex) {
          if (ev.target.isActive) {
            column.hide(0);
          } else {
            column.show(0);
          }
        }
      });
    });
    chartData.chart.legend.data = [
      {
        name: "Very Positive",
        fill: constants.COLOR_TJ_GREEN,
      },
      {
        name: "Positive",
        fill: constants.COLOR_TJ_LIGHT_GREEN,
      },
      {
        name: "Neutral",
        fill: constants.COLOR_TJ_YELLOW,
      },
      {
        name: "Negative",
        fill: constants.COLOR_TJ_ORANGE,
      },
      {
        name: "Very Negative",
        fill: constants.COLOR_TJ_RED,
      },
    ];

    let markerTemplate = chartData.chart.legend.markers.template;
    markerTemplate.width = 20;
    markerTemplate.height = 10;

    // Sort Agent to be displayed first
    chartData.chart.events.on("beforedatavalidated", function () {
      chartData.chart.data.sort(function (a, b) {
        return a.ParticipantRole.localeCompare(b.ParticipantRole);
      });
    });

    // Set cell size in pixels
    let cellSize = numberParticipants === 2 ? 60 : 30;
    chartData.chart.events.on("datavalidated", function (ev) {
      chartData.chart = ev.target;
      let adjustHeight = numberParticipants * cellSize; // Calculate how we need to adjust chartData.chart height
      let targetHeight = chartData.chart.pixelHeight + adjustHeight; // Get current chartData.chart height
      chartData.chart.svgContainer.htmlElement.style.height = targetHeight + "px"; // Set it on chartData.chart's container
    });

    // Set XY Cursor
    chartData.chart.cursor = new am4charts.XYCursor();
    chartData.chart.cursor.lineY.disabled = true;
    chartData.chart.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    chartData.chart.cursor.hideSeriesTooltipsOnSelection = true;
    chartData.chart.leftAxesContainer.layout = "vertical";
    let cursorPosition = {
      //A point on the graph where the user will click
      x: null,
      y: null,
    };
    chartData.chart.cursor.events.on("cursorpositionchanged", (ev) => {
      // extract the coordinated where the user clicked
      let xAxis = ev.target.chart.xAxes.getIndex(0);
      cursorPosition.x = xAxis.positionToValue(xAxis.toAxisPosition(ev.target.xPosition));
    });
    chartData.chart.plotContainer.events.on("hit", () => {
      // seek the audio player to that clicked point.
      let s = cursorPosition.x;
      let currTime = s;
      props.seekFromGraph(currTime);
    });

    // Chart Zoom
    chartData.chart.maxZoomLevel = 1;
    chartData.chart.zoomOutButton.background.cornerRadius(5, 5, 5, 5);
    chartData.chart.zoomOutButton.background.fill = am4core.color("#25283D");
    chartData.chart.zoomOutButton.icon.stroke = am4core.color("#EFD9CE");
    chartData.chart.zoomOutButton.icon.strokeWidth = 2;
    chartData.chart.zoomOutButton.background.states.getKey("hover").properties.fill = am4core.color("#606271");
    // Pre-zoom on chart if start and end time present in query string
    if (props.isEndTimePresentInQueryString) {
      chartData.chart.events.on("ready", () => {
        let start = props.snippetStartTime;
        let end = props.snippetEndTime + 1;
        chartData.durationAxis.zoomToValues(start, end, false, true);
        chartData.durationAxis.keepSelection = true;
        chartData.pauseNotOver = true;
        props.seekFromGraph(start);
      });
    }

    chartData.range = chartData.durationAxis.axisRanges.push(new am4charts.DurationAxisDataItem());
    chartData.range.grid.stroke = chartData.chart.colors.getIndex(0);
    chartData.range.grid.strokeOpacity = 1;
    chartData.range.grid.above = true;
    chartData.range.label.tooltipHTML = "";
    chartData.range.label.maxWidth = 100;
    chartData.range.label.wrap = true;
    chartData.range.label.dy = -180;
    chartData.range.label.showTooltipOn = "always";
    chartData.range.label.tooltip.pointerOrientation = "vertical";
    chartData.range.value = dgStartTime;

    if (!props.state.noTranscript) {
      chartData.range.label.adapter.add("tooltipHTML", (value, target) => {
        let d = target.dataItem.values.value.value;
        let sec = d;
        let allKpisString = "";
        let allKpis = [];
        let speaker = null;
        let label = "";
        for (let k in props.state.sentences) {
          let start = props.state.sentences[k].start;
          let end = props.state.sentences[k].end;
          let mk = props.isMeddic && props.state.sentences[k].mkpi ? props.state.sentences[k].mkpi : [];
          let nk = props.state.sentences[k].kpi ? props.state.sentences[k].kpi : [];
          nk = nk?.filter((k) => k !== constants.hideKPI); // Remove None KPI's
          mk = mk?.filter((k) => k !== constants.hideKPI);
          if (start <= sec && end >= sec) {
            speaker = props.state.sentences[k].speakerName;
            allKpisString = constants.getStringFromArray(nk, props.isMeddic, mk);
            allKpis = [...nk, ...mk];
            break;
          }
        }
        chartData.range.label.fill = am4core.color("#F8F9FA");
        if (allKpis.length <= 0) {
          label = speaker;
        } else {
          if (allKpisString) {
            label = speaker + " : " + allKpisString;
          } else {
            label = speaker;
          }
        }
        return label;
      });
    }

    // Handle cursor zoom done via mouse drag

    chartData.durationAxis.events.on("startendchanged", (ev) => {
      let axis = ev.target;
      chartData.zoomStart = Math.ceil(axis.positionToValue(axis.start));
      chartData.zoomEnd = Math.ceil(axis.positionToValue(axis.end) - 1);
      chartData.snippetEndTime = chartData.zoomEnd;
      chartData.pauseNotOver = true;
      chartData.isEndTimePresentInQueryString = true;
      if (parseInt(ev.target.minZoomed) === 0 && parseInt(ev.target.maxZoomed) === props.state.duration && !chartData.pauseNotOver) {
        // zoom out
        // chartData.chart.zoomOutButton.hide();
        props.seekFromGraph(props.lastTrackedRange);
        props.setParentState({
          dragStartTime: parseInt(chartData.startDragRange.value),
          dragEndTime: parseInt(chartData.endDragRange.value),
        });
        setDgStartTime(parseInt(chartData.startDragRange.value));
        setDgEndTime(parseInt(chartData.endDragRange.value));
        chartData.pauseNotOver = false;
      } else {
        // zoom in
        chartData.range.value = chartData.zoomStart;
        props.setParentState({
          dragStartTime: parseInt(chartData.zoomStart),
          dragEndTime: parseInt(chartData.zoomEnd),
        });
        setDgStartTime(parseInt(chartData.zoomStart));
        setDgEndTime(parseInt(chartData.zoomEnd));
        props.seekOnAudio(chartData.zoomStart);
        chartData.range.value = chartData.zoomStart; // setting the value for tooltip
      }
    });

    // Handle event when zoom out button clicked
    chartData.chart.zoomOutButton.events.on("hit", () => {
      let lastTrackedRange = chartData.range.value;
      if (props.state.isSnippetOpen) {
        lastTrackedRange = parseInt(chartData.startDragRange.value);
      }
      props.setParentState({
        lastTrackedRange: lastTrackedRange,
        dragStartTime: parseInt(chartData.startDragRange.value),
        dragEndTime: parseInt(chartData.endDragRange.value),
      });
      setDgStartTime(parseInt(chartData.startDragRange.value));
      setDgEndTime(parseInt(chartData.endDragRange.value));
    });
  };

  const getFirstRadius = (radius, target) => {
    return target.dataItem.dataContext.isFirst ? 10 : 0;
  };

  const getLastRadius = (radius, target) => {
    return target.dataItem.dataContext.isLast ? 10 : 0;
  };

  const createMessageModal = () => {
    if (!props.processingComplete) {
      chartData.chart.openModal(constants.MSG_UNAVAILABLE, constants.NOTE_UNAVAILABLE);
      chartData.chart.modal.container = document.body;
      chartData.chart.modal.left = "30%";
      chartData.chart.modal.right = "20%";
      chartData.chart.modal.top = "20%";
      chartData.chart.modal.bottom = "20%";
      chartData.chart.modal.defaultStyles = false;
      chartData.chart.modal.closable = false;
      chartData.chart.modal.showCurtain = true;
    } else if (props.processingComplete && props.state.noTranscript) {
      chartData.chart.openModal(constants.MSG_NO_TRANSCRIPT, constants.NOTE_NO_TRANSCRIPT);
      chartData.chart.modal.container = document.body;
      chartData.chart.modal.left = "20%";
      chartData.chart.modal.right = "20%";
      chartData.chart.modal.top = "20%";
      chartData.chart.modal.bottom = "20%";
      chartData.chart.modal.defaultStyles = false;
      chartData.chart.modal.closable = false;
      chartData.chart.modal.showCurtain = true;
    }
  };

  const handleOpen = () => setOpenSettingModel(true);

  const handleClose = () => {
    setKPI(props.kpisSelectedToShowOnGraph);
    setMKPI(props.meddicKpisSelectedToShowOnGraph);
    setCKPI(props.customerKpisSelectedToShowOnGraph);
    setOpenSettingModel(false);
  };

  const onSave = () => {
    props.handleKPIChangeForGraphSelector(KPI, "kpis");
    props.handleKPIChangeForGraphSelector(mKPI, "mkpis");
    props.handleKPIChangeForGraphSelector(cKPI, "ckpis");
    setOpenSettingModel(false);
  };

  const getStylesMaterial = (name, kpiName) => {
    let KPIArray;

    switch (kpiName) {
      case "kpis":
        KPIArray = KPI;
        break;
      case "mkpis":
        KPIArray = mKPI;
        break;
      case "ckpis":
        KPIArray = cKPI;
        break;
      default:
        break;
    }

    return KPIArray && KPIArray?.indexOf(name) === -1
      ? {
          fontWeight: 300,
        }
      : {
          fontWeight: 300,
          backgroundColor: "#f9f9f9",
          color: "#1077a8",
        };
  };

  const handleKPIChange = (kpiName, event) => {
    const {
      target: { value },
    } = event;
    switch (kpiName) {
      case "kpis":
        setKPI(typeof value === "string" ? value.split(",") : value);
        break;
      case "mkpis":
        setMKPI(typeof value === "string" ? value.split(",") : value);
        break;
      case "ckpis":
        setCKPI(typeof value === "string" ? value.split(",") : value);
        break;
      default:
        break;
    }
  };

  const createSnipCursors = () => {
    props.pauseMedia();
    setDgStartTime(parseInt(chartData.range?.value));
    props.setParentState({ dragStartTime: parseInt(chartData.range?.value) });
    chartData.chart.cursor.behavior = "none";
    chartData.chart.zoomOutButton.disabled = true;
    chartData.chart.cursor.lineX.disabled = true;
    chartData.durationAxis.cursorTooltipEnabled = false;
    // Start Cursor
    // Create this.series range
    chartData.startDurationSeriesRange = chartData.durationAxis.createSeriesRange(chartData.series);
    chartData.startDurationSeriesRange.value = 0;
    chartData.startDurationSeriesRange.endValue = chartData.range?.value;
    chartData.startDurationSeriesRange.axisFill.fillOpacity = 0.2;
    chartData.startDurationSeriesRange.grid.strokeOpacity = 0;

    // Create value axis range for modifying this.series range
    chartData.startDragRange = chartData.durationAxis.axisRanges.create();
    chartData.startDragRange.value = chartData.range?.value;
    chartData.startDragRange.grid.above = true;
    chartData.startDragRange.grid.stroke = am4core.color("#396478");
    chartData.startDragRange.grid.strokeWidth = 2;
    chartData.startDragRange.grid.strokeOpacity = 1;
    chartData.startDragRange.label.text = "START";
    chartData.startDragRange.label.fontSize = 12;
    chartData.startDragRange.label.background.fill = am4core.color("#396478");
    chartData.startDragRange.label.fill = am4core.color("#fff");
    chartData.startDragRange.label.draggable = true;
    chartData.startDragRange.label.minY = -22;
    chartData.startDragRange.label.maxY = -22;
    chartData.startDragRange.label.minX = 0;
    chartData.startDragRange.label.dy = 0;
    chartData.startDragRange.label.inside = false;

    chartData.startDragRange.label.events.on("drag", function () {
      chartData.startDragRangeMaxValue = dgEndTime - 4.99; // minimum ~5 seconds for snippet(4.99 for callibration)
      chartData.startDragRangeMaxX = chartData.durationAxis.renderer.positionToCoordinate(
        chartData.durationAxis.valueToPosition(chartData.startDragRangeMaxValue)
      );
      chartData.startDragRange.label.maxX = chartData.startDragRangeMaxX;
      chartData.startDragRange.label.minX = 0.0;
      chartData.startDragRange.grid.stroke.maxX = chartData.startDragRangeMaxX;
      chartData.startDragRange.value = chartData.durationAxis.positionToValue(
        chartData.durationAxis.renderer.coordinateToPosition(chartData.startDragRange.label.pixelX)
      );
      props.seekOnAudio(chartData.startDragRange.value);
      chartData.range.value = parseInt(chartData.startDragRange.value); // setting the value for tooltip
      chartData.startDurationSeriesRange.endValue = chartData.range.value;
      setDgStartTime(parseInt(chartData.startDragRange.value));
      props.setParentState({ dragStartTime: parseInt(chartData.startDragRange.value) });
      chartData.series.invalidateDataItems();
    });

    // End Cursor
    // Create this.series range
    chartData.endDurationSeriesRange = chartData.durationAxis.createSeriesRange(chartData.series);
    chartData.endDurationSeriesRange.value = props.state.duration;
    chartData.endDurationSeriesRange.endValue = dgEndTime;
    chartData.endDurationSeriesRange.axisFill.fillOpacity = 0.2;
    chartData.endDurationSeriesRange.grid.strokeOpacity = 0;

    // Create value axis range for modifying this.series range
    chartData.endDragRange = chartData.durationAxis.axisRanges.create();
    chartData.endDragRange.value = dgEndTime;
    chartData.endDragRange.grid.above = true;
    chartData.endDragRange.grid.stroke = am4core.color("#396478");
    chartData.endDragRange.grid.strokeWidth = 2;
    chartData.endDragRange.grid.strokeOpacity = 1;
    chartData.endDragRange.label.text = "END";
    chartData.endDragRange.label.fontSize = 12;
    chartData.endDragRange.label.background.fill = chartData.endDragRange.grid.stroke;
    chartData.endDragRange.label.fill = am4core.color("#fff");
    chartData.endDragRange.label.draggable = true;
    chartData.endDragRange.label.minY = -22;
    chartData.endDragRange.label.maxY = -22;
    chartData.endDragRange.label.minX = 0;
    chartData.endDragRange.label.dy = 0;
    chartData.endDragRange.label.inside = false;

    chartData.endDragRange.label.events.on("drag", function () {
      chartData.endDragRangeMinValue = dgStartTime + 5.01; // minimum ~5 seconds for snippet(5.01 for callibration)
      chartData.endDragRangeMinX = chartData.durationAxis.renderer.positionToCoordinate(
        chartData.durationAxis.valueToPosition(chartData.endDragRangeMinValue)
      );
      chartData.endDragRange.label.maxX = chartData.durationAxis.renderer.pixelWidth;
      chartData.endDragRange.label.minX = chartData.endDragRangeMinX;
      chartData.endDragRange.grid.stroke.minX = chartData.endDragRangeMinX;
      chartData.endDragRange.value = chartData.durationAxis.positionToValue(
        chartData.durationAxis.renderer.coordinateToPosition(chartData.endDragRange.label.pixelX)
      );
      if (chartData.endDragRange.value >= chartData.endDragRangeMinValue) {
        chartData.endDurationSeriesRange.endValue = chartData.endDragRange.value;
        setDgEndTime(parseInt(chartData.endDragRange.value));
        props.setParentState({ dragEndTime: parseInt(chartData.endDragRange.value) });
      } else {
        chartData.endDragRange.value = chartData.endDragRangeMinValue;
        chartData.endDurationSeriesRange.endValue = chartData.endDragRange.value;
        setDgEndTime(parseInt(chartData.endDragRange.value));
        props.setParentState({ dragEndTime: parseInt(chartData.endDragRange.value) });
      }
      chartData.series.invalidateDataItems();
    });
  };

  const disposeSnipCursors = () => {
    setDgStartTime(parseInt(chartData.startDragRange?.value));
    setDgEndTime(parseInt(chartData.endDragRange?.value));
    props.setParentState({
      dragStartTime: parseInt(chartData.startDragRange.value),
      dragEndTime: parseInt(chartData.endDragRange.value),
    });
    chartData.chart.cursor.behavior = "zoomX";
    chartData.chart.zoomOutButton.disabled = false;
    chartData.chart.cursor.lineX.disabled = false;
    chartData.durationAxis.cursorTooltipEnabled = true;
    chartData.startDurationSeriesRange.value = 0;
    chartData.startDurationSeriesRange.endValue = 0;
    chartData.endDurationSeriesRange.value = 0;
    chartData.endDurationSeriesRange.endValue = 0;
    chartData.startDragRange.hide();
    chartData.endDragRange.hide();
    chartData.chart.invalidateDataItems();
  };

  return (
    <div className="chartRowOuter" style={{ overflowY: numberParticipants === 2 ? "hidden" : "auto" }}>
      {props.state.noTranscript ? undefined : (
        <Stack className="salesStack" direction="row" justifyContent="flex-end">
          <div className="salesdiv"></div>
          <div style={{ width: "9%" }}>
            <SettingsIcon onClick={handleOpen} style={{ marginRight: "10px", marginTop: "6px", cursor: "pointer" }}></SettingsIcon>
          </div>
        </Stack>
      )}
      <Modal open={openSettingModel} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Paper className={styles.kpiModalContainer}>
          {props.optionsForAgentTagged ? (
            <FormControl fullWidth={true} className={styles.inputBox}>
              <React.Fragment>
                <InputLabel className={styles.inputLabel} id="demo-simple-select-helper-label">
                  Show Key Moments
                </InputLabel>
                <Select
                  sx={{ borderRadius: "10px", fontFamily: "Inter, sans-serif" }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={KPI}
                  onChange={(event) => handleKPIChange("kpis", event)}
                  input={<OutlinedInput label="Text" />}
                  MenuProps={MenuProps}
                >
                  {props.optionsForAgentTagged?.length > 0 ? (
                    props.optionsForAgentTagged.map((name, key) => (
                      <StyledMenuItem key={key} value={name} style={getStylesMaterial(name, "kpis", theme)}>
                        {name}
                      </StyledMenuItem>
                    ))
                  ) : (
                    <StyledMenuItem disabled value="">
                      {"No KPIs availabe"}
                    </StyledMenuItem>
                  )}
                </Select>
              </React.Fragment>
            </FormControl>
          ) : undefined}

          {props.optionsForMeddicTagged && props.isMeddic ? (
            <>
              <FormControl fullWidth={true} className={styles.inputBox}>
                <React.Fragment>
                  <InputLabel className={styles.inputLabel} id="demo-simple-select-helper-label">
                    Show Meddic Key Moments
                  </InputLabel>
                  <Select
                    sx={{ borderRadius: "10px", fontFamily: "Inter, sans-serif" }}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={mKPI}
                    onChange={(event) => handleKPIChange("mkpis", event)}
                    input={<OutlinedInput label="Text" />}
                    MenuProps={MenuProps}
                  >
                    {props.optionsForMeddicTagged?.length > 0 ? (
                      props.optionsForMeddicTagged.map((name, key) => (
                        <StyledMenuItem key={key} value={name} style={getStylesMaterial(name, "mkpis", theme)}>
                          {name}
                        </StyledMenuItem>
                      ))
                    ) : (
                      <StyledMenuItem disabled value="">
                        {"No KPIs availabe"}
                      </StyledMenuItem>
                    )}
                  </Select>
                </React.Fragment>
              </FormControl>
            </>
          ) : undefined}

          {props.optionsForCustomerTagged ? (
            <>
              <FormControl fullWidth={true} className={styles.inputBox}>
                <React.Fragment>
                  <InputLabel className={styles.inputLabel} id="demo-simple-select-helper-label">
                    Show Customer Key Moments
                  </InputLabel>
                  <Select
                    sx={{ borderRadius: "10px", fontFamily: "Inter, sans-serif" }}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={cKPI}
                    onChange={(event) => handleKPIChange("ckpis", event)}
                    input={<OutlinedInput label="Text" />}
                    MenuProps={MenuProps}
                  >
                    {props.optionsForCustomerTagged?.length > 0 ? (
                      props.optionsForCustomerTagged.map((name, key) => (
                        <StyledMenuItem key={key} value={name} style={getStylesMaterial(name, "ckpis", theme)}>
                          {name}
                        </StyledMenuItem>
                      ))
                    ) : (
                      <StyledMenuItem disabled value="">
                        {"No KPIs availabe"}
                      </StyledMenuItem>
                    )}
                  </Select>
                </React.Fragment>
              </FormControl>
            </>
          ) : undefined}
          <div className={styles.saveCardDiv}>
            <React.Fragment>
              <Button
                className={classNames(styles.actionButton, styles.actionButtonSave)}
                sx={{ margin: "0px 5px", fontFamily: "Inter, sans-serif" }}
                onClick={onSave}
                variant="outlined"
              >
                Apply
              </Button>

              <Button
                className={classNames(styles.actionButton)}
                sx={{ margin: "0px 5px", fontFamily: "Inter, sans-serif" }}
                onClick={handleClose}
                variant="outlined"
              >
                Cancel
              </Button>
            </React.Fragment>
          </div>
        </Paper>
      </Modal>
      <div id="chartdiv"></div>
    </div>
  );
};

export default SentimentGraph;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "224px",
      width: "250px",
    },
  },
};

const StyledMenuItem = withStyles({
  root: {
    fontFamily: "Inter, sans-serif !important",
  },
})(MenuItem);
