import { withStyles } from "@material-ui/core/styles";
import ErrorIcon from "@mui/icons-material/Error";

export const VeryNegativeIcon = withStyles(() => ({
  root: {
    color: "#C92A2A",
    width: "inherit !important",
    height: "inherit !important",
    verticalAlign: "inherit",
  },
}))(ErrorIcon);
