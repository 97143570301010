import { makeStyles } from "@material-ui/core/styles";

const callMetadataStyles = makeStyles((theme) => ({
  callMetadataRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  callTitle: {
    fontSize: "20px",
    fontWeight: "600",
    color: "#0288d1",
    paddingBottom: "8px",
    lineHeight: "1.1em",
    marginRight: "12px",
    paddingLeft: "8%",
    display: "flex",
    whiteSpace: "break-spaces",
    overflowWrap: "anywhere",
    textAlign: "left",
    paddingRight: "2em",
  },
  meetingInfo: {
    display: "inline-flex",
    alignItems: "center",
  },
  sentimentInfoDiv: {
    display: "inline-flex",
    left: "50%",
    width: "50%",
    alignItems: "center",
  },
  sentimentInfo: {
    padding: "5%",
    borderRadius: "15px",
    border: "1px solid",
    fontSize: "12px",
  },
  callInfo: {
    display: "flex",
    margin: "auto",
    width: "87%",
    alignItems: "center",
  },
  callInfoDocked: {
    display: "flex",
    marginTop: theme.spacing(6),
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  participantDiv: {
    margin: theme.spacing(1),
  },
  participantText: {
    textAlign: "left",
    fontSize: "12px",
    textTransform: "capitalize",
    color: "#495057",
  },
  callSubTitle: {
    color: "#495057",
    paddingLeft: "8%",
  },
  alert: {
    margin: theme.spacing(1),
  },
  hostColor: {
    color: "#1971c2 !important",
    backgroundColor: "white !important",
    border: "1px solid rgba(25, 113, 194, 0.5) !important",
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
}));

export default callMetadataStyles;
