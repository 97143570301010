import config from "../config/config";
import fetchError from "../helper/customException";

export default async function getFileById(fileid, userKey, clientKey, externalAccess) {
  try {
    const requestData = { userKey: userKey, clientKey: clientKey, externalAccess: externalAccess };
    const response = await fetch(
      config.PCA_SERVER_URL + `file${fileid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: `bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(requestData),
      },
      2 * 10 * 60 * 1000
    );
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response;
  } catch (err) {
    if (typeof err.text === "function") {
      throw new fetchError(err);
    } else {
      throw new Error(err);
    }
  }
}
