import config from "../config/config";
import fetchError from "../helper/customException";
import { ObjectToQueryParams } from "../assets/constants";

export async function LogoutService() {
  try {
    const response = await fetch(config.PCA_SERVER_URL + "logout/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("token")}`,
      },
    });
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response;
  } catch (err) {
    if (typeof err.text === "function") {
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      throw new Error(err);
    }
  }
}

export async function LoginWithGoogleAuthService() {
  try {
    let token = "xyz";
    const response = await fetch(config.PCA_SERVER_URL + "login/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response;
  } catch (err) {
    if (typeof err.text === "function") {
      //error in fetch call from server side
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      //client side error
      throw new Error(err);
    }
  }
}

export async function retrieveUserByUserKey(userKey) {
  try {
    const requestData = { userKey: userKey };
    const response = await fetch(
      config.PCA_SERVER_URL + "userInfo",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      },
      2 * 10 * 60 * 1000
    );

    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response;
  } catch (err) {
    if (typeof err.text === "function") {
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      throw new Error(err);
    }
  }
}

export async function validateTokenAPI(token) {
  try {
    const response = await fetch(
      config.PCA_SERVER_URL + `validateAccessToken?token=${token}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/w-www-form-urlencoded",
        },
      },
      2 * 10 * 60 * 1000
    );
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response?.data;
  } catch (err) {
    if (typeof err.text === "function") {
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      throw new Error(err);
    }
  }
}

export async function getExternalAccessTokenAPI(requestObject) {
  try {
    const queryString = ObjectToQueryParams(requestObject);
    const response = await fetch(
      config.PCA_SERVER_URL + `getExternalAccessToken?${queryString}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/w-www-form-urlencoded",
        },
      },
      2 * 10 * 60 * 1000
    );
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response?.data;
  } catch (err) {
    if (typeof err.text === "function") {
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      throw new Error(err);
    }
  }
}

export async function validateExternalAccessTokenAPI(requestObject) {
  try {
    const queryString = ObjectToQueryParams(requestObject);
    const response = await fetch(
      config.PCA_SERVER_URL + `validateExternalAccessToken?${queryString}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/w-www-form-urlencoded",
        },
      },
      2 * 10 * 60 * 1000
    );
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response?.data;
  } catch (err) {
    if (typeof err.text === "function") {
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      throw new Error(err);
    }
  }
}

export async function getUserSessionAPI() {
  try {
    const response = await fetch(
      config.API_GET_USER_SESSION,
      {
        method: "GET",
        credentials: "include",
      },
      2 * 10 * 60 * 1000
    );
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response;
  } catch (err) {
    if (typeof err.text === "function") {
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      throw new Error(err);
    }
  }
}

export async function extendUserSessionAPI() {
  try {
    const response = await fetch(
      config.API_EXTEND_SESSION,
      {
        method: "GET",
        credentials: "include",
      },
      2 * 10 * 60 * 1000
    );
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response;
  } catch (err) {
    if (typeof err.text === "function") {
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      throw new Error(err);
    }
  }
}

export async function endUserSessionAPI() {
  try {
    const response = await fetch(
      config.API_END_SESSION,
      {
        method: "GET",
        credentials: "include",
      },
      2 * 10 * 60 * 1000
    );
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response;
  } catch (err) {
    if (typeof err.text === "function") {
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      throw new Error(err);
    }
  }
}
