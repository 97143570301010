import React from "react";
import getRetrainInfo from "../services/retrainInfoService";
import { Button } from "@mui/material";
import { Table } from "antd";
import CircularProgress from "@material-ui/core/CircularProgress";
import OfflinePinIcon from "@material-ui/icons/OfflinePin";
import { Link } from "react-router-dom";
import * as cookieUtils from "../assets/cookie-utils";
import enableInferencing from "../services/enableInferencingService";
import styles from "./userAddedKPIs.module.css";
import { Redirect } from "react-router-dom";
import BootstrapTooltip from "../assets/components/BootstrapTooltip";

export default function ModelHistory() {
  const urlParams = new URLSearchParams(window.location.search);
  const category = urlParams.get("category") ? decodeURIComponent(urlParams.get("category")) : "";
  const clientKey = urlParams.get("clientkey") ? decodeURIComponent(urlParams.get("clientkey")) : 0;
  const displayString = "Model History for";
  const rowsPerPage = 10;
  let initialUserInfo = {
    userType: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    status: "",
    role: "",
    office: "",
    userKey: 0,
    clientKey: 0,
  };
  const [userInfo, setUserInfo] = React.useState(initialUserInfo);
  const [setError] = React.useState({
    isError: false,
    msgError: null,
  });
  const [data, setData] = React.useState([]);
  const [currentPage, setCurrentpage] = React.useState(1);
  const [JobId, setJobId] = React.useState("");
  const [isClientAdmin, setIsClientAdmin] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      try {
        let activeUser = await cookieUtils.fetchActiveUserSession();
        setUserInfo(activeUser);
        setIsClientAdmin(activeUser.isAdmin);
      } catch (err) {
        setError({
          isError: true,
          msgError: "ERR: Failed to validate user token",
        });
      }
    })();
  }, [userInfo.clientKey]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        let jobs = await getRetrainInfo(currentPage, rowsPerPage, category, clientKey);
        if (data !== jobs.data.modelHistory) {
          setData(jobs.data);
          // setTotalCount(jobs.data.totalCount)
          // setI
        }
      } catch (err) {
        setError({
          isError: true,
          msgError: "ERR: Failed to fetch the data",
        });
      }
    };

    // Call fetchData initially
    fetchData();
    // Set up an interval to call fetchData every 15 seconds
    const intervalId = setInterval(fetchData, 15000);
    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [currentPage]);

  const giveDateTime = (timeStamp) => {
    const timeStampDate = timeStamp;
    const dateInMillis = timeStampDate._seconds * 1000;
    var date = new Date(dateInMillis).toDateString() + " at " + new Date(dateInMillis).toLocaleTimeString();
    return date;
  };

  const handleEnableInferencing = async () => {
    let res = await enableInferencing(category, clientKey, JobId);
    alert(res.message);
    setJobId("");
  };

  const columns = [
    {
      title: "S.NO",
      dataIndex: "sno",
      align: "center",
      render: (text, record, index) => <span key={index}>{index + 1}.</span>,
    },
    { title: "Base Model", dataIndex: "base_model", align: "center" },
    { title: "Model Version", dataIndex: "model_name", align: "center", render: (text) => (text ? text : "-") },
    {
      title: "Precision",
      dataIndex: "precision",
      align: "center",
      render: (text, record) => {
        if (record && record.model_metrics && record.model_metrics["micro avg"] && record.model_metrics["micro avg"].precision !== undefined) {
          return record.model_metrics["micro avg"].precision.toFixed(3);
        }
        return "-";
      },
    },
    {
      title: "Recall",
      dataIndex: "recall",
      align: "center",
      render: (text, record) => {
        if (record && record.model_metrics && record.model_metrics["micro avg"] && record.model_metrics["micro avg"].recall !== undefined) {
          return record.model_metrics["micro avg"].recall.toFixed(3);
        }
        return "-";
      },
    },
    {
      title: "F1 Score",
      dataIndex: "f1Score",
      align: "center",
      render: (text, record) => {
        if (record && record.model_metrics && record.model_metrics["micro avg"] && record.model_metrics["micro avg"]["f1-score"] !== undefined) {
          return record.model_metrics["micro avg"]["f1-score"].toFixed(3);
        }
        return "-";
      },
    },
    {
      title: "Deployed",
      dataIndex: "deployed",
      align: "center",
      render: (text, record) => (
        <span key={record.id}>
          {/* {record.deployed === true ? "Yes" : "No"} */}
          {record.deployed === true ? <OfflinePinIcon style={{ color: "green" }} /> : <OfflinePinIcon style={{ color: "#989898" }} />}
        </span>
      ),
    },
    {
      title: "Job Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <BootstrapTooltip title={record.err_msg ? record.err_msg : ""} placement="top" arrow>
          {text}
        </BootstrapTooltip>
      ),
    },
    { title: "Trigger Type", dataIndex: "trigger_type", align: "center" },
    {
      title: "Trained At",
      dataIndex: "Timestamp",
      align: "center",
      render: (text) => (text ? giveDateTime(text) : "-"),
    },
    {
      title: "Triggered By",
      dataIndex: "trigger_by",
      align: "center",
      render: (text) => (text ? text.split("@")[0] : "-"),
    },
  ];

  if (userInfo.clientKey !== 0) {
    if (!isClientAdmin || userInfo.clientKey !== 1) {
      return <Redirect to={"/files"}></Redirect>;
    }
  }

  return (
    <>
      <Link
        to={{
          pathname: "/UserAddedKPIs",
          search: `${window.location.search}`,
        }}
      >
        <Button variant="outlined" style={{ position: "absolute", left: 0 }}>
          <strong>Back</strong>
        </Button>
      </Link>

      <div className={styles.gap}></div>
      <div className={styles.spaceBetween}>
        <h4>
          {displayString} {category}
        </h4>

        {data?.isTrainingFlag ? <CircularProgress title="Pipeline Job InProgress" size={20} thickness={6} /> : null}
      </div>
      <Table
        dataSource={data?.modelHistory}
        columns={columns}
        rowKey="id"
        onRow={(record) =>
          data?.isTrainingFlag
            ? null
            : {
                onClick: () => {
                  if (record.status !== "ERROR") {
                    setJobId(record.job_id);
                  }
                },
              }
        }
        rowClassName={(record) => (record.job_id === JobId ? styles.selectedRow : "")}
        pagination={{
          total: data?.totalCount,
          simple: true,
          onChange: (page) => {
            setCurrentpage(page);
          },
        }}
        footer={() => (
          <>
            <Button
              disabled={JobId?.length === 0 || data.deployedFlag === true || data.isTrainingFlag === true}
              label="Deploy Model"
              variant="outlined"
              onClick={handleEnableInferencing}
            >
              <strong>Deploy Model</strong>
            </Button>
          </>
        )}
      />
    </>
  );
}
