import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { useEffect } from "react";
import styles from "./BulletCard.module.css";
import { withStyles } from "@material-ui/core/styles";

export const BulletCard = (props) => {
  const { onSectionDetailsChange, defaultValues, handleBulletDel, bulletLength, sectionId, bulletId } = props;

  const [value, setValue] = React.useState(defaultValues?.bulletType);

  useEffect(() => {
    setValue(defaultValues?.bulletType);
  }, [defaultValues.bulletContent]);

  const handleChange = (event) => {
    setValue(event.target.value);

    if (event.target.label === "text") {
      onSectionDetailsChange("bulletContent", event.target.value, true, sectionId, bulletId);
    } else {
      onSectionDetailsChange("bulletType", event.target.value, true, sectionId, bulletId);
    }
  };

  return (
    <>
      <div className={styles.BulletContainer}>
        <div className={styles.BulletLayout}>
          <div className={styles.BulletSeperator} />
          <div className={styles.BulletContent}>
            <div className={styles.BulletLayout}>
              <StyledTextField
                className={styles.BulletCardTitleStyled}
                name="Bullet header"
                variant="outlined"
                label="Bullet title"
                onChange={(event) => onSectionDetailsChange("bulletHeader", event.target.value, true, sectionId, bulletId)}
                value={defaultValues.bulletHeader}
              />
              <div className={styles.BulletIconContainerStyled}>
                {bulletLength > 1 && <ClearIcon color="primary" onClick={() => handleBulletDel(sectionId, bulletId)} />}
              </div>
            </div>
            <div className={styles.BulletContentInfoLayout}>
              <div className={styles.BulletContentInfoInnerLayout}>
                {value === "link" ? (
                  <>
                    <StyledTextField
                      className={styles.BulletLinkStyled}
                      id="outlined-basic"
                      label="Link text"
                      variant="outlined"
                      onChange={(event) => onSectionDetailsChange("bulletLink", event.target.value, true, sectionId, bulletId)}
                      value={defaultValues?.bulletLink}
                    />
                    <StyledTextField
                      className={styles.BulletLinkURLStyled}
                      label="Address"
                      variant="outlined"
                      id="standard-basic"
                      onChange={(event) => onSectionDetailsChange("bulletlinkurl", event.target.value, true, sectionId, bulletId)}
                      value={defaultValues?.bulletlinkurl}
                    />
                  </>
                ) : (
                  <StyledTextField
                    className={styles.BulletTextContentStyled}
                    multiline={true}
                    id="outlined-basic"
                    rows={3}
                    label="Bullet content"
                    variant="outlined"
                    onChange={(event) => onSectionDetailsChange("bulletContent", event.target.value, true, sectionId, bulletId)}
                    value={defaultValues.bulletContent}
                  />
                )}
              </div>
              <div className={styles.BulletRadioContainerLayout}>
                <FormControl component="fieldset">
                  <RadioGroup value={value} onChange={handleChange} color="primary">
                    <FormControlLabel value="text" control={<Radio color="primary" />} label="Text" color="primary" />
                    <FormControlLabel value="link" control={<Radio color="primary" />} label="Link" color="primary" />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const StyledTextField = withStyles({
  root: {
    "& input": {
      color: "#495057",
      fontSize: "14px",
    },
    "& fieldset": {
      borderRadius: "10px",
      fontSize: "14px",
    },
    "& textarea": {
      color: "#495057",
      fontSize: "14px",
    },
  },
})(TextField);
