import React from "react";
import styles from "./CallSummaryAction.module.css";
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { Divider } from "@mui/material";
import { ReactComponent as GNBulletIcon } from "../../../assets/svgs/gn-icon_bullet.svg";
import { ReactComponent as GNActionItemIcon } from "../../../assets/svgs/gn-icon_actionItem.svg";

export default function CallSummaryAction(props) {
  const [call_summary] = React.useState(props.stateInfo.callSummary);
  const call_summary_header = "Call Summary:";
  const actions_header = "Action Items and Next Steps:";

  return (
    <React.Fragment>
      {call_summary.bullet_summary.length > 0 ? (
        <>
          <StyledTitleHeader>{`${call_summary_header}`}</StyledTitleHeader>
          <StyledContentContainer>
            {call_summary.bullet_summary.map((bullet, index) => {
              return (
                <div className={styles.bullet_item} key={`bullet_${index}`}>
                  <div className={styles.bullet_item_icon}>
                    <GNBulletIcon></GNBulletIcon>
                  </div>
                  <StyledSubContent>{bullet}</StyledSubContent>
                </div>
              );
            })}
          </StyledContentContainer>
        </>
      ) : undefined}
      {call_summary.agent_action_items.length > 0 ? (
        <>
          <StyledDivider />
          <StyledTitleHeader>{`${actions_header}`}</StyledTitleHeader>
          <StyledContentContainer>
            {call_summary.agent_action_items.map((action_item, index) => {
              return (
                <div className={styles.action_item} key={`action_item_${index}`}>
                  <div className={styles.action_item_icon}>
                    <GNActionItemIcon></GNActionItemIcon>
                  </div>
                  <StyledSubContent>{action_item}</StyledSubContent>
                </div>
              );
            })}
          </StyledContentContainer>
        </>
      ) : undefined}
    </React.Fragment>
  );
}

const StyledTitleHeader = withStyles({
  root: {
    color: "#495057",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "1rem",
    fontFamily: "Inter",
    textAlign: "initial",
    paddingBottom: "0.75rem",
  },
})(Typography);

const StyledDivider = withStyles({
  root: {
    color: "#495057",
    marginBottom: "1rem !important",
    marginTop: "1rem !important",
  },
})(Divider);

const StyledContentContainer = withStyles({
  root: {
    display: "flow",
    textAlign: "initial",
    maxHeight: "45%",
    overflowY: "auto",
    "& > :not(:last-child)": {
      paddingBottom: "0.75rem",
    },
    "& > *": {
      paddingBottom: "0rem",
    },
  },
})(Box);

const StyledSubContent = withStyles({
  root: {
    color: "#495057",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1rem",
    fontFamily: "Inter",
    paddingLeft: "3px",
  },
})(Typography);
