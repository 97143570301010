import config from "../config/config";
import axios from "axios";

const PROVIDER = "gspb";
const serverURL = config.PCA_SERVER_URL;

const addSpacing = (str) => str.replace(/[:]+/g, ": ");

export const cardContentPayload = (sectionList) => {
  const payload = sectionList?.map((section) => {
    const { sectionTitle, bulletList } = section;
    const bullet = bulletList?.map((bullet) => {
      const { bulletHeader, bulletContent, bulletType, bulletLink, bulletlinkurl } = bullet;

      return {
        bulletheader: bulletHeader,
        bulletcontent: bulletContent,
        bullettype: bulletType,
        bulletlink: bulletLink,
        bulletlinkurl,
      };
    });

    return {
      sectiontitle: sectionTitle,
      bullets: {
        bullet,
      },
    };
  });

  return payload;
};

const validateKeyword = async (payload) => {
  try {
    const { licenseid, cardId, clientkey } = payload;
    if (licenseid !== "" && cardId !== "" && clientkey !== "") {
      const url = `${serverURL}checkKeyword?licenseId=${payload.licenseid}&cardId=${payload.cardId}&clientKey=${payload.clientkey}&officeKey=${payload.officekey}&keyWord=${payload.keyword}&provider=${PROVIDER}`;
      const value = await axios.get(url);
      return value?.data?.data?.data;
    }
  } catch (err) {
    return err.toJSON();
  }
};

export const createContentCard = async (payload) => {
  try {
    let cardContent = {
      clientkey: payload.clientkey,
      officekey: payload.office,
      cardtitle: payload.cardtitle,
      cardtype: payload.cardtype,
      cardid: 0,
      keywords: "",
      cardcontext: payload.context.join(","),
    };

    const section = cardContentPayload(payload.section);
    cardContent = { ...cardContent, sections: { section } };

    const cardContentStringfy = addSpacing(JSON.stringify(cardContent));

    const postCreatePayload = {
      licenseId: payload.licenseid,
      clientKey: payload.clientkey,
      officeKeys: payload.office,
      cardStatus: parseInt(payload.cardstatus),
      keywords: payload.keywords,
      provider: payload.provider,
      cardContent: cardContentStringfy,
    };

    const url = `${serverURL}createContentCard`;
    const value = await axios.post(url, postCreatePayload);
    return value;
  } catch (err) {
    return err.toJSON();
  }
};

export default validateKeyword;
