import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import * as constants from "../../assets/constants";
import Alert from "@mui/material/Alert";
import styles from "./AudioCallAnalysis.module.css";

const AudioCallAnalysis = (props) => {
  const [audioUrl, setAudioUrl] = useState([]);
  const [indexAudio, setindexAudio] = useState(props.audioIndex);

  useEffect(() => setAudioUrl(props.stateData.AudioURL), []);

  function callDuration(index) {
    if (props.stateData.AudioURL[index]) {
      if (props.stateData.MediaURL[index].StartTime !== null) {
        let startValue = props.stateData.MediaURL[index].StartTime._seconds;
        let endValue = props.stateData.MediaURL[index].EndTime._seconds;

        let startTime = new Date(startValue * 1000).getTime();
        let endTime = new Date(endValue * 1000).getTime();

        let ms = (endTime - startTime) / 1000;

        return constants.toStringHHMMSS(ms, ms > 3600);
      } else if (props.stateData.AudioURL?.length === 1 && props.stateData.AudioURL[0]) {
        // case when you have one valid audio analysis file, return entire call duration value
        if (props.stateData.StartTime !== null && props.stateData.EndTime !== null) {
          let startValue = props.stateData.StartTime._seconds;
          let endValue = props.stateData.EndTime._seconds;

          let startTime = new Date(startValue * 1000).getTime();
          let endTime = new Date(endValue * 1000).getTime();

          let ms = (endTime - startTime) / 1000;

          return constants.toStringHHMMSS(ms, ms > 3600);
        } else if (props.stateData?.Duration !== null) {
          let ms = props.stateData?.Duration;
          return constants.toStringHHMMSS(ms, ms > 3600);
        } else return "Duration unavailable";
      } else return "Duration unavailable";
    } else {
      return "Recording unavailable";
    }
  }

  function playlistClick(index) {
    setindexAudio(index);
    try {
      props.setIsAudio(true);
      props.setMediaUrl(audioUrl[index]);
      props.muteAudio(false);
      let sentencesArr = props.stateData.sentences.filter((sen) => sen.MediaIndex === index);
      props.setSentences(sentencesArr);
      props.setindexAudio(index);
      props.setPlaylistIndex(index);
      props.resetSpeed();
      props.toggleOffSnip();
    } catch (err) {}
  }

  return (
    <React.Fragment>
      <div className={styles.audioAnalysisPlaylist}>
        <Typography
          style={{
            margin: "8px",
            marginLeft: "16px",
            float: "left",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "22px",
            color: "#495057",
          }}
        >
          {props.isSnippetCall ? "Audio Snippet Recording Files" : "Audio Only Analysis"}
        </Typography>

        {audioUrl?.length > 0 ? (
          audioUrl?.map((item, index) => {
            return (
              <Card
                key={`aca_play_${index}`}
                elevation={0}
                sx={{
                  display: "flex",
                  margin: "15px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  width: "90%",
                  cursor: "pointer",
                }}
                onClick={() => playlistClick(index)}
              >
                <Grid spacing={0} container>
                  <Grid item xs={3}>
                    <Card
                      sx={
                        indexAudio === index && props.isAudioPlayingInPlaylist
                          ? {
                              border: "2.5px solid #88B3DA",
                              boxShadow: "none",
                              borderRadius: "10px",
                              height: "100px",
                              background: "#E7F5FF",
                            }
                          : {
                              boxShadow: "none",
                              borderRadius: "10px",
                              height: "100px",
                              background: "#E7F5FF",
                            }
                      }
                    >
                      <VolumeUpIcon
                        color="primary"
                        sx={{
                          height: "62px",
                          width: "55px",
                          top: "17px",
                          position: "relative",
                        }}
                      ></VolumeUpIcon>
                    </Card>
                  </Grid>

                  <Grid item xs={9}>
                    <Box>
                      <CardContent sx={{ padding: "10px" }}>
                        <Typography variant="subtitle2" component="div" align="left">
                          {props.isSnippetCall
                            ? props.stateData?.SnippetInfo?.name
                              ? props.stateData?.SnippetInfo?.name
                              : "Snippet file"
                            : `${props.stateData?.Topic} : Part ${index + 1}`}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" component="div" align="left">
                          {props.stateData &&
                          props.stateData?.MediaURL[index].StartTime != null &&
                          props.stateData?.MediaURL[index].StartTime._seconds
                            ? constants.formatTimeStampToEST(props.stateData?.MediaURL[index].StartTime)
                            : "Timestamp unavailable"}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" align="left" component="div">
                          {callDuration(index)}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            );
          })
        ) : (
          <Alert className={styles.alertBox} severity="warning">
            {constants.ERROR_NO_RECORDING_AVAILALE}
          </Alert>
        )}
      </div>
    </React.Fragment>
  );
};

export default AudioCallAnalysis;
