import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import * as constants from "../../assets/constants";
import { Grid, Divider, Autocomplete, Alert } from "@mui/material";
import { Box, Typography, Modal, FormControl, InputLabel, Select, MenuItem, TextField, Button } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import detailsPanelStyles from "./DetailsPanel.css";
import { get, has } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import getCallCRMDetails from "../../services/callCRMDetails";
import getCallSessionID from "../../services/getSessionIdService";
import debounce from "lodash/debounce";
import getDispositionData from "../../services/getDispositionService";
import getContextsData from "../../services/getContextsService";
import getTopicsData from "../../services/getTopicsService";
import getCallMetadata from "../../services/callMetadataService";
import callSalesforceAPISync from "../../services/callSalesforceService";
import saveCRMCallDetails from "../../services/saveCRMCallDetails";
import updateDispositionCall from "../../services/updateCallDispositionService";
import getCRMDetailsSearch from "../../services/searchCRMDetails";
import ParticipantsPanel from "../participants-panel/ParticipantsPanel";

const CRM_SEARCH_DELAY = 1000; // 1sec

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "340px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 2,

  "& label": {
    fontSize: "14px",
  },
};

const notesStyles = {
  width: "450px !important",
};

const initialErrorObject = {
  isError: false,
  msgError: "",
};

const formatNumber = (phoneNumber) => {
  if (phoneNumber !== "") {
    return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)} - ${phoneNumber.substring(6, phoneNumber.length)}`;
  }
};

const DetailsPanel = (props) => {
  const styles = detailsPanelStyles();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [editContentData, setEditContentData] = useState({});
  const [dispositionData, setDispositionData] = useState([]);
  const [contextsData, setContextsData] = useState(props.stateInfo.contextDataInfos);
  const [topicsData, setTopicsData] = useState([]);
  const [isSaveButtonDisable, setSaveButtonDisable] = useState(true);
  const { disableCRMFields } = useState({ contact: true, opportunity: true });
  const [accountBackup, setAccountBackup] = useState([]);
  const [contactBackup, setContactBackup] = useState([]);
  const [leadBackup, setLeadBackup] = useState([]);
  const [opportunityBackup, setOpportunityBackup] = useState([]);

  const [callDetailsError, setCallDetailsError] = useState(initialErrorObject);
  const [crmDetailsError] = useState(initialErrorObject);
  const [notesError, setNotesError] = useState(initialErrorObject);

  const [disableContact, setDisableContact] = useState(true);
  const [disableOpportunity, setDisableOpportunity] = useState(true);
  const [disableAccountName, setDisableAccountName] = useState(false);
  const [disableLead, setDisableLead] = useState(false);
  const [crmSessionInfo, setCRMSessionInfo] = useState({});

  const [selectedDisposition, setSelectedDisposition] = useState({});
  const [selectedContext, setSelectedContext] = useState({});
  const [selectedTopic, setSelectedTopic] = useState({});
  const [allowTopicChange, setAllowTopicChange] = useState(false);

  const [initialData, setInitialData] = useState({});

  const initialNotesInfo = "No notes added during call.";

  const getClientKeyFromStateInfo = () => {
    const clientKey = get(props.stateInfo, "rawData.fileContent.ClientKey", "");
    return clientKey;
  };

  //to show or hide disposition edit based on userKey
  const getUserKeyFromSession = () => {
    const userKey = get(props.stateInfo, "userInfo.userKey", "");
    return parseInt(userKey);
  };

  const getCRMSyncKeyword = () => {
    const { CRMSystem } = crmSyncDetails;
    const crmSystemInfo = CRMSystem?.split(",");
    if (crmSystemInfo?.length > 0) {
      return crmSystemInfo[1];
    }

    return "sfdc";
  };

  useEffect(() => {
    (async () => {
      try {
        const callContext = get(props.stateInfo, "callMetadataInfo.data.CallContext", "")
          ? get(props.stateInfo, "callMetadataInfo.data.CallContext", "")
          : "None";
        if (callContext !== "None") setAllowTopicChange(true);
        const contextItemLabel = contextsData?.find((item) => item.value === callContext)?.label;
        const callContextDescription = contextItemLabel ? contextItemLabel : "None";
        const topicId = get(props.stateInfo, "callMetadataInfo.data.TopicId", 0);
        const topicContext = get(props.stateInfo, "callMetadataInfo.data.TopicContext", "");
        const topicName = get(props.stateInfo, "callMetadataInfo.data.TopicName", "None");
        const number = formatNumber(get(props.stateInfo, "callMetadataInfo.data.Number", "").toString());
        const dispostion = get(props.stateInfo, "callMetadataInfo.data.ActionText", "");
        const dispostionValue = get(props.stateInfo, "callMetadataInfo.data.Action", "");
        const oldDispositionValue = get(props.stateInfo, "callMetadataInfo.data.FollowUpKey", "");
        const currentModelDeployedSeconds = constants.formatTimeStampToEST(get(props.stateInfo, "data.Timestamp", {}));
        setInitialData({
          callContext,
          callContextDescription,
          topicId,
          topicName,
          topicContext,
          number,
          dispostion,
          dispostionValue,
          oldDispositionValue,
          currentModelDeployedSeconds,
        });

        setSelectedDisposition({
          dispositionText: dispostion,
          dispostionValue: dispostionValue.slice(0, -1),
          oldDispositionValue,
        });

        setSelectedContext({
          context: callContext,
          description: callContextDescription,
        });

        setSelectedTopic({
          topicId: topicId,
          topicName: topicName,
          topicContext: topicContext,
        });

        // await getCRMSyncDetails();
        if (!props.externalAccess) {
          if (JSON.stringify(props.callMetadataInfo.data) !== "{}") {
            const crmData = get(props.callMetadataInfo, "data", {});
            setCRMDetails(crmData);
            let callNotesRes = get(crmData, "Notes", "");
            if (callNotesRes) {
              setCallNotesInfo(callNotesRes);
            }
          }
        }
      } catch (err) {
        setErrorObject({
          isError: false,
          msgError: "Error: ",
        });
      }
    })();
  }, [props.stateInfo, props.callMetadataInfo]);

  const [callNotesInfo, setCallNotesInfo] = useState(initialNotesInfo);
  const [errorObject, setErrorObject] = useState(initialErrorObject);

  const [crmDetails, setCRMDetails] = useState({ AccountName: "", CallAccountName: "", CallOpportunity: "", CallContactName: "", CallLeadName: "" });
  const [crmSyncDetails] = useState(props.stateInfo.crmSyncDetails);

  const [openAccountDropDown, setOpenAccountDropdown] = React.useState(false);
  const [accountOptions, setAccountOptions] = React.useState([]);
  const accountOptionsloading = openAccountDropDown && accountOptions.length === 0;
  const [accountNotFound, setAccountNotFound] = useState(false);

  const [openContactDropdown, setContactDropdown] = useState(false);
  const [contactOptions, setContactOptions] = React.useState([]);
  const contactOptionsloading = openContactDropdown && contactOptions.length === 0;
  const [contactNotFound, setContactNotFound] = useState(false);

  const [openLeadDropdown, setLeadDropdown] = useState(false);
  const [leadOptions, setLeadOptions] = React.useState([]);
  const leadOptionsloading = openLeadDropdown && leadOptions.length === 0;
  const [leadNotFound, setLeadNotFound] = useState(false);

  const [openOpporDropdown, setOpporDropdown] = useState(false);
  const [oppOptions, setOppOptions] = React.useState([]);
  const oppOptionsloading = openOpporDropdown && oppOptions.length === 0;
  const [opporNotFound, setOpporNotFound] = useState(false);

  const [searchAccountValue, setSearchAccountValue] = useState("");
  const [searchContactValue, setSearchContactValue] = useState("");
  const [searchOpportunityValue, setSearchOpportunityValue] = useState("");
  const [searchLeadValue, setSearchLeadValue] = useState("");

  const defaultEditButtonVisible = () => {
    const callId = get(props.stateInfo, "rawData.id");
    const UserKey = getUserKeyFromSession();
    let checkUserKey = 0;
    if (callId) {
      const callArr = callId?.split("_");
      checkUserKey = callArr.length > 0 && callArr[1];
      checkUserKey = checkUserKey && checkUserKey.slice(1, checkUserKey.length);
    }

    const date = get(props.stateInfo, "rawData.fileContent.Timestamp", {});
    const dateInMillis = date._seconds * 1000;
    const expirationDate = moment(dateInMillis).add(30, "days");
    const isEditable = moment().isSameOrBefore(expirationDate);
    return isEditable && (parseInt(UserKey) === parseInt(checkUserKey) || props.isAdmin);
  };

  const onAccountChangeHandler = (event, value, dropDownName) => {
    if (dropDownName === "AccountName" && value !== "") {
      setCRMDetails({ ...crmDetails, [dropDownName]: value });
      setDisableLead(true);
      setDisableOpportunity(false);
      setDisableContact(false);
      setContactOptions([{ name: "", value: "" }]);
      setOppOptions([{ name: "", value: "" }]);
      return;
    }

    if (dropDownName === "AccountName" && value === "") {
      setCRMDetails({ ...crmDetails, [dropDownName]: value });
      setDisableLead(false);
      setDisableOpportunity(true);
      setDisableContact(true);
      setContactOptions([{ name: "", value: "" }]);
      setOppOptions([{ name: "", value: "" }]);
      return;
    }

    if (dropDownName === "LeadName" && value !== "") {
      setCRMDetails({ ...crmDetails, [dropDownName]: value });
      setDisableAccountName(true);
      setDisableOpportunity(true);
      setDisableContact(true);
      return;
    }

    if (dropDownName === "LeadName" && value === "") {
      setCRMDetails({ ...crmDetails, [dropDownName]: value });
      setDisableAccountName(false);
      setDisableOpportunity(true);
      setDisableContact(true);
      return;
    }

    setCRMDetails({ ...crmDetails, [dropDownName]: value });
  };

  const getAccountOptionsDelayed = useCallback(
    debounce(async (text) => {
      //try {
      if (text !== "") {
        const respOptions = await getCRMDetailsSearch(text);
        setAccountNotFound(respOptions.length === 0);
        setSaveButtonDisable(false);
        setAccountBackup(respOptions);
        setAccountOptions(respOptions.map((key) => ({ name: key.AccountName, value: key.AccountId })));
      }
    }, CRM_SEARCH_DELAY),
    []
  );

  useEffect(() => {
    getAccountOptionsDelayed(searchAccountValue, () => {
      // setOptions(filteredOptions);
    });
  }, [searchAccountValue, getAccountOptionsDelayed]);

  const getContactOptionsDelayed = useCallback(
    debounce(async (text) => {
      // try {
      if (text !== "") {
        const respOptions = await getCRMDetailsSearch(text);
        setContactNotFound(respOptions.length === 0);
        setSaveButtonDisable(false);
        setContactBackup(respOptions);
        setContactOptions(respOptions.map((key) => ({ name: key.ContactName, value: key.ContactId })));
      }
    }, CRM_SEARCH_DELAY),
    []
  );

  useEffect(() => {
    getContactOptionsDelayed(searchContactValue, () => {
      // setOptions(filteredOptions);
    });
  }, [searchContactValue, getContactOptionsDelayed]);

  const getOpportunityOptionsDelayed = useCallback(
    debounce(async (text) => {
      // try {
      if (text !== "") {
        const respOptions = await getCRMDetailsSearch(text);
        setOpporNotFound(respOptions.length === 0);
        setSaveButtonDisable(false);
        setOpportunityBackup(respOptions);
        setOppOptions(respOptions.map((key) => ({ name: key.OpportunityName, value: key.OpportunityId })));
      }
    }, CRM_SEARCH_DELAY),
    []
  );

  useEffect(() => {
    getOpportunityOptionsDelayed(searchOpportunityValue, () => {
      // setOptions(filteredOptions);
    });
  }, [searchOpportunityValue, getOpportunityOptionsDelayed]);

  const getLeadOptionsDelayed = useCallback(
    debounce(async (text) => {
      //try {
      if (text !== "") {
        const respOptions = await getCRMDetailsSearch(text);
        setLeadNotFound(respOptions.length === 0);
        setSaveButtonDisable(false);
        setLeadBackup(respOptions);
        setLeadOptions(respOptions.map((key) => ({ name: key.LeadName, value: key.LeadId })));
      }
    }, CRM_SEARCH_DELAY),
    []
  );

  useEffect(() => {
    getLeadOptionsDelayed(searchLeadValue, () => {
      // setOptions(filteredOptions);
    });
  }, [searchLeadValue, getLeadOptionsDelayed]);

  const onChangeHandle = async (value) => {
    setContactOptions([{ name: "", value: "" }]);
    setOppOptions([{ name: "", value: "" }]);
    const ClientKey = getClientKeyFromStateInfo();
    const payload = {
      ClientKey,
      CRMObject: "account",
      CRMSystem: getCRMSyncKeyword(),
      SearchString: value,
    };

    setSearchAccountValue(payload);
  };

  const onContactChangeHandler = async (value) => {
    let findAccount = {};

    if (accountBackup.length > 0) {
      findAccount = accountBackup.find((item) => item.AccountName === (crmDetails.AccountName || accountOptions[0].name));
    } else {
      if (accountOptions?.length === 1) {
        findAccount = { AccountName: accountOptions[0].name, AccountId: accountOptions[0].value };
      }
    }
    const ClientKey = getClientKeyFromStateInfo();
    const payload = {
      ClientKey,
      CRMObject: "contact",
      CRMSystem: getCRMSyncKeyword(),
      SearchString: value,
      AccountID: findAccount?.AccountId,
    };

    setSearchContactValue(payload);
  };

  const onLeadChangeHandler = async (value) => {
    // use the changed value to make request and then use the result. Which
    const ClientKey = getClientKeyFromStateInfo();
    const payload = {
      ClientKey,
      CRMObject: "lead",
      CRMSystem: getCRMSyncKeyword(),
      SearchString: value,
    };

    setSearchLeadValue(payload);
  };

  const onoppChangeHandler = async (value) => {
    // use the changed value to make request and then use the result. Which

    let findAccount = {};

    if (accountBackup.length > 0) {
      findAccount = accountBackup.find((item) => item.AccountName === crmDetails.AccountName);
    } else {
      if (accountOptions?.length === 1) {
        findAccount = { AccountName: accountOptions[0].name, AccountId: accountOptions[0].value };
      }
    }
    const ClientKey = getClientKeyFromStateInfo();
    const payload = {
      ClientKey,
      CRMObject: "opportunity",
      CRMSystem: getCRMSyncKeyword(),
      SearchString: value,
      AccountID: findAccount?.AccountId,
    };

    setSearchOpportunityValue(payload);
  };

  useEffect(() => {
    if (!openAccountDropDown) {
      setAccountOptions([]);
    }
  }, [openAccountDropDown]);

  useEffect(() => {
    if (!openContactDropdown) {
      setContactOptions([]);
    }
  }, [openContactDropdown]);

  useEffect(() => {
    if (!openLeadDropdown) {
      setLeadOptions([]);
    }
  }, [openLeadDropdown]);

  useEffect(() => {
    if (!openOpporDropdown) {
      setOppOptions([]);
    }
  }, [openOpporDropdown]);

  const fetchCRMSessionInfo = async () => {
    if (crmDetails) {
      const { SessionID = "", CallRecordingID = "" } = crmDetails;

      if (SessionID === "" || CallRecordingID === "") {
        try {
          const callRecordingID = get(props.stateInfo, "callMetadataInfo.data.CallRecordingID") || get(props.stateInfo, "rawData.id");
          if (callRecordingID) {
            let respSessionInfo = await getCallSessionID({ callRecordingID });
            if (JSON.stringify(respSessionInfo.data) !== "{}" && respSessionInfo?.success) {
              setCRMSessionInfo(respSessionInfo.data);
            }
          }
        } catch (err) {
          setErrorObject({
            isError: false,
            msgError: "Error: ",
          });
        }
      }
    }
  };

  const getDispositionInfo = async () => {
    try {
      const licenseID = get(props.stateInfo, "userInfo.goeLicense", "");
      const userKey = get(props.stateInfo, "userInfo.userKey", "");
      const providerName = "gspb";
      const dispositionData = await getDispositionData({ licenseID, userKey, providerName });
      const prepareDispositionData = dispositionData?.map((item) => ({ label: item.Description, value: item.dispositionKey }));
      setDispositionData(prepareDispositionData);
    } catch (err) {
      setErrorObject({
        isError: false,
        msgError: "Error: ",
      });
    }
  };

  const getContextsInfo = async () => {
    try {
      const licenseId = get(props.stateInfo, "userInfo.goeLicense", "");
      const clientKey = getClientKeyFromStateInfo();
      const officeKey = 0;
      const provider = "gspb";
      const contextsData = await getContextsData({ licenseId, clientKey, officeKey, provider });
      const prepareContextsData = contextsData?.map((item) => ({ label: item.Description, value: item.Context }));
      setContextsData(prepareContextsData);
    } catch (err) {
      setErrorObject({
        isError: false,
        msgError: "Error: ",
      });
    }
  };

  const getTopicsInfo = async (context = "") => {
    try {
      const license = get(props.stateInfo, "userInfo.goeLicense", "");
      const includeDisabled = false;
      const provider = "gspb";
      const callContext = context ? context : get(props.stateInfo, "callMetadataInfo.data.CallContext", "");
      const topicsData = await getTopicsData({ license, includeDisabled, provider, callContext });
      const prepareTopicsData = topicsData?.map((item) => ({ label: item.topic, value: item.topicId, context: item.contexts[0]?.context }));
      setTopicsData(prepareTopicsData);
    } catch (err) {
      setErrorObject({
        isError: false,
        msgError: "Error: ",
      });
    }
  };

  const handleDispositionChange = (event) => {
    const selDisposition = dispositionData?.find((item) => item.value === event.target.value);
    setSelectedDisposition({ ...selectedDisposition, dispostionValue: selDisposition.value, dispositionText: selDisposition.label });
    setSaveButtonDisable(false);
  };

  const handleContextChange = async (event) => {
    const selContext = contextsData?.find((item) => item.value === event.target.value);
    setSelectedContext({ ...selectedContext, context: selContext.value, description: selContext.label });
    setSelectedTopic({ ...selectedTopic, topicId: 0, topicName: "", topicContext: "" });
    if (selContext) {
      setAllowTopicChange(true);
      await getTopicsInfo(selContext.value);
    }
  };

  const handleTopicChange = (event) => {
    const selTopic = topicsData?.find((item) => item.value === event.target.value);
    setSelectedTopic({ ...selectedTopic, topicId: selTopic.value, topicName: selTopic.label, topicContext: selTopic.context });
    setSaveButtonDisable(false);
  };

  const fetchCallCRMDetails = async () => {
    try {
      const convID = get(props.stateInfo, "data.ConversationID", "");
      const callSource = get(props.stateInfo, "data.CallSource", "");
      if (convID !== "") {
        let respCRMDetails = await getCallCRMDetails({ convID, callSource });
        if (JSON.stringify(respCRMDetails.data) !== "{}" && respCRMDetails?.success) {
          const crmData = get(respCRMDetails, "data", {});
          setCRMDetails(crmData);
          let callNotesRes = get(crmData, "Notes", "");
          if (callNotesRes) {
            setCallNotesInfo(callNotesRes);
          }
        }

        if (JSON.stringify(respCRMDetails.data) === "{}") {
          fetchCRMSessionInfo();
          setCRMDetails({
            ...crmDetails,
            CallAccountName: "",
            CallOpportunity: "",
            CallContactName: "",
            CallLeadName: "",
          });
        }
      }
    } catch (err) {
      setErrorObject({
        isError: false,
        msgError: "Error: ",
      });
    }
  };

  const onEditTitleClickHandler = async (label, value, elementType) => {
    try {
      if (elementType === "fields" && label === "CRM Details") {
        setAccountOptions([{ name: crmDetails.CallAccountName, value: crmDetails.CallAccountLink }]);
        setOppOptions([{ name: crmDetails.CallOpportunity, value: crmDetails.CallOpportunityLink }]);
        setLeadOptions([{ name: crmDetails.CallLeadName, value: crmDetails.CallLeadLink }]);
        setContactOptions([{ name: crmDetails.CallContactName, value: crmDetails.CallContactLink }]);
      } else if (elementType === "fields" && label === "Call Details") {
        await getDispositionInfo();
        await getContextsInfo();
        await getTopicsInfo();
      }
      setEditContentData({ label, value, elementType });
      setOpen(true);
    } catch (e) {}
  };

  const contentItem = (isHeader, label, value, isEdit, elementType) => {
    const l = isHeader ? label : `${label}:`;
    return (
      <StyledContentContainer>
        <StyledSubTitle style={isHeader ? { fontWeight: "600" } : {}}>{`${l}`}</StyledSubTitle>
        {!isHeader && <StyledSubContent> {value}</StyledSubContent>}
        {isEdit && (
          <StyledIconWrapper onClick={() => (!isHeader ? null : onEditTitleClickHandler(label, value, elementType))}>
            <Edit color="primary" className={styles.editIconStyles} />
          </StyledIconWrapper>
        )}
      </StyledContentContainer>
    );
  };

  const onCloseModalHandler = (callingFromCancel) => {
    if (callingFromCancel && editContentData.elementType === "fields" && editContentData.label === "Call Details") {
      const selContext = contextsData?.find((item) => item.value === initialData.callContext);
      const selTopic = topicsData?.find((item) => item.value === initialData.topicId);
      const selDisposition = dispositionData?.find((item) => item.value === initialData.dispostionValue.slice(0, -1));
      if (selContext) {
        setSelectedContext({ ...selectedContext, context: selContext.value, description: selContext.label });
      } else {
        setSelectedContext({});
        setAllowTopicChange(false);
      }
      if (selTopic) {
        setSelectedTopic({ ...selectedTopic, topicId: selTopic.value, topicName: selTopic.label, topicContext: selTopic.context });
      } else {
        setSelectedTopic({});
      }
      if (selDisposition) {
        setSelectedDisposition({ ...selectedDisposition, dispostionValue: selDisposition.value, dispositionText: selDisposition.label });
      } else {
        setSelectedDisposition({});
      }
      setSaveButtonDisable(true);
    }
    setOpen(false);
    setDisableLead(false);
    setDisableOpportunity(true);
    setDisableContact(true);
    setDisableAccountName(false);
    setEditContentData({});
  };

  const validateCRMDetails = () => {
    // find from backup data
    const findAccount = accountBackup.find((item) => item.AccountName === crmDetails.AccountName);
    const findContact = contactBackup.find((item) => item.ContactName === crmDetails.ContactName);
    const findLead = leadBackup.find((item) => item.LeadName === crmDetails.LeadName);
    const findOpportunity = opportunityBackup.find((item) => item.OpportunityName === crmDetails.OpportunityName);

    const CallAccountName = get(crmDetails, "AccountName") || get(crmDetails, "CallAccountName");
    const CallAccountLink = get(findAccount, "AccountId") || get(crmDetails, "CallAccountLink");
    const CallContactName = get(crmDetails, "ContactName") || get(crmDetails, "CallContactName");
    const CallContactLink = get(findContact, "ContactId") || get(crmDetails, "CallContactLink");
    const CallLeadName = get(crmDetails, "LeadName") || get(crmDetails, "CallLeadName");
    const CallLeadLink = get(findLead, "LeadId") || get(crmDetails, "CallLeadLink");
    const CallOpportunity = get(crmDetails, "OpportunityName") || get(crmDetails, "CallOpportunity");
    const CallOpportunityLink = get(findOpportunity, "OpportunityId") || get(crmDetails, "CallOpportunityLink");

    if (has(crmDetails, "LeadName") && crmDetails.LeadName !== "") {
      return {
        CallLeadName,
        CallLeadLink,
        CallAccountName: "",
        CallAccountLink: "",
        CallContactName: "",
        CallContactLink: "",
        CallOpportunity: "",
        CallOpportunityLink: "",
      };
    }

    if (has(crmDetails, "AccountName") && crmDetails.AccountName !== "") {
      return {
        CallAccountName,
        CallAccountLink,
        CallContactName,
        CallContactLink,
        CallOpportunity,
        CallOpportunityLink,
        CallLeadName: "",
        CallLeadLink: "",
      };
    }

    if (has(crmDetails, "CallLeadName") && crmDetails.CallLeadName !== "") {
      return {
        CallLeadName,
        CallLeadLink,
        CallAccountName: "",
        CallAccountLink: "",
        CallContactName: "",
        CallContactLink: "",
        CallOpportunity: "",
        CallOpportunityLink: "",
      };
    }

    if (has(crmDetails, "CallAccountName") && crmDetails.CallAccountName !== "") {
      return {
        CallAccountName,
        CallAccountLink,
        CallContactName,
        CallContactLink,
        CallOpportunity,
        CallOpportunityLink,
        CallLeadName: "",
        CallLeadLink: "",
      };
    }
  };

  //common method to call /salesforce api incase of updateDisposition, CRMdetails and Notes
  const callSalesForceAPISync = async ({ CRMTaskID, CallLeadLink, CallContactLink, CallOpportunityLink, CallAccountLink, notes, ConversationID }) => {
    const CRMSyncEnabled = get(crmSyncDetails, "CRMSyncEnabled");

    if (CRMSyncEnabled && CRMTaskID !== "") {
      const ClientKey = getClientKeyFromStateInfo();

      const disposition = get(selectedDisposition, "dispostionValue");
      const callResult = get(selectedDisposition, "dispositionText");
      const whoId = CallLeadLink || CallContactLink;
      const whatId = CallLeadLink === "" ? CallOpportunityLink || CallAccountLink : "";

      const payload = {
        client: `${ClientKey}`,
        activityId: CRMTaskID,
        disposition,
        callResult,
        whoId,
        whatId,
        notes,
        convId: ConversationID,
      };

      await callSalesforceAPISync(payload);
    }
  };

  const onSaveCRMDetailsHandler = async () => {
    const { label = "", elementType = "" } = editContentData;
    const UserKey = getUserKeyFromSession();
    const CRMTaskID = get(crmDetails, "CRMTaskID", "");
    const ConversationID = get(crmDetails, "ConversationID");
    const CallContext = get(crmDetails, "CallContext") || get(props.stateInfo, "callMetadataInfo.data.CallContext", "");
    const TopicId = get(crmDetails, "TopicId") || get(props.stateInfo, "callMetadataInfo.data.TopicId", "");
    const ContactDataID = get(crmDetails, "ContactDataID", 2); //TODO
    const Number = get(crmDetails, "Number", "") || get(props.stateInfo, "callMetadataInfo.data.Number", "");
    const Notes = get(crmDetails, "Notes", "") || get(props.stateInfo, "callMetadataInfo.data.CallNotes", "");

    const CallRecordingID = get(crmDetails, "CallRecordingID") || get(crmSessionInfo, "CallRecordingID", "");

    // fields are representing to update the CRM details and sync with sales force api.
    if (elementType === "fields" && label === "CRM Details") {
      const {
        CallAccountName = "",
        CallAccountLink = "",
        CallContactName = "",
        CallContactLink = "",
        CallOpportunity = "",
        CallOpportunityLink = "",
        CallLeadName = "",
        CallLeadLink = "",
      } = validateCRMDetails();
      const SessionID = get(crmDetails, "SessionID") || get(crmSessionInfo, "SessionID", "");

      const CallContactEmail = get(crmDetails, "CallContactEmail", "");
      const CallContactLinkedInProfile = get(crmDetails, "CallContactLinkedInProfile", "");

      const payload = {
        ContactDataID,
        SessionID,
        ConversationID,
        UserKey,
        CallContext,
        CallAccountName,
        CallAccountLink,
        CallLeadName,
        CallLeadLink,
        CallContactName,
        CallContactLink,
        CallOpportunity,
        CallOpportunityLink,
        CallContactEmail,
        CallContactLinkedInProfile,
        Number,
        Notes,
        CRMTaskID,
        CallRecordingID,
        TopicId,
      };
      // try {
      callSalesForceAPISync({ CRMTaskID, CallLeadLink, CallContactLink, CallOpportunityLink, CallAccountLink, ConversationID, notes: callNotesInfo });

      await saveCRMCallDetails(payload);
      onCloseModalHandler();

      await fetchCallCRMDetails();
      return;
    }

    // fields are representing to update the Call details and sync with sales force api.
    if (elementType === "fields" && label === "Call Details") {
      try {
        // update the context and topic data
        let callContactDataPayload = {
          ...crmDetails,
          CRMTaskID,
          ConversationID,
          CallContext: selectedContext.context,
          TopicId: parseInt(selectedTopic.topicId),
          TopicName: selectedTopic.topicName,
          TopicContext: selectedTopic.topicContext,
        };

        // API call to save context and topic to gryphon DB
        const resSave = await saveCRMCallDetails(callContactDataPayload);

        // update the disposition data and sync with sales force api.
        callSalesForceAPISync({ ...crmDetails, CRMTaskID, ConversationID, notes: callNotesInfo });
        const CallRecordingID = get(props.stateInfo, "callMetadataInfo.data.CallRecordingID", "");
        const TableName = get(props.stateInfo, "callMetadataInfo.data.TableName", "");
        let respUpdateDisposition = {};
        if (CallRecordingID !== "" && selectedDisposition.dispostionValue !== "" && TableName !== "") {
          const dispositionPayload = {
            CallRecordingID,
            NewDispositionKey: parseInt(selectedDisposition.dispostionValue),
            OldDispositionKey: selectedDisposition.oldDispositionValue,
            UserKey,
            TableName,
          };

          respUpdateDisposition = await updateDispositionCall(dispositionPayload);
        }

        if (
          (JSON.stringify(resSave.data) !== "{}" && resSave?.data?.retCode === 0) ||
          (JSON.stringify(respUpdateDisposition.data) !== "{}" && respUpdateDisposition?.success)
        ) {
          const getLatestCallMetaData = await getCallMetadata({
            convID: ConversationID,
          });
          const ActionText = get(getLatestCallMetaData, "data.ActionText");
          props.setParentState({
            callMetadataInfo: getLatestCallMetaData,
          });
          setInitialData({ ...initialData, dispostion: ActionText });
        }

        onCloseModalHandler();
        setCallDetailsError({
          isError: false,
          msgError: "",
        });

        return;
      } catch (e) {
        props.handleOpenSnack(true, "ERR: Failed to save call details");
        return;
      }
    }

    const CallAccountLink = get(validateCRMDetails(), "CallAccountLink", "");
    const CallContactLink = get(validateCRMDetails(), "CallContactLink", "");
    const CallOpportunityLink = get(validateCRMDetails(), "CallOpportunityLink", "");
    const CallLeadLink = get(validateCRMDetails(), "CallLeadLink", "");
    // Update notes
    const payload = {
      ...crmDetails,
      UserKey,
      Notes: editContentData.value,
      ConversationID,
    };

    callSalesForceAPISync({
      CRMTaskID,
      CallLeadLink,
      CallContactLink,
      CallOpportunityLink,
      CallAccountLink,
      notes: editContentData.value,
      ConversationID,
    });

    await saveCRMCallDetails(payload);

    onCloseModalHandler();
    await fetchCallCRMDetails();
    setNotesError({
      isError: false,
      msgError: "",
    });
    return;
  };

  const [selectedCRMDetails] = useState({
    accountName: "",
    lead: "",
    opportunity: "",
    contact: "",
  });

  const onNotesChangeHandler = (event) => {
    setSaveButtonDisable(false);
    setEditContentData({ ...editContentData, value: event.target.value });
  };

  useEffect(() => {
    if (selectedCRMDetails?.accountName !== "") {
      setDisableContact(true);
      setDisableLead(true);
      setDisableOpportunity(true);
    } else {
      setDisableLead(false);
    }
  }, [selectedCRMDetails]);

  const renderModalContent = () => {
    const { label, elementType } = editContentData;
    const isVideo = get(props.stateInfo, "rawData.fileContent.MediaType") === "video";
    const TableName = get(props.stateInfo, "callMetadataInfo.data.TableName", "");
    if (elementType === "fields" && label === "Call Details") {
      return (
        <StyledCRMFieldsWrapper>
          {callDetailsError.isError && <Alert severity="error">{callDetailsError.msgError}</Alert>}
          <StyledTitleTypography> Edit Call details </StyledTitleTypography>
          <FormControl variant="outlined" className={styles.formControlCallDetails}>
            <InputLabel id="demo-simple-select-label">Context</InputLabel>
            <Select
              value={selectedContext.context}
              label="Context"
              onChange={handleContextChange}
              MenuProps={{
                classes: {
                  paper: styles.menuPaper,
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {contextsData.length > 0 ? (
                contextsData?.map((item) => (
                  <MenuItem value={item.value} key={item.value} name={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value="">
                  <em>None</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={styles.formControlCallDetails}>
            <InputLabel id="demo-simple-select-label">Topic</InputLabel>
            <Select
              disabled={!allowTopicChange}
              value={selectedTopic.topicId}
              label="Topic"
              onChange={handleTopicChange}
              MenuProps={{
                classes: {
                  paper: styles.menuPaper,
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {topicsData.length > 0 ? (
                topicsData?.map((item) => (
                  <MenuItem value={item.value} key={item.value} name={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value="">
                  <em>None</em>
                </MenuItem>
              )}
            </Select>
          </FormControl>
          {!isVideo && TableName.length !== 0 ? (
            <FormControl variant="outlined" className={styles.formControlCallDetails}>
              <InputLabel id="demo-simple-select-label">Disposition</InputLabel>
              <Select
                value={selectedDisposition.dispostionValue}
                label="Disposition"
                onChange={handleDispositionChange}
                MenuProps={{
                  classes: {
                    paper: styles.menuPaper,
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {dispositionData.length > 0 ? (
                  dispositionData?.map((item) => (
                    <MenuItem value={item.value} key={item.value} name={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value="">
                    <em>None</em>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          ) : undefined}
        </StyledCRMFieldsWrapper>
      );
    } else if (elementType === "fields" && label === "CRM Details") {
      return (
        <StyledCRMFieldsWrapper>
          {crmDetailsError.isError && <Alert severity="error">{crmDetailsError.msgError}</Alert>}
          <StyledTitleTypography> Edit CRM details </StyledTitleTypography>
          <Autocomplete
            id="account-name"
            style={{ width: 300, borderRadius: "10px" }}
            open={openAccountDropDown}
            defaultValue={accountOptions[0] !== "" && accountOptions[0]}
            onOpen={() => {
              setOpenAccountDropdown(true);
            }}
            onClose={() => {
              setOpenAccountDropdown(false);
            }}
            disabled={disableAccountName}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={accountOptions}
            loading={accountOptionsloading && accountNotFound !== true}
            onInputChange={(event, value) => onAccountChangeHandler(event, value, "AccountName")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Account Name"
                variant="outlined"
                onChange={(ev) => {
                  if (ev.target.value !== "" || ev.target.value !== null) {
                    onChangeHandle(ev.target.value);
                  }
                }}
                style={{ borderRadius: "10px" }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {accountOptionsloading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <Autocomplete
            id="opp"
            sx={{ width: 300 }}
            open={openOpporDropdown}
            defaultValue={oppOptions[0] !== "" && oppOptions[0]}
            onOpen={() => {
              setOpporDropdown(true);
            }}
            onClose={() => {
              setOpporDropdown(false);
            }}
            disabled={disableOpportunity}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={oppOptions}
            loading={oppOptionsloading && opporNotFound !== true}
            onInputChange={(event, value) => onAccountChangeHandler(event, value, "OpportunityName")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Opportunity"
                variant="outlined"
                onChange={(ev) => {
                  if (ev.target.value !== "" || ev.target.value !== null) {
                    onoppChangeHandler(ev.target.value);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {leadOptionsloading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />

          <Autocomplete
            id="contact"
            sx={{ width: 300 }}
            open={openContactDropdown}
            defaultValue={contactOptions[0] !== "" && contactOptions[0]}
            onOpen={() => {
              setContactDropdown(true);
            }}
            disabled={disableContact}
            onClose={() => {
              setContactDropdown(false);
            }}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={contactOptions}
            loading={contactOptionsloading && contactNotFound !== true}
            onInputChange={(event, value) => onAccountChangeHandler(event, value, "ContactName")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Contact"
                variant="outlined"
                onChange={(ev) => {
                  if (ev.target.value !== "" || ev.target.value !== null) {
                    onContactChangeHandler(ev.target.value);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {contactOptionsloading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <Autocomplete
            id="lead"
            sx={{ width: 300 }}
            open={openLeadDropdown}
            defaultValue={leadOptions[0] !== "" && leadOptions[0]}
            onOpen={() => {
              setLeadDropdown(true);
            }}
            onClose={() => {
              setLeadDropdown(false);
            }}
            disabled={disableLead}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={leadOptions}
            loading={leadOptionsloading && leadNotFound !== true}
            onInputChange={(event, value) => onAccountChangeHandler(event, value, "LeadName")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Lead"
                variant="outlined"
                onChange={(ev) => {
                  if (ev.target.value !== "" || ev.target.value !== null) {
                    onLeadChangeHandler(ev.target.value);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {leadOptionsloading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </StyledCRMFieldsWrapper>
      );
    }

    return (
      <StyledNotesWrapper>
        {notesError.isError && <Alert severity="error">{notesError.msgError}</Alert>}
        <StyledTextField
          label={editContentData.label}
          value={editContentData.value}
          placeholder="Notes"
          variant="outlined"
          onChange={onNotesChangeHandler}
          multiline
          inputProps={{ maxLength: 32000 }}
          InputProps={{
            classes: {
              notchedOutline: {
                borderColor: "red !important",
              },
            },
          }}
        />
      </StyledNotesWrapper>
    );
  };

  const CRMSyncEnabled = get(crmSyncDetails, "CRMSyncEnabled");

  return (
    <StyledGridMainWrapper container>
      {errorObject.isError && <div>{errorObject.msgError}</div>}
      {callDetailsError.isError && <Alert severity="error">{callDetailsError.msgError}</Alert>}
      {props.externalAccess ? (
        <ParticipantsPanel {...props}></ParticipantsPanel>
      ) : (
        <>
          <ParticipantsPanel {...props}></ParticipantsPanel>
          <StyledDivider />
          <StyledGridItem>
            {contentItem(true, "Call Details", "", defaultEditButtonVisible(), "fields")}
            {contentItem(false, "Context", initialData.callContextDescription)}
            {contentItem(false, "Topic", initialData.topicName)}
            {contentItem(false, "Date/Time", initialData.currentModelDeployedSeconds)}
            {contentItem(false, "Number", initialData.number)}
            {contentItem(false, "Disposition", initialData.dispostion)}
          </StyledGridItem>
          {CRMSyncEnabled !== 0 &&
            getClientKeyFromStateInfo() !== 0 && ( //To hide CRM details on CRMsync is disable
              <>
                <StyledDivider />
                <StyledGridItem>
                  {contentItem(true, "CRM Details", "", defaultEditButtonVisible(), "fields")}
                  {contentItem(false, "Account", crmDetails?.CallAccountName)}
                  {contentItem(false, "Opportunity", crmDetails?.CallOpportunity)}
                  {contentItem(false, "Contact", crmDetails.CallContactName)}
                  {contentItem(false, "Lead", crmDetails?.CallLeadName)}
                </StyledGridItem>
              </>
            )}
          <StyledDivider />
          <StyledGridItem>
            {contentItem(true, "Notes", callNotesInfo, defaultEditButtonVisible(), "textArea")}
            <StyledNotes>{callNotesInfo}</StyledNotes>
          </StyledGridItem>
          <StyledModalContainer open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <>
              <Box sx={editContentData.elementType === "textArea" ? { ...style, ...notesStyles } : style}>
                {renderModalContent(disableCRMFields)}

                <StyledButtonContainer container>
                  <StyledSaveButton variant="outlined" onClick={onSaveCRMDetailsHandler} disabled={isSaveButtonDisable}>
                    Save
                  </StyledSaveButton>
                  <StyledCancelButton variant="text" onClick={() => onCloseModalHandler(true)}>
                    Cancel
                  </StyledCancelButton>
                </StyledButtonContainer>
              </Box>
            </>
          </StyledModalContainer>
        </>
      )}
    </StyledGridMainWrapper>
  );
};

export default DetailsPanel;

const StyledModalContainer = withStyles({
  root: {
    display: "flex",
    alignItems: "center",
  },
})(Modal);

const StyledTitleTypography = withStyles({
  root: {
    color: "#1971C2",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Inter",
    marginBottom: "20px",
  },
})(Typography);

const StyledGridMainWrapper = withStyles({
  root: {
    paddingLeft: "12px",
    display: "flex",
    flexDirection: "column",
  },
})(Box);

const StyledGridItem = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    "& > :not(:last-child)": {
      paddingBottom: "0.75rem",
    },
    "& > *": {
      paddingBottom: "0rem",
    },
  },
})(Box);

const StyledContentContainer = withStyles({
  root: {
    display: "flex",
    alignItems: "baseline",
  },
})(Box);

const StyledCRMFieldsWrapper = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",

    "& .MuiAutocomplete-root": {
      marginBottom: "1rem",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
    },
  },
})(Box);

const StyledTextField = withStyles({
  root: {
    color: "#1971C2",
    borderRadius: "10px",
    borderColor: "#1971C2",
    width: "100%",
    height: "7rem",

    "& .MuiOutlinedInput-root": {
      height: "100%",
      borderRadius: "10px",
      paddingTop: "10px !important",
      paddingLeft: "10px !important",
      paddingRight: "15px !important",
    },

    "& .MuiOutlinedInput-input": {
      paddingLeft: "0 !imporant",
      paddingRight: "0 !imporant",
      paddingBottom: "0 !imporant",
    },

    "& .MuiOutlinedInput-inputMultiline": {
      overflowY: "scroll !important",
      wordBreak: "break-all !important",
      wordWrap: "inherit !important",
      height: "inherit !important",
      "& textarea": {
        wordBreak: "break-all !important",
        wordWrap: "inherit !important",
        height: "inherit !important",
      },
    },

    "& textarea": {
      wordBreak: "break-all !important",
      wordWrap: "inherit !important",
      height: "inherit !important",
    },
  },
})(TextField);

const StyledSubTitle = withStyles({
  root: {
    color: "#495057",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1rem",
    fontFamily: "Inter",
  },
})(Typography);

const StyledSubContent = withStyles({
  root: {
    color: "#495057",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1rem",
    fontFamily: "Inter",
    paddingLeft: "3px",
  },
})(Typography);

const StyledNotes = withStyles({
  root: {
    color: "#495057",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1rem",
    fontFamily: "Inter",
    textAlign: "left",
  },
})(Typography);

const StyledDivider = withStyles({
  root: {
    color: "#495057",
    marginBottom: "1rem",
    marginTop: "1rem",
  },
})(Divider);

const StyledIconWrapper = withStyles({
  root: {
    paddingLeft: "5px",
    marginTop: "-13px", //TODO: Need to check proper alignment
    cursor: "pointer",
  },
})(Box);

const StyledButtonContainer = withStyles({
  root: {
    display: "flex",
    marginTop: "1rem",
    width: "100%",
    justifyContent: "center",
  },
})(Grid);

const StyledSaveButton = withStyles({
  root: {
    boxShadow: "none !important",
    width: "100px",
    borderRadius: "10px",
    color: "#1971C2",
    textTransform: "none",
    border: "1px solid #1971C2",
    marginRight: "1rem",
    fontSize: "14px",

    "&:disabled": {
      color: "lightgrey",
    },

    "&:hover": {
      color: "#1971C2",
      backgroundColor: "#e7f5ff",
    },
  },
})(Button);

const StyledCancelButton = withStyles({
  root: {
    color: "#1971C2",
    backgroundColor: "#ffffff",
    fontSize: "14px",
    textTransform: "none",
    borderRadius: "10px",
    width: "6rem",

    "&:hover": {
      color: "#1971C2",
      backgroundColor: "#e7f5ff",
    },
  },
})(Button);

const StyledNotesWrapper = withStyles({
  height: "6rem",
  width: "200px",
})(Box);
