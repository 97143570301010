import React, { createRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import PropTypes from "prop-types";
import "react-h5-audio-player/lib/styles.css";
import getFileById from "../../services/postCallService";
import { getUtteranceFlagsSummary } from "../../services/utteranceFlagService";
import getCRMSyncInfo from "../../services/callCRMSyncService";
import getCallMetadata from "../../services/callMetadataService";
import ToggleButton from "@material-ui/lab/ToggleButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import "./NewInsights.css";
import * as constants from "../../assets/constants";
import * as cookieUtils from "../../assets/cookie-utils";
import AnimatedMulti from "../../assets/components/selector/selector";
import Forward10Icon from "@material-ui/icons/Forward10";
import Replay10Icon from "@material-ui/icons/Replay10";
import CallActionsDock from "../call-actions-dock/CallActionsDock";
import Stack from "@mui/material/Stack";
import StyledToggleButtonGroup from "../../assets/components/StyledToggleButtonGroup";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import PlaylistPanel from "../playlist-panel/PlaylistPanel";
import queryString from "query-string";
import CallTranscript from "../call-transcript/CallTranscript";
import FauxVideoView from "../faux-video-view/FauxVideoView";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CustomError from "../../helper/CustomError/CustomError";
import AudioPlaylistPanel from "../playlist-panel/AudioPlaylistPanel";
import VideoView from "../video-view/VideoView";
import SentimentGraph from "../sentiment-graph/SentimentGraph";
import SearchIcon from "@mui/icons-material/Search";
import DetailsPanel from "../details/DetailsPanel";
import getContextsData from "../../services/getContextsService";
import CallInsightsPanel from "../call-insights-panel/CallInsightsPanel";
import Alert from "@material-ui/lab/Alert";

class NewInsights extends React.Component {
  isCallRecordingOff = null; // this is a flag which we will get from file's contents, if NO it indicates the recording for that file might be available(we need to check as per retention policy), and if set YES then it indicates that recording is not available as per client requirements.
  queryStr = null;
  fileId = null;
  snippetStartTime = 0;
  snippetEndTime = 0;
  chartData = null;
  audio = null;
  main = null;
  scrollNow = 0;
  timer = 0;
  oldSecond = 0;
  rowIdx = 0;
  sentimentScore = 0;
  duration = 0;
  rowAutoIdx = 0;
  seriesRange = null;
  valueRange = null;
  range = {};
  sentences = [];
  rangeDate = 0;
  speed = 1;
  pauseNotOver = true;
  isEndTimePresentInQueryString = false;
  refreshIntervalId = null;
  isMeddic = false;
  agentEmail = "agent@gryphon.com";
  isEdit = false;
  timerObj = 0;
  inputRegex = constants.REGEX_MMSS;
  reg = null;
  nextSentenceAt = 0.1;
  sentimentScoreDivCSS = ["#FFDE68", "#947300", "#C09602"]; // initializing background, text, border with neutral sentiment color scheme;
  durationDivCSS = ["#B6E1FA", "#1077a8", "#1390ca"];
  optionsForAgentTagged = [];
  optionsForCustomerTagged = [];
  optionsForMeddicTagged = [];
  initialLoading = true;
  video = null;
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.queryStringObj = queryString.parse(window.location.search);
    this.fileId = constants.extractFileId(props.match.params.fileId);
    this.isEndTimePresentInQueryString = constants.containsParameter(this.queryStringObj, constants.END_TIME_PARAM);
    this.snippetStartTime = constants.extractParameterByType(this.queryStringObj, constants.START_TIME_PARAM, "int");
    this.snippetEndTime = constants.extractParameterByType(this.queryStringObj, constants.END_TIME_PARAM, "int");
    this.isMeddic = constants.extractParameterByType(this.queryStringObj, constants.MEDDIC_PARAM, "bool");
    this.isEdit = constants.extractParameterByType(this.queryStringObj, constants.EDIT_PARAM, "bool");
    this.agentEmail = constants.extractAgentEmail(this.queryStringObj, constants.USEREMAIL_PARAM);
    this.audio = createRef();
    this.video = createRef();
    this.graph = createRef();
    this.state = this.initialState = {
      errResponse: {
        err: false,
        errCode: 0,
        errMessage: "DEFAULT_MSG",
        errSourceURL: window.location.href,
      },
      rawData: {},
      isRunning: false,
      timeElapsed: 0,
      scrollToIndex: 0,
      data: null,
      duration: 0,
      audioURL: null,
      fieldID: props.location.pathname,
      sentences: [],
      alignment: 0,
      isLongCall: false,
      isSnippetCall: false,
      lastTrackedRange: 0,
      startDragRange: {},
      endDragRange: {},
      dragStartTime: 0,
      dragEndTime: 0,
      isSnippetOpen: false,
      optionsForAgent: ["optionA", "optionB"],
      optionsForCustomer: ["optionC", "optionD"],
      optionsForMeddic: [],
      showModal: false,
      fileUnderProcessing: false,
      isAdmin: false,
      id: "file",
      fileType: "wav",
      isVideoFile: false,
      tabPanelVal: "2",
      filteredTranscript: [],
      userInfo: { userKey: 0 },
      utteranceFlags: [],
      utteranceFlagsSelected: [],
      playlistIndex: 0,
      callMetadataInfo: {
        UserType: "",
        ClientKey: 0,
        FirstName: "Gryphon",
        LastName: "User",
        EmailAddress: "",
        Status: "",
        Role: "",
        Office: "",
        CallAccountName: "",
        CallContactName: "",
        CallOpportunity: "",
        FollowUpKey: "",
        Action: "",
        ActionText: "None",
        DialedNumber: "0",
        DialingNumber: "0",
        Number: "0",
      },
      crmSyncDetails: {},
      openSnack: false,
      msgSnack: "",
      audioIndex: -1,
      isAudioPlayingInPlaylist: false,
      isPlaylistClick: false,
      noTranscript: false,
      showSearchBox: false,
      externalAccess: false,
      kpiInfos: { info: [] },
      contextDataInfos: [],
      callSummary: null,
      errorObject: {
        isError: false,
        msgError: "",
      },
    };
    this.setState = this.setState.bind(this);
  }

  async componentDidMount() {
    // GET USER INFO
    try {
      let activeUser = await cookieUtils.fetchActiveUserSession();
      this.setState({
        userInfo: activeUser,
        externalAccess: activeUser.isExternalToken,
      });
      // let activeUser = JSON.parse(userCookie);
      if (activeUser.isExternalToken) {
        this.isEdit = false;
        this.isMeddic = false;
        this.agentEmail = "";
        localStorage.setItem("user", this.agentEmail);
        this.setState({
          isAdmin: false,
          tabPanelVal: "1",
        });
      } else {
        this.isEdit = true;
        this.agentEmail = activeUser.emailAddress;
        localStorage.setItem("user", this.agentEmail);
        if (activeUser.isAdmin) {
          this.setState({
            isAdmin: true,
          });
        }
      }
    } catch (err) {
      //
    }

    // GET FILE BY ID
    try {
      await getFileById("/" + this.fileId, this.state.userInfo.userKey, this.state.userInfo.clientKey, this.state.externalAccess).then((res) => {
        this.isCallRecordingOff = res?.data?.fileContent?.NO_RECORDING; // read CALL_RECORDING_OFF field to check if recording is available for that call or not
        let isVideoCheck = res?.data?.fileContent?.MediaType === "video" ? true : false; // check if it's a video file
        let snipCheck = this.fileId.includes("_snip_");
        let isOldFile = res.data.fileContent.Processing_status === undefined;
        this.isEdit = snipCheck ? false : this.isEdit; // turn off edit for snip calls
        if (snipCheck) {
          this.setState({ isAdmin: false });
          if (isVideoCheck) {
            this.snippetStartTime = res.data.fileContent?.SnippetInfo?.sourceStartTime;
            if (res.data.fileContent?.SnippetInfo?.sourceEndTime) {
              this.isEndTimePresentInQueryString = true;
              this.snippetEndTime = res.data.fileContent?.SnippetInfo?.sourceEndTime;
            }
            if (res.data.fileContent?.SnippetInfo?.isAudio) {
              this.setIsAudio(true);
            }
          }
        } // default to agent off edit for snip calls
        let mAudioURL = res.data.fileContent.BucketURL;
        let fileTypeCheck = mAudioURL ? constants.getFileType(res.data.fileContent.BucketURL) : "undefined";
        // Specify start and end time (if present) to audio URL in the query param +st_ and +et_ (Safari fix)
        if (parseInt(this.snippetStartTime) > 0 && mAudioURL) {
          mAudioURL = this.isEndTimePresentInQueryString
            ? res.data.fileContent.BucketURL + "#t=" + this.snippetStartTime + "," + this.snippetEndTime
            : res.data.fileContent.BucketURL + "#t=" + this.snippetStartTime;
        }
        if (res.data.fileContent.sentences.length === 0) {
          res.data.fileContent.sentences.push(constants.DEFAULT_NO_TRANSCRIPT_OBJECT);
          this.setState({ noTranscript: true });
        }
        let sentencesArr = res.data.fileContent.sentences;

        let dataOfCurrentplaylistIndex = res.data.fileContent.sentences;
        if (isVideoCheck) {
          dataOfCurrentplaylistIndex = dataOfCurrentplaylistIndex.filter((sentence) => sentence.MediaIndex === this.state.playlistIndex);
          sentencesArr = dataOfCurrentplaylistIndex;
        }
        let processingComplete =
          snipCheck || isOldFile ? true : res.data.fileContent.Processing_status?.toUpperCase() === constants.PROCESSING_COMPLETE.toUpperCase();
        this.setMediaUrl(mAudioURL);
        this.setState({
          isVideoFile: isVideoCheck,
          rawData: res.data,
          id: res.data.id,
          data: res.data.fileContent,
          duration: res.data.fileContent.Duration,
          dragEndTime: res.data.fileContent.Duration,
          audioURL: mAudioURL,
          alignment: 1,
          isSnippetCall: snipCheck,
          fileType: fileTypeCheck,
          filteredTranscript: sentencesArr,
          processingComplete: processingComplete,
        });
        if (isVideoCheck) {
          this.setState({
            dragStartTime: 0,
            dragEndTime:
              res.data.fileContent?.MediaURL?.length > 1 ? sentencesArr[sentencesArr.length - 1]?.EndTime?.second : res.data.fileContent.Duration,
          });
        }
        this.duration = res.data.fileContent.Duration;
        this.sentimentScoreDivCSS = res.data.fileContent.OverallSentiment
          ? constants.sentimentScoreDivColor(res.data.fileContent.OverallSentiment.toFixed(1), this.sentimentScoreDivCSS)
          : this.sentimentScoreDivCSS;
        if (res.data.optionsForCustomer && res.data.optionsForAgent) {
          // to change the KPIs in select for Agent and Customer, We just need to edit them in DB.
          this.setState({
            optionsForAgent: res.data.optionsForAgent,
            optionsForCustomer: res.data.optionsForCustomer,
            optionsForMeddic: res.data.optionsForMeddic,
          });
        }
        if (isVideoCheck && !res.data.fileContent?.SnippetInfo?.isAudio) {
          this.audio.current.audio.current.muted = true;
        }
        // KPI INFOS
        if (res.data.clientConfig) {
          if (res.data.clientConfig.config) {
            this.setState({
              kpiInfos: res.data.clientConfig.config,
            });
          }
        }
        //  STORE CALL SUMMARY INTO STATE
        if (res.data.fileContent.call_summary && !snipCheck) {
          this.setState({
            callSummary: res.data.fileContent.call_summary,
          });
        }
      });

      if (!this.state.externalAccess) {
        // GET Utterance Flag Summary
        this.refreshUtteranceFlags();
        // GET CallMetaInfo
        this.getCallMetadataInfo();

        // GET Context Data
        this.getContextsInfo();

        // GET CRM SYNC INFO
        this.getCRMSyncDetails();
      }

      if (this.state.data) {
        let actualFileDuration = constants.getActualFileDuration(this.state.data);
        if (actualFileDuration > 3600) {
          this.setState({
            isLongCall: true,
          });
          this.inputRegex = constants.REGEX_HHMMSS;
        }
        // deepcode ignore reDOS: <please specify a reason of ignoring this>
        this.reg = new RegExp(this.inputRegex);
        let rowData = constants.transformForSentenceTableData(this.state?.data?.sentences, this.duration, this.state?.data?.Participants);
        this.setState({ sentences: rowData });
        this.startTimesArr = constants.getStartTimes(this.state.isVideoFile ? this.state.filteredTranscript : this.state.data.sentences);
        let taggedOptionsObject = constants.getTaggedOptionsObject(this.state.data.sentences);
        this.optionsForAgentTagged = taggedOptionsObject.salesKPIs;
        this.optionsForCustomerTagged = taggedOptionsObject.customerKPIs;
        this.optionsForMeddicTagged = taggedOptionsObject.meddicKPIs;

        // all used KPIs selected to show on graph
        this.setState({
          kpisSelectedToShowOnGraph: this.optionsForAgentTagged,
          meddicKpisSelectedToShowOnGraph: this.optionsForMeddicTagged,
          customerKpisSelectedToShowOnGraph: this.optionsForCustomerTagged,
        });
        if (this.state.isVideoFile) {
          this.setState({
            playlistIndex: 0,
          });
          let mVideoURL = this.state.data?.BucketURL;
          if (parseInt(this.snippetStartTime) > 0 && mVideoURL) {
            mVideoURL = this.isEndTimePresentInQueryString
              ? this.state.data?.BucketURL + "#t=" + this.snippetStartTime + "," + this.snippetEndTime
              : this.state.data?.BucketURL + "#t=" + this.snippetStartTime;
          }
          this.setMediaUrl(mVideoURL);
        }
      }

      if (this.snippetStartTime > 0 && !this.state.isVideoFile) {
        this.seekFromGraph(this.snippetStartTime);
      }
    } catch (err) {
      let errResponseObj = {
        err: true,
        errCode: err.errCode,
        errMessage: err.errMessage,
        errSourceURL: window.location.href,
      };
      this.setState({
        errResponse: errResponseObj,
      });
    }
  }

  refreshUtteranceFlags = async () => {
    // GET Utterance Flag Summary
    let utteranceFlagsReq = {
      clientKey: this.state.data?.ClientKey ? this.state.data?.ClientKey : 0,
      convID: this.state.data?.ConversationID ? this.state.data.ConversationID : 0,
    };
    await getUtteranceFlagsSummary(utteranceFlagsReq).then((res) => {
      if (res.data != null) {
        let revisedUtterenceFlagsSelected = res.data.utteranceFlagsSelected;
        if (this.state?.isSnippetCall) {
          let snippetStart = this.state.data?.SnippetInfo?.sourceStartTime;
          let snippetEnd = this.state.data?.SnippetInfo?.sourceEndTime;
          revisedUtterenceFlagsSelected = res.data.utteranceFlagsSelected
            .filter((flag) => flag.StartTime >= snippetStart && flag.StartTime <= snippetEnd)
            .map((flag) => {
              flag.revisedStartTime = Math.floor(
                this.state.data?.sentences?.find((sentence) => sentence.MessageID === flag.MessageID).StartTime.second
              );
              return flag;
            });
        }
        this.setState({
          utteranceFlags: res.data.utteranceFlags,
          utteranceFlagsSelected: revisedUtterenceFlagsSelected,
        });
      }
    });
  };

  getCallMetadataInfo = async () => {
    // GET CallMetadata
    const convID = this.state.data?.ConversationID ? this.state.data.ConversationID : "";
    const clientKey = this.state.data?.ClientKey ? this.state.data.ClientKey : "";
    const callSource = this.state.data?.CallSource ? this.state.data.CallSource : "";
    let callMetadataInfo = await getCallMetadata({
      convID: convID,
      callSource: callSource,
      clientKey: clientKey,
    });
    if (callMetadataInfo.data != null) {
      this.setState({
        callMetadataInfo: callMetadataInfo,
      });
    }
  };

  // for details panel - added for fetching initial context descritpion
  getContextsInfo = async () => {
    try {
      const licenseId = this.state.userInfo.goeLicense ? this.state.userInfo.goeLicense : "";
      const clientKey = this.state.data?.ClientKey ? this.state.data.ClientKey : "";
      const officeKey = 0;
      const provider = "gspb";
      const contextsData = await getContextsData({ licenseId, clientKey, officeKey, provider });
      const prepareContextsData = contextsData?.map((item) => ({ label: item.Description, value: item.Context }));
      this.setState({
        contextDataInfos: prepareContextsData,
      });
    } catch (err) {}
  };

  getCRMSyncDetails = async () => {
    try {
      const clientKey = this.state.data?.ClientKey ? this.state.data.ClientKey : "";
      if (clientKey !== "") {
        const getCRMSyncURL = await getCRMSyncInfo({ clientKey });
        if (JSON.stringify(getCRMSyncURL.data) !== "{}") {
          this.setState({
            crmSyncDetails: getCRMSyncURL.data,
          });
        }
      }
    } catch (err) {}
  };

  setSentences = (object) => {
    let latestData = this.state.data;
    latestData.sentences = object;
    let taggedOptionsObject = constants.getTaggedOptionsObject(object);
    this.optionsForAgentTagged = taggedOptionsObject.salesKPIs;
    this.optionsForCustomerTagged = taggedOptionsObject.customerKPIs;
    this.optionsForMeddicTagged = taggedOptionsObject.meddicKPIs;

    // For updating Key Moment in FoaxVideoView
    const rowData = constants.transformForSentenceTableData(object, this.duration);

    this.setState({
      data: latestData,
      sentences: rowData,
    });
  };

  setIsAudio = (isAudioPlayingInPlaylist) => {
    this.setState({ isAudioPlayingInPlaylist: isAudioPlayingInPlaylist });
  };

  muteAudio = (isMute = true) => {
    if (this.state.isVideoFile) {
      this.audio.current.audio.current.muted = isMute;
    }
  };

  setindexAudio = (index) => {
    this.setState({ audioIndex: index });
  };

  setMediaUrl = async (url) => {
    try {
      this.setState({
        audioURL: url,
      });
    } catch (e) {}
  };

  setSentencesVideoFile = (filteredList) => {
    // For updating Key Moment in FoaxVideoView
    const rowData = constants.transformForSentenceTableData(filteredList, this.duration);
    this.setState({
      filteredTranscript: filteredList,
      sentences: rowData,
    });
  };

  setGetStartTime = (playlistIndexChange) => {
    return this.state.data.sentences.filter((sentence) => sentence.MediaIndex === playlistIndexChange);
  };

  //this function set a flag whenever user click from the playlist.
  setIsplaylistClick = (playlistClick) => {
    this.setState({
      isPlaylistClick: playlistClick,
    });
  };

  // when user change playlistIndex from video playlist.
  changePlaylistIndexFromVideo = (videoPlaylistIndex) => {
    this.setState({
      playlistIndex: videoPlaylistIndex,
    });
  };

  // when user change playlistIndex from audio Playlist
  changePlaylistIndexFromAudio = (audioPlaylistIndex) => {
    let getStartTimeArray = this.setGetStartTime(audioPlaylistIndex);
    this.startTimesArr = constants.getStartTimes(getStartTimeArray);

    let filteredSentence = this.state.data.sentences.filter((sentence) => sentence.MediaIndex === audioPlaylistIndex);
    // For updating Key Moment in FoaxVideoView
    const rowData = constants.transformForSentenceTableData(filteredSentence, this.duration);
    this.seekOnGraph(this.startTimesArr[0]);
    this.setState({
      playlistIndex: audioPlaylistIndex,
      sentences: rowData,
      dragStartTime: this.startTimesArr[0],
    });
  };

  playlistIndexChange = (playlistIndexChange) => {
    let getStartTimeArray = this.setGetStartTime(playlistIndexChange);
    this.startTimesArr = constants.getStartTimes(getStartTimeArray);
    this.setState(
      {
        isPlaylistClick: true,
        playlistIndex: playlistIndexChange,
      },
      () => {
        this.seekFromGraph(0);
        this.setIsAudio(false);
        this.muteAudio(true);
        this.setMediaUrl(this.state.data.MediaURL[playlistIndexChange].BucketURL);
        if (this.video.player && this.video.player.player_) {
          if (this.video.player.playlist()[playlistIndexChange].sources[0].src !== "") {
            this.video.player.playlist.currentItem(playlistIndexChange);
          } else {
            this.video.player.playlist.currentItem(playlistIndexChange);
          }
        }
      }
    );
  };

  handleKPIChangeForGraphSelector = (event, name) => {
    this.setKPIsToShowOnGraph(name, event);
  };

  setKPIsToShowOnGraph = (name, val) => {
    let value = val ? val : [];
    if (name === "kpis") {
      this.setState({
        kpisSelectedToShowOnGraph: value,
      });
    }
    if (name === "mkpis") {
      this.setState({
        meddicKpisSelectedToShowOnGraph: value,
      });
    }
    if (name === "ckpis") {
      this.setState({
        customerKpisSelectedToShowOnGraph: value,
      });
    }
  };

  checkIfAdminUserType = (data) => {
    return data.UserType === constants.ADMIN_USERTYPE;
  };

  chart = null;
  categoryAxis = null;
  series = null;
  zoomStart = null;
  zoomEnd = null;
  // chartData = null;
  chartContainer = null;
  salesContainer = null;
  meddiccContainer = null;

  sound() {
    this.video.player.play();
  }

  oneXClick() {
    this.audio.current.audio.current.playbackRate = 1.0;
    this.speed = 1;
    if (this.state.isVideoFile && !this.state.isAudioPlayingInPlaylist) {
      this.video.player.playbackRate(1.0);
    }
  }

  onePointFiveXClick() {
    this.audio.current.audio.current.playbackRate = 3.0 / 2;
    this.speed = 1.5;
    if (this.state.isVideoFile && !this.state.isAudioPlayingInPlaylist) {
      this.video.player.playbackRate(1.5);
    }
  }

  twoXClick() {
    this.audio.current.audio.current.playbackRate = 2.0;
    this.speed = 2;
    if (this.state.isVideoFile && !this.state.isAudioPlayingInPlaylist) {
      this.video.player.playbackRate(2.0);
    }
  }

  resetSpeed = () => {
    this.audio.current.audio.current.playbackRate = 1.0;
    this.speed = 1;
    if (this.state.isVideoFile && !this.state.isAudioPlayingInPlaylist) {
      this.video.player.playbackRate(1.0);
    }
    this.setState({
      alignment: 1,
    });
  };

  handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      this.setState({ alignment: newAlignment });
    }
  };

  toggleSnippet = () => {
    if (this.state.isSnippetOpen) {
      this.setState({ isSnippetOpen: false });
      this.graph.current.disposeSnipCursors();
    } else {
      this.setState({ isSnippetOpen: true });
      this.graph.current.createSnipCursors();
    }
  };

  toggleOffSnippet = () => {
    if (this.state.isSnippetOpen) {
      this.setState({ isSnippetOpen: false });
      this.graph.current.disposeSnipCursors();
    }
  };

  handleStartTimeChange = (e) => {
    let formattedValue = e.formattedValue;
    clearTimeout(this.timerObj);
    this.timerObj = setTimeout(() => {
      if (this.reg.test(formattedValue)) {
        let formattedValueSec = constants.toSeconds(formattedValue, this.isLongCall);
        if (formattedValueSec > this.state.dragEndTime - 5) {
          formattedValueSec = this.state.dragStartTime;
        }
        this.startDragRange.value = formattedValueSec;
        this.startDurationSeriesRange.endValue = formattedValueSec;
        this.seekOnAudio(formattedValueSec);
        this.range.value = formattedValueSec; // setting the value for tooltip
        this.setState({ dragStartTime: parseInt(formattedValueSec) });
        this.series.invalidateDataItems();
      }
    }, 200);
  };

  blurStartTimeChange = (e) => {
    let formattedValue = e.target.value;
    clearTimeout(this.timerObj);
    this.timerObj = setTimeout(() => {
      if (this.reg.test(formattedValue)) {
        // hack to remove es-lint empty if block
      } else {
        let res = constants.toHHMMSS(0, this.isLongCall);
        if (this.reg.test(res)) {
          let sec = constants.toSeconds(res, this.isLongCall);
          this.startDragRange.value = sec;
          this.startDurationSeriesRange.endValue = sec;
          this.seekOnAudio(sec);
          this.range.value = sec; // setting the value for tooltip
          this.setState({ dragStartTime: parseInt(sec) });
          this.series.invalidateDataItems();
        } else {
          this.setState({ dragStartTime: -1 }); // For invalid inputs by user to show error
        }
      }
    }, 600);
  };

  handleEndTimeChange = (e) => {
    let formattedValue = e.formattedValue;
    clearTimeout(this.timerObj);
    this.timerObj = setTimeout(() => {
      if (this.reg.test(formattedValue)) {
        let formattedValueSec = constants.toSeconds(formattedValue, this.isLongCall);
        if (formattedValueSec < this.state.dragStartTime + 5) {
          formattedValueSec = this.state.dragEndTime;
        }
        this.endDurationSeriesRange.disposed = false;
        this.endDragRange.value = formattedValueSec;
        this.endDurationSeriesRange.endValue = formattedValueSec;
        this.setState({ dragEndTime: parseInt(formattedValueSec) });
        this.series.invalidateDataItems();
      }
    }, 200);
  };

  blurEndTimeChange = (e) => {
    let formattedValue = e.target.value;
    clearTimeout(this.timerObj);
    this.timerObj = setTimeout(() => {
      if (this.reg.test(formattedValue)) {
        // hack to remove es-lint empty if block
      } else {
        let res = constants.toHHMMSS(this.state.duration, this.isLongCall);
        if (this.reg.test(res)) {
          let sec = constants.toSeconds(res, this.isLongCall);
          this.endDragRange.value = sec;
          this.endDurationSeriesRange.endValue = sec;
          this.setState({ dragEndTime: parseInt(sec) });
          this.series.invalidateDataItems();
        } else {
          this.setState({ dragEndTime: -1 }); // For invalid inputs by user to show error
        }
      }
    }, 600);
  };

  seekFromGraph = (currentTime) => {
    // is equivalent to seekAllOver.
    this.seekOnGraph(currentTime);
    this.seekOnAudio(currentTime);
    this.seekOnVideo(currentTime);
    this.seekOnSentenceTable(currentTime);
  };
  seekFromAudio = (event) => {
    // audio seeks itself to currentTime.
    try {
      let currentTime = event.target.currentTime;
      this.seekOnGraph(currentTime);
      this.seekOnSentenceTable(currentTime);
      this.seekOnVideo(currentTime);
    } catch (e) {}
  };
  changeVolume = (event) => {
    // audio seeks itself to currentTime.
    try {
      if (this.state.isVideoFile) {
        this.video.player.volume(event.target.volume);
      }
    } catch (e) {}
  };
  seekOnGraph = (currentTime) => {
    if (this.range) {
      this.range.value = currentTime;
    }
  };
  seekOnAudio = (currentTime) => {
    try {
      this.audio.current.audio.current.currentTime = currentTime;
    } catch (e) {}
  };

  seekOnVideo = (currentTime) => {
    if (this.state.isVideoFile && !this.state.isAudioPlayingInPlaylist && this.video.player) {
      // file deepcode ignore OperatorPrecedence: <please specify a reason of ignoring this>
      if (this.video.player.player_ !== null) {
        this.video.player.currentTime(currentTime);
        if (!this.video.player.paused() === true && currentTime !== 0 && this.video.player.playlist.length > 1) {
          this.pauseMedia();
        }
      }
    }
  };

  seekOnSentenceTable = (currentTime) => {
    let h = constants.getCurrentSentenceIndex(this.startTimesArr, currentTime);
    this.nextSentenceAt = constants.getNextSentenceStartTime(this.startTimesArr, currentTime);
    this.setState({ scrollToIndex: h });
  };

  setScrollToIndex = (index) => {
    this.setState({ scrollToIndex: index });
  };

  seekByFastForward = (msgID) => {
    try {
      this.pauseMedia();
      let sen;
      if (this.isVideoFile) {
        sen = this.state?.filteredTranscript;
      } else {
        sen = this.state?.data?.sentences;
      }
      let index = sen?.findIndex((v) => v.MessageID === msgID);
      if (index >= 0) {
        this.setScrollToIndex(index);
        let sentenceInfo = sen?.filter((v) => v.MessageID === msgID);
        let st = sentenceInfo[0].StartTime?.second;
        this.seekOnGraph(st);
        this.seekOnAudio(st);
        this.seekOnVideo(st);
      }
    } catch (e) {}
  };

  newlis(e) {
    // event of audio does not need to seekOnAudio.
    let event = e.target.currentTime;
    try {
      if (this.isEndTimePresentInQueryString && event > this.snippetEndTime && this.pauseNotOver) {
        this.pauseMedia();
        this.pauseNotOver = false;
      } else if (event > this.state.dragEndTime && this.state.isSnippetOpen) {
        this.pauseMedia();
        this.pauseNotOver = false;
      }
      this.seekOnGraph(event);

      if (event > this.nextSentenceAt) {
        // to reduce computation cycles, using this logic.
        this.seekOnSentenceTable(event);
      }
    } catch (e) {}
  }
  newClick = (rec, index) => {
    // function called when user clicks on a sentence in table
    try {
      let currentTime = rec.StartTime.second;
      this.seekFromGraph(currentTime);
      this.setState({ scrollToIndex: index });
      this.seekOnSentenceTable(currentTime);
      if (this.state.isVideoFile && !this.state.isAudioPlayingInPlaylist) {
        if (this.video.player.paused() !== true && this.video.player.playlist.length > 1) {
          this.video.player.pause();
        }
      }
    } catch (e) {}
  };

  pauseMedia = () => {
    if (this.audio && this.state.audioURL) {
      this.audio.current.audio.current.pause();
    }
    if (this.state.isVideoFile && !this.state.isAudioPlayingInPlaylist && this.state.audioURL) {
      this.video.player.pause();
    }
  };
  playMedia = () => {
    if (this.audio && this.state.audioURL) {
      this.audio.current.audio.current.play();
    }
    if (this.state.isVideoFile && !this.state.isAudioPlayingInPlaylist && this.state.audioURL) {
      this.video.player.play();
    }
  };

  rewindMedia = () => {
    if (this.audio && this.state.audioURL) {
      this.audio.current.audio.current.currentTime = this.audio.current.audio.current.currentTime - 10;
    }
    if (this.state.isVideoFile && !this.state.isAudioPlayingInPlaylist && this.state.audioURL) {
      this.video.player.currentTime(this.video.player.currentTime() - 10);
    }
  };

  forwardMedia = () => {
    if (this.audio && this.state.audioURL) {
      this.audio.current.audio.current.currentTime = this.audio.current.audio.current.currentTime + 10;
    }
    if (this.state.isVideoFile && !this.state.isAudioPlayingInPlaylist && this.state.audioURL) {
      this.video.player.currentTime(this.video.player.currentTime() + 10);
    }
  };

  handleTabChange = (event, value) => {
    this.setState({
      tabPanelVal: value,
    });
  };

  showParticipantTab = () => {
    this.setState({
      tabPanelVal: "1",
    });
  };

  toggleShowModal = () => {
    let t = this.state.showModal;
    this.setState({ showModal: !t });
  };

  setRef = (ref) => {
    this.video = ref;
  };

  setGraphRef = (ref) => {
    this.graph.current = ref;
  };

  setChartData = (ref) => {
    this.chart = ref.chart;
    this.series = ref.series;
    this.chartData = ref.chartData;
    this.range = ref.range;
    this.startDurationSeriesRange = ref.startDurationSeriesRange;
    this.startDragRange = ref.startDragRange;
    this.endDurationSeriesRange = ref.endDurationSeriesRange;
    this.endDragRange = ref.endDragRange;
  };

  setCustomErrorObject = (errObj) => {
    this.setState({ errorObject: errObj });
  };

  handleOpenSnack = (open, msg) => {
    this.setState({
      openSnack: open,
      msgSnack: msg,
    });
  };

  handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnack: false,
      msgSnack: "",
    });
  };

  showSearchBoxFun = (show) => {
    this.setState({ showSearchBox: show });
  };

  handleCloseErrorSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      errorObject: {
        isError: false,
        msgError: "",
      },
    });
  };

  render() {
    const { scrollToIndex, audioURL, alignment, data, optionsForCustomer, optionsForAgent, optionsForMeddic, errResponse } = this.state;

    if (errResponse.err) {
      return (
        <React.Fragment>
          <CustomError errResponse={errResponse} />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {!this.state.data ? (
          <LinearProgress color="primary" />
        ) : (
          <div className="new-root">
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={this.state.errorObject.isError}
              autoHideDuration={4000}
              onClose={this.handleCloseErrorSnack}
              message={this.state.errorObject.msgError}
              action={
                <React.Fragment>
                  <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseErrorSnack}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            >
              <Alert severity="error">{this.state.errorObject.msgError}</Alert>
            </Snackbar>
            <CallActionsDock
              externalAccess={this.state.externalAccess}
              downloadAudioURL={this.state.audioURL}
              downloadTranscriptUrl={this.state.data.TranscriptsURL ? this.state.data.TranscriptsURL[this.state.playlistIndex] : ""}
              fileType={this.state.fileType}
              fileId={this.state.data.ConversationID}
              id={this.state.id}
              isAdmin={this.state.isAdmin}
              userKeyInSession={this.state.userInfo.userKey}
              clientKey={this.state.data.ClientKey}
              isSnippetCall={this.state.isSnippetCall}
              processingComplete={this.processingComplete}
              sentimentScoreDivCSS={this.sentimentScoreDivCSS}
              overallSentiment={this.state.data.OverallSentiment}
              sentencesLength={this.state.data.sentences.length}
              noTranscript={this.state.noTranscript}
              dataForSnip={this.state}
              isSnippetOpen={this.state.isSnippetOpen}
              onToggleSnip={this.toggleSnippet}
              toggleOffSnip={this.toggleOffSnippet}
              onHandleStartChange={this.handleStartTimeChange}
              onBlurStartTimeChange={this.blurStartTimeChange}
              onBlurEndTimeChange={this.blurEndTimeChange}
              showParticipantTab={this.showParticipantTab}
              isVideoFile={this.state.isVideoFile}
              onHandleEndChange={this.handleEndTimeChange}
              newClick={this.newClick}
              scrollIndex={scrollToIndex}
              utteranceFlags={this.state.utteranceFlags}
              utteranceFlagsSelected={this.state.utteranceFlagsSelected}
              refreshUtteranceFlags={this.refreshUtteranceFlags}
              isLongCall={this.isLongCall}
              seekByFastForward={this.seekByFastForward}
              callMetadataInfo={this.state.callMetadataInfo}
              playlistIndex={this.state.playlistIndex}
              isAudioPlayingInPlaylist={this.state.isAudioPlayingInPlaylist}
            ></CallActionsDock>
            <main className="new-content">
              <div className="new-parentOfAll">
                <Stack className="new-leftPart" spacing={2}>
                  <React.Fragment>
                    <div className="new-videoRow">
                      {this.state.isVideoFile && !this.state.isAudioPlayingInPlaylist ? (
                        <VideoView
                          externalAccess={this.state.externalAccess}
                          fileType={this.state.fileType}
                          fileId={this.state.data.ConversationID}
                          id={this.state.id}
                          isAdmin={this.state.isAdmin}
                          userKeyInSession={this.state.userInfo.userKey}
                          isSnippetCall={this.state.isSnippetCall}
                          isEndTimePresentInQueryString={this.isEndTimePresentInQueryString}
                          snippetStartTime={this.snippetStartTime}
                          snippetEndTime={this.snippetEndTime}
                          sentencesLength={this.state.data.sentences.length}
                          noTranscript={this.state.noTranscript}
                          setRef={this.setRef}
                          stateInfo={this.state}
                          playMedia={this.playMedia}
                          pauseMedia={this.pauseMedia}
                          playlistIndex={this.state.playlistIndex}
                          seekFromGraph={this.seekFromGraph}
                          setMediaUrl={this.setMediaUrl}
                          setPlaylistIndex={this.changePlaylistIndexFromVideo}
                          setIsplaylistClick={this.setIsplaylistClick}
                          isPlaylistClick={this.state.isPlaylistClick}
                        ></VideoView>
                      ) : (
                        <FauxVideoView
                          externalAccess={this.state.externalAccess}
                          isCallRecordingOff={this.isCallRecordingOff}
                          audioURL={audioURL}
                          callMetadataInfo={this.state.callMetadataInfo}
                          sentences={this.state.sentences}
                          scrollToIndex={this.state.scrollToIndex}
                          isMeddic={this.isMeddic}
                        />
                      )}
                    </div>
                    <div className="new-audioRow">
                      <AudioPlayer
                        externalAccess={this.state.externalAccess}
                        ref={this.audio}
                        src={audioURL}
                        onPlay={this.playMedia.bind(this)}
                        onPause={this.pauseMedia.bind(this)}
                        onListen={this.newlis.bind(this)}
                        onSeeked={this.seekFromAudio.bind(this)}
                        onVolumeChange={this.changeVolume.bind(this)}
                        customIcons={{
                          rewind: <Replay10Icon onClick={this.rewindMedia.bind(this)} fontSize="large"></Replay10Icon>,
                          forward: <Forward10Icon onClick={this.forwardMedia.bind(this)} fontSize="large"></Forward10Icon>,
                        }}
                        listenInterval={100}
                        controls
                        style={{ width: "100%", fontFamily: "Inter, sans-serif" }}
                        loop={false}
                        customAdditionalControls={[
                          <StyledToggleButtonGroup
                            key={"ni-aplayer-stbg"}
                            disabled={this.state.audioURL ? false : true}
                            value={alignment}
                            exclusive
                            onChange={this.handleAlignment.bind(this)}
                            size="small"
                            sx={{ borderRadius: "20px" }}
                          >
                            <ToggleButton style={{ border: "none" }} onClick={this.oneXClick.bind(this)} value="1">
                              <strong style={{ textTransform: "lowercase" }}>1x</strong>
                            </ToggleButton>
                            <ToggleButton style={{ border: "none" }} onClick={this.onePointFiveXClick.bind(this)} value="1.5">
                              <strong style={{ textTransform: "lowercase" }}>1.5x</strong>
                            </ToggleButton>
                            <ToggleButton style={{ border: "none" }} onClick={this.twoXClick.bind(this)} value="2">
                              <strong style={{ textTransform: "lowercase" }}>2x</strong>
                            </ToggleButton>
                          </StyledToggleButtonGroup>,
                        ]}
                        title=" "
                      />
                    </div>
                    {!this.state.externalAccess ? (
                      <SentimentGraph
                        dragStartTime={this.state.dragStartTime}
                        dragEndTime={this.state.dragEndTime}
                        callMetadataInfo={this.state.callMetadataInfo}
                        sentences={this.state.sentences}
                        scrollToIndex={this.state.scrollToIndex}
                        setChartData={this.setChartData}
                        setGraphRef={this.setGraphRef}
                        state={this.state}
                        pauseMedia={this.pauseMedia}
                        seekFromGraph={this.seekFromGraph}
                        setParentState={this.setState}
                        seekOnAudio={this.seekOnAudio}
                        startDragRange={this.startDragRange}
                        lastTrackedRange={this.lastTrackedRange}
                        endDragRange={this.endDragRange}
                        renderMidRow={this.renderMidRow}
                        processingComplete={this.state.processingComplete}
                        duration={this.duration}
                        kpisSelectedToShowOnGraph={this.state.kpisSelectedToShowOnGraph}
                        meddicKpisSelectedToShowOnGraph={this.state.meddicKpisSelectedToShowOnGraph}
                        customerKpisSelectedToShowOnGraph={this.state.customerKpisSelectedToShowOnGraph}
                        snippetStartTime={this.snippetStartTime}
                        snippetEndTime={this.snippetEndTime}
                        isMeddic={this.isMeddic}
                        isVideoFile={this.state.isVideoFile}
                        range={this.range}
                        isEndTimePresentInQueryString={this.isEndTimePresentInQueryString}
                        optionsForAgentTagged={this.optionsForAgentTagged}
                        optionsForMeddicTagged={this.optionsForMeddicTagged}
                        optionsForCustomerTagged={this.optionsForCustomerTagged}
                        handleKPIChangeForGraphSelector={this.handleKPIChangeForGraphSelector}
                        series={this.series}
                        recordingIndex={this.state.playlistIndex}
                        toggleOffSnip={this.toggleOffSnippet}
                        kpiInfos={this.state.kpiInfos}
                      />
                    ) : undefined}
                  </React.Fragment>
                </Stack>

                <div className="new-rightPartOuter">
                  <div className="new-rightPart">
                    <TabContext value={this.state.tabPanelVal}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        <TabList variant="scrollable" scrollButtons="auto" onChange={this.handleTabChange}>
                          <Tab key={"insights_tab_1"} className="tab-label" label="Details" value="1" />
                          {!this.state.externalAccess ? <Tab key={"insights_tab_2"} className="tab-label" label="Transcript" value="2" /> : undefined}
                          <Tab key={"insights_tab_3"} className="tab-label" label="Recordings" value="3" />
                          {this.state.callSummary && !this.state.externalAccess ? (
                            <Tab key={"insights_tab_4"} className="tab-label" label="Call Summary" value="4" />
                          ) : undefined}
                        </TabList>
                        <div className="participantInsightsDiv">
                          <div className={this.state.showSearchBox ? "searchButtonDisable" : "searchButton"}>
                            <IconButton
                              style={{ padding: "0px" }}
                              disabled={this.state.showSearchBox}
                              className="searchIconDisable disableButtonDefaultDesign"
                              type="submit"
                              aria-label="search"
                              onClick={() => this.showSearchBoxFun(true)}
                            >
                              <SearchIcon />
                            </IconButton>
                          </div>
                        </div>
                      </Box>
                      <TabPanel
                        key={"insights_tabpanel_1"}
                        sx={{ padding: "4px", height: "calc(100% - 60px)", width: "100%", overflowY: "auto" }}
                        value="1"
                      >
                        <DetailsPanel
                          setParentState={this.setState}
                          stateInfo={this.state}
                          isAdmin={this.state.isAdmin}
                          handleOpenSnack={this.handleOpenSnack}
                          externalAccess={this.state.externalAccess}
                          processingComplete={this.processingComplete}
                          sentimentScoreDivCSS={this.sentimentScoreDivCSS}
                          overallSentiment={this.state.data.OverallSentiment}
                          fileType={this.state.fileType}
                          fileId={this.state.data.ConversationID}
                          id={this.state.id}
                          userKeyInSession={this.state.userInfo.userKey}
                          isSnippetCall={this.state.isSnippetCall}
                          isLongCall={this.isLongCall}
                          callMetadataInfo={this.state.callMetadataInfo}
                        />
                      </TabPanel>
                      {!this.state.externalAccess ? (
                        <TabPanel key={"insights_tabpanel_2"} sx={{ padding: "4px", height: "calc(100% - 60px)", width: "100%" }} value="2">
                          {this.state.processingComplete ? (
                            <CallTranscript
                              externalAccess={this.state.externalAccess}
                              isAdmin={this.state.isAdmin}
                              userInfo={this.state.userInfo}
                              fileId={this.state.data.ConversationID}
                              utteranceFlags={this.state.utteranceFlags}
                              utteranceFlagsSelected={this.state.utteranceFlagsSelected}
                              refreshUtteranceFlags={this.refreshUtteranceFlags}
                              isVideoFile={this.state.isVideoFile}
                              data={data}
                              scrollIndex={scrollToIndex}
                              isMeddic={this.isMeddic}
                              isEdit={this.isEdit}
                              optionsForCustomer={optionsForCustomer}
                              optionsForAgent={optionsForAgent}
                              optionsForMeddic={optionsForMeddic}
                              newClick={this.newClick}
                              playMedia={this.playMedia}
                              pauseMedia={this.pauseMedia}
                              setSentences={this.state.isVideoFile ? this.setSentencesVideoFile : this.setSentences}
                              location={this.props.location.pathname}
                              seekOnSentenceTable={this.seekOnSentenceTable}
                              seekOnVideo={this.seekOnVideo}
                              filteredTranscript={this.state.filteredTranscript}
                              kpiInfos={this.state.kpiInfos}
                              clientKey={this.state.data?.ClientKey}
                              userKey={this.state.userInfo.userKey}
                              playlistIndex={this.state.playlistIndex}
                              isLongCall={this.state.isLongCall}
                              callMetadataInfo={this.state.callMetadataInfo}
                              seekByFastForward={this.seekByFastForward}
                              setScrollToIndex={this.setScrollToIndex}
                              handleOpenSnack={this.handleOpenSnack}
                              showSearchBox={this.state.showSearchBox}
                              showSearchBoxFun={this.showSearchBoxFun}
                              setCustomErrorObject={this.setCustomErrorObject}
                            />
                          ) : undefined}
                        </TabPanel>
                      ) : undefined}
                      {this.state.isVideoFile
                        ? [
                            <TabPanel
                              key={"insights_tabpanel_3"}
                              sx={{
                                padding: "4px",
                                height: "calc(100% - 60px)",
                                width: "100%",
                              }}
                              value="3"
                            >
                              <PlaylistPanel
                                externalAccess={this.state.externalAccess}
                                fileType={this.state.fileType}
                                fileId={this.state.data.ConversationID}
                                id={this.state.id}
                                isAdmin={this.state.isAdmin}
                                userKeyInSession={this.state.userInfo.userKey}
                                isSnippetCall={this.state.isSnippetCall}
                                stateInfo={this.state}
                                videoRef={this.video}
                                setSentences={this.state.isVideoFile ? this.setSentencesVideoFile : this.setSentences}
                                seekFromGraph={this.seekFromGraph}
                                setMediaUrl={this.setMediaUrl}
                                setPlaylistIndex={this.playlistIndexChange}
                                isVideoFile={this.state.isVideoFile}
                                audioIndex={this.state.audioIndex}
                                setIsAudio={this.setIsAudio}
                                muteAudio={this.muteAudio}
                                setindexAudio={this.setindexAudio}
                                isAudioPlayingInPlaylist={this.state.isAudioPlayingInPlaylist}
                                changePlaylistIndexFromAudio={this.changePlaylistIndexFromAudio}
                                playlistIndex={this.state.playlistIndex}
                                resetSpeed={this.resetSpeed}
                                setParentState={this.setState}
                                toggleOffSnip={this.toggleOffSnippet}
                              ></PlaylistPanel>
                            </TabPanel>,
                          ]
                        : [
                            <TabPanel
                              key={"insights_tabpanel_3"}
                              sx={{
                                padding: "4px",
                                height: "calc(100% - 60px)",
                                width: "100%",
                              }}
                              value="3"
                            >
                              <AudioPlaylistPanel
                                externalAccess={this.state.externalAccess}
                                audioRef={this.audio}
                                seekFromGraph={this.seekFromGraph}
                                pauseMedia={this.pauseMedia}
                                timeStamp={this.state.data.Timestamp}
                                stateData={this.state.data}
                                isSnippetCall={this.state.isSnippetCall}
                                toggleOffSnip={this.toggleOffSnippet}
                              ></AudioPlaylistPanel>
                            </TabPanel>,
                          ]}
                      <TabPanel key={"insights_tabpanel_4"} sx={{ padding: "4px", height: "calc(100% - 60px)", width: "100%" }} value="4">
                        <CallInsightsPanel
                          fileId={this.state.data.ConversationID}
                          isAdmin={this.state.isAdmin}
                          userKeyInSession={this.state.userInfo.userKey}
                          stateInfo={this.state}
                        ></CallInsightsPanel>
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </div>
            </main>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={this.state.openSnack}
              autoHideDuration={2000}
              onClose={this.handleCloseSnack}
              message={this.state.msgSnack}
              action={
                <React.Fragment>
                  <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnack}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          </div>
        )}
      </React.Fragment>
    );
  }

  renderKpiSelector = (value, options, name, placeholder) => {
    return (
      <AnimatedMulti
        id={placeholder}
        handleOpenMenu={this.handleOpenMenu}
        handleCloseMenu={this.handleCloseMenu}
        options={options}
        name={name}
        optionchanger={(event) => this.handleKPIChangeForGraphSelector(event, name)}
        value={value}
        placeholder={placeholder}
        defaults={value}
        kpiInfos={this.state.kpiInfos}
      />
    );
  };

  renderMidRow = () => {
    return (
      <span className="new-kpiSelectorsForGraph">
        {this.optionsForAgentTagged.length
          ? this.renderKpiSelector(this.state.kpisSelectedToShowOnGraph, this.optionsForAgentTagged, "kpis", constants.KPIS)
          : null}
        {this.isMeddic && this.optionsForMeddicTagged.length
          ? this.renderKpiSelector(this.state.meddicKpisSelectedToShowOnGraph, this.optionsForMeddicTagged, "mkpis", constants.MEDDICC_KPIS)
          : null}
        {this.optionsForCustomerTagged.length
          ? this.renderKpiSelector(this.state.customerKpisSelectedToShowOnGraph, this.optionsForCustomerTagged, "ckpis", constants.CUSTOMER_KPIS)
          : null}
      </span>
    );
  };
}

export default NewInsights;
