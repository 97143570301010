import { makeStyles } from "@material-ui/core/styles";

const participantsPanelStyles = makeStyles(() => ({
  participantsPanelRoot: {
    width: "100%",
    height: "100%",
  },
  divider: {
    width: "95%",
    alignSelf: "center",
    margin: "auto !important",
  },
  participantDiv: {
    display: "flex",
  },
  sentimentInfo: {
    display: "inline",
    padding: "6px",
    borderRadius: "8px",
    border: "1px solid",
    fontSize: "12px",
    margin: "auto",
    marginRight: "8px",
  },
  hostColor: {
    color: "#1971c2",
  },
  endUserColor: {
    color: "#087f5b",
  },
  "MuiListItemText-secondary": {
    "@media screen and (max-width: 1300px)": {
      fontSize: "0.65rem",
    },
  },
}));

export default participantsPanelStyles;
