import React, { useEffect } from "react";
import * as constants from "../../assets/constants";
import playlistPanelStyles from "./PlaylistPanel.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Grid, Divider } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
// import Typography from "@mui/material/Typography";
import { Typography } from "@material-ui/core";
import SnippetPanel from "../snippet-panel/SnippetPanel";
import Alert from "@mui/material/Alert";

const AudioPlaylistPanel = (props) => {
  const styles = playlistPanelStyles();

  useEffect(() => {}, []);

  function formatDuration() {
    if (props.stateData?.BucketURL && props.audioRef?.current?.audio?.current?.duration) {
      let time = props.audioRef.current.audio.current.duration;
      let parsedTime = time > 0 ? parseInt(time).toFixed(0) : 0;
      return constants.toStringHHMMSS(parsedTime, parsedTime > 3600);
    } else return "Recording unavailable";
  }

  function playlistClick() {
    try {
      props.seekFromGraph(0);
      props.pauseMedia();
      props.toggleOffSnip();
    } catch (err) {}
  }

  return (
    <React.Fragment>
      <div className={styles.playlistPanelRoot}>
        <div className={styles.mediaPlaylist}>
          <Typography
            style={{
              margin: "8px",
              marginLeft: "16px",
              float: "left",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "22px",
              color: "#495057",
            }}
          >
            <div>{props.isSnippetCall ? "Audio Snippet Recording Files" : "Audio Recording Files"}</div>
          </Typography>
          {props.stateData.BucketURL ? (
            <Card
              elevation={0}
              sx={{
                display: "flex",
                margin: "15px",
                marginTop: "5px",
                marginBottom: "5px",
                width: "90%",
                cursor: "pointer",
              }}
              onClick={() => playlistClick()}
            >
              <Grid spacing={0} container xs={12}>
                <Grid item xs={3}>
                  <Card
                    sx={{
                      border: "2.5px solid #88B3DA",
                      boxShadow: "none",
                      borderRadius: "10px",
                      height: "100px",
                      background: "#E7F5FF",
                    }}
                  >
                    <VolumeUpIcon
                      color="primary"
                      sx={{
                        height: "62px",
                        width: "55px",
                        top: "17px",
                        position: "relative",
                      }}
                    ></VolumeUpIcon>
                  </Card>
                </Grid>

                <Grid item xs={9}>
                  <Box>
                    <CardContent sx={{ padding: "10px" }}>
                      <Typography variant="subtitle2" component="div" align="left">
                        {props.isSnippetCall
                          ? props.stateData?.SnippetInfo?.name
                            ? props.stateData?.SnippetInfo?.name
                            : "Snippet file"
                          : "Audio file"}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" component="div" align="left">
                        {props.timeStamp._seconds ? constants.formatTimeStampToEST(props.timeStamp) : null}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" align="left" component="div">
                        {formatDuration()}
                      </Typography>
                    </CardContent>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          ) : (
            <Alert className={styles.alertBox} severity="warning">
              {constants.ERROR_NO_RECORDING_AVAILALE}
            </Alert>
          )}
        </div>
        {props.stateData?.Snippets && props.stateData?.Snippets?.length > 0 ? (
          <React.Fragment>
            <Divider
              sx={{
                marginTop: "8px",
              }}
            />
            <SnippetPanel Snippets={props.stateData?.Snippets}></SnippetPanel>
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default AudioPlaylistPanel;
