import Container from "@mui/material/Container";
import { withStyles } from "@material-ui/core/styles";

const StyledContainer = withStyles({
  root: {
    display: "inline-flex",
    flexWrap: "wrap",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    "& > :not(:last-child)": {
      marginRight: "6px",
      marginBottom: "6px",
    },
    "& > *": {
      marginBottom: "6px",
    },
    marginBottom: "6px",
  },
})(Container);

export default StyledContainer;
