import config from "../config/config";
import fetchError from "../helper/customException";

export async function checkSnippetExists(requestData) {
  try {
    const response = await fetch(
      config.PCA_SERVER_URL + "checkSnippetExists",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      },
      2 * 10 * 60 * 1000
    );
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    if (json_response.data.snippetExists) {
      let fileID = json_response.data.fileID;
      let url = config.PCA_CLIENT_URL + fileID;
      json_response.data.url = url;
    }
    return json_response;
  } catch (err) {
    if (typeof err.text === "function") {
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      throw new Error(err);
    }
  }
}

export default async function getSnippet(requestData) {
  try {
    const response = await fetch(
      config.PCA_SERVER_URL + "getSnippet",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      },
      2 * 10 * 60 * 1000
    );
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    return json_response;
  } catch (err) {
    if (typeof err.text === "function") {
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      throw new Error(err);
    }
  }
}

export async function saveSnippet(fileObject) {
  try {
    const response = await fetch(
      config.PCA_SERVER_URL + "saveSnippet",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: `bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(fileObject),
      },
      2 * 10 * 60 * 1000
    );
    if (!response.ok) {
      throw response;
    }
    const json_response = await response.json();
    let fileID = json_response.data.id;
    let url = config.PCA_CLIENT_URL + fileID;
    json_response.data.url = url;
    return json_response;
  } catch (err) {
    if (typeof err.text === "function") {
      let errorMessage = await err.text();
      throw new fetchError(err.status, errorMessage);
    } else {
      throw new Error(err);
    }
  }
}
