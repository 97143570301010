import React from "react";
import "./VideoView.css";
import VideoPlayer from "./VideoPlayer";
import Container from "@mui/material/Container";

const videoJsOptions = {
  autoplay: false,
  controls: true,
  preload: "auto",
  controlBar: {
    children: ["fullscreenToggle", "pictureInPictureToggle"],
  },
};

const VideoView = (props, ref) => {
  const [fullWidth, setFullWidth] = React.useState(false);

  return (
    <React.Fragment>
      <Container sx={{ padding: "0px !important" }} maxWidth="false">
        <VideoPlayer {...videoJsOptions} getFullWidth={setFullWidth} fullwidth={fullWidth} {...props} forwardedRef={ref} />
      </Container>
    </React.Fragment>
  );
};

export default VideoView;
