import "./App.css";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Navbar from "./nav-bar/Navbar";
import ErrorBoundary from "./helper/Error/Error";
import FileView from "./post-call-analysis/listOfCalls";
import NewInsights from "./post-call-analysis/new-insights/NewInsights";
import ModelHistory from "./admin-view/modelHistory";
import Choice from "./admin-view/Choice";
import CssBaseline from "@mui/material/CssBaseline";
import CustomError from "./helper/CustomError/CustomError";
import "@fontsource/inter"; // Defaults to weight 400.
import Search from "./elastic-search/Search";
import TimeoutLogic from "./post-call-analysis/timeout-warning-modal/TimeoutLogic";
import UtteranceFlags from "./admin-view/UtteranceFlags";
import UserAddedKPIs from "./admin-view/UserAddedKPIs";
import UserAddedKpisConfigurationTool from "./admin-view/UAK-Configuration.js";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1971C2",
    },
    secondary: {
      main: "#D96932",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
});
const useStyles = makeStyles(() => ({
  root: {
    textAlign: "center",
    height: "calc(100% - 64px)",
    overflow: "auto",
  },
  inner: {
    padding: "0px",
    height: "100%",
  },
  app: {
    width: "100%",
    height: "100%",
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <div className={classes.app}>
          <Navbar />
          <ErrorBoundary>
            <div className={classes.root}>
              <Switch>
                <Route exact path="/files" component={FileView} />
                <Route exact path="/choice" component={Choice} />
                <Route exact path="/Model-History" component={ModelHistory} />
                <Route exact path="/search" component={Search} />
                <Route exact path="/UtteranceFlags" component={UtteranceFlags} />
                <Route exact path="/UserAddedKPIs" component={UserAddedKPIs} />
                <Route exact path="/UAK-Configurations" component={UserAddedKpisConfigurationTool} />
                <Route path="/:fileId" component={NewInsights} />
                <Route exact path="*" component={<CustomError />} />
                <Route path="/error-page" element={<CustomError />} />
                <Route path="/redirect" element={<Redirect to="/error-page" />} />
              </Switch>
              <TimeoutLogic />
            </div>
          </ErrorBoundary>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
