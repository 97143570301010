import React from "react";
import * as constants from "../../assets/constants";
import participantsPanelStyles from "./ParticipantsPanel.css";
import { Grid } from "@mui/material";
import { Box, Typography } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import TimerIcon from "@material-ui/icons/Timer";
import LightTooltip from "../../assets/components/LightTooltip";
import { withStyles } from "@material-ui/core/styles";

const ParticipantsPanel = (props) => {
  const styles = participantsPanelStyles();
  const playlistIndex = props.stateInfo.playlistIndex;
  const participant = [];
  const hosts = [];

  props.stateInfo?.data?.Participants?.forEach((item) => {
    if (item.type === "HOST" || item.type === constants.HUMAN_AGENT) hosts.push(item);
    else participant.push(item);
  });

  const participantDesign = (particiPantList, isHost) => {
    const listItems = particiPantList?.map((item, index) => {
      if (item.type === "HOST" || item.type === constants.HUMAN_AGENT) {
        let returnedValue = particiPantList?.splice(index, 1);
        if (returnedValue.length) particiPantList?.unshift(returnedValue[0]);
      }

      return (
        <React.Fragment key={`participant_${isHost}_${index}`}>
          {item.type === "HOST" || item.type === constants.HUMAN_AGENT ? <StyledSubTitleHeader>Host:</StyledSubTitleHeader> : undefined}
          <Grid item xs={12} sx={{ minHeight: "auto", maxHeight: "15rem", overflowY: "auto", overflowX: "hidden" }}>
            <ListItem key={item.id} className={item.type === "HOST" || item.type === constants.HUMAN_AGENT ? styles.hostColor : styles.endUserColor}>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    border:
                      item.type === "HOST" || item.type === constants.HUMAN_AGENT ? "1px solid #1971C2 !important" : "1px solid #087F5B !important",
                    backgroundColor: "white",
                    color: item.type === "HOST" || item.type === constants.HUMAN_AGENT ? "#1971C2" : "#087F5B",
                    width: 40,
                    height: 40,
                  }}
                >
                  <Typography
                    className={item.type === "HOST" || item.type === constants.HUMAN_AGENT ? styles.hostColor : styles.endUserColor}
                    style={{ textAlign: "left", fontWeight: 1000 }}
                    variant="body2"
                  >
                    {" "}
                    {constants.getAcroyn(item.name)}
                  </Typography>
                </Avatar>
              </ListItemAvatar>
              <ListItemText style={{ fontSize: "0.775rem" }} primary={item.name} secondary={item.email} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TimerIcon
                  className={item.type === "HOST" || item.type === constants.HUMAN_AGENT ? styles.hostColor : styles.endUserColor}
                  display="grid"
                ></TimerIcon>
                <LightTooltip title={!item.speaker_metrics ? item.speakDuration : item.speaker_metrics[playlistIndex]?.speakDuration} arrow>
                  <Typography
                    className={item.type === "HOST" || item.type === constants.HUMAN_AGENT ? styles.hostColor : styles.endUserColor}
                    style={{ textAlign: "left", fontSize: "14px", margin: "6px" }}
                    variant="body2"
                  >
                    {item.speaker_metrics ? item.speaker_metrics[playlistIndex]?.speakPercent : item?.speakPercent}
                  </Typography>
                </LightTooltip>
              </div>
            </ListItem>
          </Grid>
        </React.Fragment>
      );
    });

    return listItems;
  };

  return (
    <React.Fragment>
      <StyledContentContainer>
        <Grid item xs={12} sx={{ minHeight: "auto", maxHeight: "20rem", overflowY: "hidden", overflowX: "hidden" }}>
          <StyledTitleHeader>Participants</StyledTitleHeader>
          <List>{participantDesign(hosts, true)}</List>
          {participant.length > 0 ? (
            <>
              <StyledSubTitleHeader>Others:</StyledSubTitleHeader>
              <Grid item xs={12} sx={{ minHeight: "auto", maxHeight: "15rem", overflowY: "auto", overflowX: "hidden" }}>
                <List>{participantDesign(participant, false)}</List>
              </Grid>
            </>
          ) : undefined}
        </Grid>
      </StyledContentContainer>
    </React.Fragment>
  );
};

export default ParticipantsPanel;

const StyledTitleHeader = withStyles({
  root: {
    color: "#495057",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "1rem",
    fontFamily: "Inter",
    display: "flex",
  },
})(Typography);

const StyledSubTitleHeader = withStyles({
  root: {
    color: "#495057",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1rem",
    fontFamily: "Inter",
    paddingLeft: "3px",
    paddingBottom: "2px",
    display: "flex",
  },
})(Typography);

const StyledContentContainer = withStyles({
  root: {
    display: "flow",
    textAlign: "initial",
    paddingTop: "16px",
    "& > :not(:last-child)": {
      paddingBottom: "0.75rem",
    },
    "& > *": {
      paddingBottom: "0rem",
    },
  },
})(Box);
