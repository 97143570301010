import { makeStyles } from "@material-ui/core/styles";

const snippetPanelStyles = makeStyles(() => ({
  snippetPanelRoot: {
    width: "100%",
    marginBottom: "16px",
  },
  snippetListDiv: {
    overflowY: "auto",
    width: "100%",
    height: "calc(100% - 24px)",
  },
  panelTitleText: {
    width: "100%",
    textAlign: "left",
    float: "left",
  },
}));

export default snippetPanelStyles;
