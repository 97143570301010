const drawerWidth = 320;

const callActionDockStyles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#E5E5E5",
    padding: "16px",
    paddingRight: "8px",
    "@media screen and (max-width: 1319px)": {
      position: "absolute",
      zIndex: 10,
      height: "90%",
      background: "none",
    },
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "hidden",
    position: "inherit",
    borderRadius: "10px",
    boxShadow: "0px 6px 18px rgb(0 0 0 / 6%)",
    height: "88vh",
    marginBottom: "1em",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(10) + 1,
    },
    overflow: "hidden",
    position: "inherit",
    borderRadius: "10px",
    boxShadow: "0px 6px 18px rgb(0 0 0 / 6%)",
    height: "88vh",
    marginBottom: "1em",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  divider: {
    width: "90%",
    alignSelf: "center",
    margin: "auto",
  },
  actionContentOpen: {
    paddingBottom: "5%",
    maxHeight: "52%",
    overflowY: "auto",
  },
  actionContentClose: {
    paddingBottom: "5%",
    maxHeight: "87%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  actionContentDocked: {
    paddingBottom: "5%",
    maxHeight: "81%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  drawerToggleDiv: {
    width: "fit-content",
    marginInlineStart: "auto",
    marginRight: "8px",
    marginTop: "8px",
  },
  drawerToggleDivOpen: {
    marginBottom: "-2em",
  },
  drawerToggleDivClose: {
    marginBottom: "-32px",
  },
  drawerIcon: {
    padding: "0px !important",
    borderRadius: "10px !important",
    outline: "0 !important",
  },
  drawerArrowIcon: {
    padding: "0px",
    borderRadius: "10px",
    outline: "0 !important",
    color: "#1976d2",
  },
  actionFooter: {
    width: "inherit",
    height: "12%",
    position: "absolute",
    bottom: "0.5em",
  },
  actionStack: {
    //v
    bottom: "0",
    position: "absolute",
    width: "288px",
  },
  actionFooterOpen: {
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "inherit",
  },
  actionFooterClose: {
    padding: "5px",
    height: "auto",
    bottom: "0",
    position: "absolute",
    width: "inherit",
  },
  downloadButton: {
    borderRadius: "10px !important",
    outline: "0 !important",
    width: "inherit",
  },
  downloadLink: {
    width: "inherit",
  },
  actionButton: {
    borderRadius: "10px !important",
    outline: "0 !important",
    width: "inherit",
  },
  buttonText: {
    textTransform: "none",
    fontSize: "15px",
  },
  actionSnippet: {
    bottom: "11%",
    position: "absolute",
    width: "inherit",
    marginBottom: "8px",
    padding: "16px",
    zIndex: "200",
  },
  inlineButton: {
    width: "138px",
    marginLeft: "2px !important",
    marginRight: "2px !important",
    "&:hover": {
      color: "white !important",
      backgroundColor: "#1976d2 !important",
    },
  },
  downloadButtonDiv: {
    marginBottom: "15px",
    marginTop: "8px",
    backgroundColor: "white",
    position: "relative",
    zIndex: 2,
  },
  drawerBase: {
    "@media screen and (max-width: 1320px)": {
      height: "85.5vh !important",
    },
    position: "relative",
    height: "100%",
  },
  callActionsDivDocked: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    border: "0px",
    padding: "15px",
    width: "35%",
    "@media screen and (max-width: 1525px)": {
      width: "45%",
    },
  },
  backDropDiv: {
    display: "flex",
    flexDirection: "row",
  },
  externalTextField: {
    width: "80%",
  },
  externalButtonDiv: {
    width: "20%",
    marginLeft: "20px",
  },
  snippetActionButton: {
    margin: "8px",
  },
  alert: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
});

export default callActionDockStyles;
