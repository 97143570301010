import { makeStyles } from "@material-ui/core/styles";

const callNotesStyles = makeStyles((theme) => ({
  callNoteRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  participantText: {
    textAlign: "left",
    fontSize: "12px",
    textTransform: "capitalize",
  },
  alert: {
    marginBottom: theme.spacing(1),
  },
  callNoteText: {
    width: "inherit",
    overflowWrap: "break-word",
    whiteSpace: "normal",
    overflowY: "auto",
    maxHeight: "100px",
    marginLeft: "24px !important",
    marginRight: "8px !important",
    marginTop: "16px !important",
  },
  callNotesDocked: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  drawer_label: {
    marginRight: "4px",
    fontFamily: "Inter",
    fontWeight: "600",
    color: "#495057",
  },
  svgExpandMoreIconDefault: {
    backgroundColor: "white",
    borderRadius: "50%",
  },
  accordion_default_with_hover: {
    margin: "12px 16px 8px 16px !important",
    padding: "0px !important",
    minHeight: "0px !important",
    "& .MuiAccordionSummary-content": {
      margin: "0px !important",
    },
    background: "#FFFFFF",
    "&:hover": {
      background: "#F2F3F5",
      borderRadius: "2px",
    },
  },
}));

export default callNotesStyles;
