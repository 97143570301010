import React from "react";
import styles from "./CallInsightsPanel.module.css";
import CallSummaryAction from "./CallSummaryAction/CallSummaryAction";

export default function CallInsightsPanel(props) {
  return (
    <React.Fragment>
      <div className={styles.callInsightsPanelRoot}>
        <CallSummaryAction {...props}></CallSummaryAction>
      </div>
    </React.Fragment>
  );
}
