import React, { useEffect, useState } from "react";
import * as constants from "../../../assets/constants";
import styles from "./CardModal.module.css";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import { TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import validateKeyword, { createContentCard } from "../../../services/cardCreatorService";
import Container from "@mui/material/Container";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { Button } from "@mui/material";
import { Typography } from "@material-ui/core";
import { ViewSection } from "./ViewSection/ViewSection";
import { BulletCard } from "./BulletCard/BulletCard";
import Fade from "@material-ui/core/Fade";
import HtmlTooltip from "../../../assets/components/HtmlTooltip";
import { isEmpty, isEmptySections } from "../../../assets/constants";
import StyledBasicChip from "../../../assets/components/StyledBasicChip/StyledBasicChip";
import classNames from "classnames";

const CardModal = (props) => {
  const initialErrorObject = {
    isError: false,
    msgError: "",
  };

  const initialCardData = {
    cardType: "battlecard",
    cardTitle: "",
    context: ["all"],
    office: "0",
    active: "0",
    cardid: 0,
  };

  const initialSectionData = [
    {
      sectionTitle: "",
      id: 0,
      bulletList: [
        {
          bulletHeader: "",
          bulletContent: "",
          bulletType: "text",
          bulletLink: "",
          bulletlinkurl: "",
        },
      ],
    },
  ];

  const initialBulletData = {
    bulletTitle: "",
    bulletContent: "",
    bulletType: "text",
    bulletLink: "",
    bulletLinkAddress: "",
  };

  const DISPLAY_KEYWORD_MAX_LIMIT = 5;
  const [bullet] = React.useState(initialBulletData);
  const [errorObject, setErrorObject] = React.useState(initialErrorObject);
  const [textValue, setTextValue] = React.useState("");
  const [isDupKeywordExists, setIsDupKeywordExists] = useState(false);
  const [chipData, setChipData] = React.useState([]);
  const [isLeftOverClicked, setIsLeftOverClicked] = useState(false);
  const [keywordChips, setKeywordChips] = React.useState([]);
  const [userInSession] = React.useState(props.userInfo);
  const [duplicateKeywordList, setDuplicateKetwordList] = useState([]);
  const [cardTypeInfo, setCardTypeInfo] = React.useState(initialCardData);
  const [sectionList, setSectionList] = useState(initialSectionData);
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    resetModalData();
    setCardTypeInfo(initialCardData);
    if (props.index != null && props.tempSentences[props.index]?.sentence != null) {
      const newBulleteData = JSON.parse(JSON.stringify(initialSectionData));
      newBulleteData[0].bulletList[0].bulletContent = props.tempSentences[props.index].sentence;
      setSectionList(newBulleteData);
    }
  }, [props.tempSentences, props.index]);

  useEffect(() => {
    const { cardType, context, office } = cardTypeInfo;
    if (
      isEmpty(cardType) ||
      isEmpty(context) ||
      isEmpty(office) ||
      isEmptySections(sectionList) ||
      isDupKeywordExists ||
      duplicateKeywordList?.length > 0
    ) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
      setIsDupKeywordExists(false);
    }
  }, [cardTypeInfo, sectionList, keywordChips, isDupKeywordExists, duplicateKeywordList]);

  useEffect(() => {}, [props.userInfo]);

  const resetModalData = () => {
    setChipData([]);
    setCardTypeInfo(initialCardData);
    setKeywordChips([]);
    setSectionList(initialSectionData);
  };

  const handleSaveCard = async (cardTypeInfo, keywordChips, section, bullet) => {
    setErrorObject(initialErrorObject);
    let res = await createNewCard(cardTypeInfo, keywordChips, section, bullet);
    if (res?.status !== 200) {
      props.handleOpenSnack(true, "ERR: " + res.message);
    } else {
      resetModalData();
      props.handleModalClose();
      props.handleOpenSnack(true, "Card Saved");
    }
  };

  const createNewCard = async (cardTypeInfo, keywordChips, section) => {
    let userkey = props.userInfo.userKey !== 0 ? props.userInfo.userKey : 0;
    let licenseid = props.userInfo.userKey !== 0 ? props.userInfo.goeLicense : "";
    let clientkey = props.userInfo.userKey !== 0 ? props.userInfo.clientKey : 0;
    const { cardType, active, cardTitle, context } = cardTypeInfo;

    const payload = {
      licenseid,
      clientkey,
      officekey: 0,
      cardstatus: active,
      userkey: userkey,
      provider: "gspb",
      keywords: keywordChips?.map((item) => item?.title).join(","),
      cardtitle: cardTitle,
      cardtype: cardType,
      cardcontent: {
        clientkey,
        officekey: 0,
        keywords: "",
      },
      context,
      office: 0,
      section,
    };
    let res = await createContentCard(payload);
    return res;
  };

  const handleTextChange = (e) => {
    setErrorObject(initialErrorObject);
    setTextValue(e.target.value);
    if (e.target.value === "") {
      onKeywordValidationCallBack(false);
    }
  };

  const onKeywordValidationCallBack = (isDuplicateKeyword) => {
    setIsDupKeywordExists(isDuplicateKeyword);
  };

  const handleKeyPressed = (e) => {
    if (e.keyCode === 13) {
      handleUserInputs();
    }
  };

  const handleOnInputBlur = () => {
    handleUserInputs();
  };

  const onChipsCallBack = (chips) => {
    setKeywordChips(chips);
    // setIsCardActive(true);
  };

  const addChip = (text) => {
    const tempData = {
      title: `${text}`,
      key: chipData.length + 1,
      added: true,
    };
    const chipsInfo = [tempData, ...chipData];

    setChipData(chipsInfo);
    onChipsCallBack(chipsInfo);
  };

  const handleDelete = (chipToDelete) => () => {
    const latestChips = chipData.filter((chip) => chip.key !== chipToDelete.key);

    setChipData(latestChips);
    onChipsCallBack(latestChips);
    onKeywordValidationCallBack(false);
  };

  const handleClick = (data) => {
    setTextValue(data.title);
  };

  const keywordTitlesList = chipData?.map((item) => item?.title);

  const onLeftOverCountClickHandler = () => {
    setIsLeftOverClicked(true);
  };

  const count = !isLeftOverClicked ? DISPLAY_KEYWORD_MAX_LIMIT : keywordChips.length;

  const getKeywordChipClassName = (keyword) => {
    const isValid =
      constants.countOccurrences(keywordTitlesList, keyword) > 1 || (duplicateKeywordList?.length > 0 && duplicateKeywordList.includes(keyword));
    if (isValid) {
      return true;
    }

    return false;
  };

  const validateKeywordsList = async (keyword) => {
    const officekey = 0;
    const cardid = 0;
    const licenseid = userInSession.goeLicense ? userInSession.goeLicense : "";
    const clientkey = userInSession.clientKey ? userInSession.clientKey : 0;

    const payload = {
      licenseid,
      clientkey,
      keyword,
      officekey,
      cardId: cardid,
    };
    const wordList = await validateKeyword(payload);
    const duplicateList = wordList?.filter((item) => item?.wordCount > 0)?.map((item) => item?.word);
    setDuplicateKetwordList(duplicateList);
    return duplicateList;
  };

  const handleUserInputs = async () => {
    if (textValue === "") {
      return;
    }
    const keywordsResult = textValue?.split(",").map((key) => key.split(";"));
    const keywordsArr = [].concat.apply([], keywordsResult);
    let finalList = [];
    keywordsArr.forEach((item) => {
      if (!keywordTitlesList.includes(item)) finalList.push(item);
    });
    let dupListFound = [];

    if (finalList?.length > 1) {
      dupListFound = await validateKeywordsList(finalList);

      const getDiffKeywords = constants.getDiffBetweenArrayItems(finalList, dupListFound);

      const tempDataList = await getDiffKeywords?.map((item, index) => {
        return {
          title: `${item}`,
          key: chipData.length + index + 1,
          added: true,
        };
      });

      const chipsInfo = [...tempDataList, ...chipData];

      setChipData(chipsInfo);
      onChipsCallBack(chipsInfo);

      onKeywordValidationCallBack(false, chipsInfo);

      if (dupListFound?.length > 0) {
        const dupKeywords = dupListFound?.join(",");
        onKeywordValidationCallBack(true);
        setTextValue(dupKeywords);
      } else {
        onKeywordValidationCallBack(false, chipsInfo);
        setTextValue("");
      }
      return;
    }
    if (finalList[0] && !keywordTitlesList.includes(finalList[0])) {
      const dupListFound = await validateKeywordsList(finalList[0]);
      if (dupListFound.length > 0) {
        setErrorObject({
          severity: "warning",
          isError: true,
          msgError: "Keyword(s) already associated with a gryph card",
        });
        onKeywordValidationCallBack(true);
        return;
      }

      setIsLeftOverClicked(false);
      addChip(finalList[0]);
      setTextValue("");
      onKeywordValidationCallBack(false);
    } else {
      setErrorObject({
        severity: "warning",
        isError: true,
        msgError: "Please enter unique keyword(s)",
      });
      onKeywordValidationCallBack(true);
    }

    return;
  };

  const getSectiondetails = (type, value, isBulletType, sectionId, bulletId) => {
    if (type === "cardTitle") {
      setCardTypeInfo({ ...cardTypeInfo, cardTitle: value });
      return;
    }
    if (isBulletType) {
      const findSection = sectionList.filter((section) => section?.id === sectionId)[0];
      const findBullet = findSection?.bulletList?.filter((bullet, index) => index === bulletId)[0];
      const updateBullet = { ...findBullet, [type]: value };
      const updateBullets = findSection?.bulletList?.map((bullet, index) => {
        if (index === bulletId) {
          return updateBullet;
        }
        return bullet;
      });

      const updateSection = { ...findSection, bulletList: updateBullets };

      const sampleData = sectionList.map((section) => {
        if (section.id === sectionId) {
          return updateSection;
        }

        return section;
      });
      setSectionList(sampleData);
    } else {
      const findSection = sectionList.filter((section) => section?.id === sectionId)[0];
      const updateSectionTitle = { ...findSection, sectionTitle: value };
      const sampleData = sectionList.map((section) => {
        if (section.id === sectionId) {
          return updateSectionTitle;
        }

        return section;
      });

      setSectionList(sampleData);
    }
    setIsDupKeywordExists(false);
  };

  const handleSectionDel = (id) => {
    const temp = sectionList.filter((section) => {
      return `${section.id}` !== `${id}`;
    });
    setSectionList([...temp]);
  };
  const handleBulletDel = (sectionId, bulletId) => {
    const temp = sectionList.map((section) => {
      if (`${section.id}` === `${sectionId}`) {
        const bulletList = section.bulletList.filter((_, index) => index !== bulletId);
        return {
          ...section,
          bulletList: [...bulletList],
        };
      }
      return section;
    });
    setSectionList([...temp]);
  };

  return (
    <React.Fragment>
      <Modal
        disableRestoreFocus={true}
        open={props.openCardModal}
        onClose={props.handleModalClose}
        aria-labelledby="card-modal-title"
        aria-describedby="card-modal-description"
      >
        <Paper className={styles.cardModalContainer}>
          <div className={styles.cardModalSub}>
            {errorObject.isError ? (
              <Alert className={styles.alert} severity={errorObject.severity ? errorObject.severity : "error"}>
                {errorObject.msgError}
              </Alert>
            ) : undefined}
            <Typography style={{ color: "#1971C2" }} variant="subtitle2">
              Create Card
            </Typography>
            <br />

            <StyledTextField
              label="Keywords"
              placeholder="Type a key word or phrase and press enter to add"
              variant="outlined"
              style={{ width: "100%" }}
              value={textValue}
              onChange={handleTextChange}
              onKeyUp={handleKeyPressed}
              onBlur={handleOnInputBlur}
            />

            <StyledContainer>
              {chipData
                .filter((_, index) => index < count)
                .map((data) => {
                  return (
                    <StyledBasicChip
                      isAdmin={true}
                      key={data.key}
                      className={getKeywordChipClassName(data.title) ? styles.applyError : undefined}
                      label={data.title}
                      onDelete={handleDelete(data)}
                      onClick={() => handleClick(data)}
                    />
                  );
                })}
              {!isLeftOverClicked && chipData.length > DISPLAY_KEYWORD_MAX_LIMIT && (
                <li className={styles.LeftOverCountStyled} onClick={onLeftOverCountClickHandler}>{`+${
                  chipData.length - DISPLAY_KEYWORD_MAX_LIMIT
                }...`}</li>
              )}
            </StyledContainer>
            <Divider className={styles.divider} />
            <div className={styles.CardHeaderStyled}>
              <StyledTextField
                className={styles.CardTitleStyled}
                name="Card title"
                variant="outlined"
                label="Card title"
                onChange={(event) => {
                  getSectiondetails("cardTitle", event.target.value, false);
                }}
                value={cardTypeInfo.cardTitle}
              />
              {/* <EqualizerRounded className={styles.EqualizerRoundedStyled} color="primary" /> */}
            </div>

            {sectionList && (
              <div className={styles.SectionLayoutStyled}>
                {sectionList &&
                  sectionList.map((section, index) => {
                    return (
                      <div key={`section_parent_${index}`}>
                        <div className={styles.SectionContainerStyled} key={`section_${index}`}>
                          <div className={styles.SectionTitleLineStyled} key={`section_title_${index}`}></div>
                          <ViewSection
                            onSectionDetailsChange={getSectiondetails}
                            isNewCard={true}
                            sectionLength={sectionList.length}
                            sectionData={section}
                            sectionId={section?.id}
                            handleDelete={handleSectionDel}
                          >
                            <>
                              {section.bulletList.map((bullet, idx) => {
                                return (
                                  <div key={`bullet_${idx}`}>
                                    <BulletCard
                                      onSectionDetailsChange={getSectiondetails}
                                      defaultValues={bullet}
                                      handleBulletDel={handleBulletDel}
                                      bulletLength={section.bulletList.length}
                                      sectionId={section?.id}
                                      bulletId={idx}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          </ViewSection>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div className={styles.saveCardDiv}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    <b>Save</b>
                  </Typography>
                  <em>{"Click to save gryph card"}</em>
                </React.Fragment>
              }
              placement="left"
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 200 }}
            >
              <span>
                <Button
                  className={classNames(styles.actionButton, styles.actionButtonSave)}
                  sx={{ margin: "20px 5px", fontFamily: "Inter, sans-serif" }}
                  variant="outlined"
                  onClick={() => handleSaveCard(cardTypeInfo, keywordChips, sectionList, bullet)}
                  disabled={disableSave}
                >
                  Save
                </Button>
              </span>
            </HtmlTooltip>

            <Button
              className={classNames(styles.actionButton)}
              sx={{ margin: "20px 5px", fontFamily: "Inter, sans-serif" }}
              onClick={props.handleModalClose}
              variant="outlined"
            >
              Cancel
            </Button>
          </div>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};

export default CardModal;

const StyledTextField = withStyles({
  root: {
    "& input": {
      color: "#495057",
      fontSize: "14px",
    },
    "& fieldset": {
      borderRadius: "10px",
      fontSize: "14px",
    },
    "& textarea": {
      color: "#495057",
      fontSize: "14px",
    },
  },
})(TextField);

const StyledContainer = withStyles({
  root: {
    display: "inline-flex",
    flexWrap: "wrap",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    "& > :not(:last-child)": {
      marginRight: "6px",
    },
    "& > *": {
      marginBottom: "6px",
    },
    marginBottom: "6px",
    marginTop: "12px",
  },
})(Container);
