import React from "react";
import snippetPanelStyles from "./SnippetPanel.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { Typography } from "@material-ui/core";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import * as constants from "../../assets/constants";

const SnippetPanel = (props) => {
  const styles = snippetPanelStyles();

  function formatDuration(index) {
    if (props.Snippets[index]) {
      let startValue = props.Snippets[index]?.sourceStartTime;
      let endValue = props.Snippets[index]?.sourceEndTime;
      var startDate = new Date(startValue * 1000).getTime();
      var endDate = new Date(endValue * 1000).getTime();
      var ms = (endDate - startDate) / 1000;
      return constants.toStringHHMMSS(ms, ms > 3600);
    } else {
      return undefined;
    }
  }

  function formatSnippetTitle(index) {
    if (props.Snippets[index]) {
      let startValue = props.Snippets[index]?.sourceStartTime;
      let endValue = props.Snippets[index]?.sourceEndTime;
      let startTimeStr = constants.toStringHHMMSS(startValue, startValue > 3600);
      let endTimeStr = constants.toStringHHMMSS(endValue, endValue > 3600);
      return "" + startTimeStr + " - " + endTimeStr;
    } else {
      return "";
    }
  }

  function playlistClick(index) {
    try {
      const url = `${window.location.origin}/${props.Snippets[index].id}${window.location.search}`;
      window.open(url, "_blank");
    } catch (err) {
      //
    }
  }

  return (
    <React.Fragment>
      <div className={styles.snippetPanelRoot}>
        <Typography
          style={{
            margin: "8px",
            marginLeft: "16px",
            float: "left",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "22px",
            color: "#495057",
            textAlign: "left",
          }}
        >
          Snippets
        </Typography>
        <div className={styles.snippetListDiv}>
          {props.Snippets?.length > 0
            ? props.Snippets?.map((item, index) => {
                return (
                  <Card
                    key={`cardSnip_${index}`}
                    elevation={0}
                    sx={{
                      display: "flex",
                      margin: "15px",
                      marginTop: "5px",
                      marginBottom: "20px",
                      width: "90%",
                      cursor: "pointer",
                    }}
                    onClick={() => playlistClick(index)}
                  >
                    <Grid spacing={0} container xs={12}>
                      <Grid item xs={3}>
                        <Card
                          sx={{
                            boxShadow: "none",
                            borderRadius: "10px",
                            height: "100px",
                            background: "#E7F5FF",
                          }}
                        >
                          <VolumeUpIcon
                            color="primary"
                            sx={{
                              height: "62px",
                              width: "55px",
                              top: "17px",
                              position: "relative",
                            }}
                          ></VolumeUpIcon>
                        </Card>
                      </Grid>

                      <Grid item xs={9}>
                        <Box>
                          <CardContent sx={{ padding: "10px" }}>
                            <Typography variant="subtitle2" component="div" align="left">
                              {props.Snippets[index]?.name ? props.Snippets[index]?.name : `Snippet ${index + 1}`}
                            </Typography>
                            {formatSnippetTitle(index) ? (
                              <Typography variant="caption" color="text.secondary" align="left" component="div">
                                {formatSnippetTitle(index)}
                              </Typography>
                            ) : undefined}
                            <Typography variant="caption" color="text.secondary" component="div" align="left">
                              {props.Snippets && props.Snippets[index].createdOn?._seconds
                                ? constants.formatTimeStampToEST(props.Snippets[index].createdOn)
                                : undefined}
                            </Typography>
                            <Typography variant="caption" color="text.secondary" align="left" component="div">
                              {formatDuration(index)}
                            </Typography>
                          </CardContent>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                );
              })
            : undefined}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SnippetPanel;
